import { useEffect, useState } from "react"
import notfound from "../../assets/notfound.png"
import { Button, Carousel, Modal } from "rsuite"
import formatIndianNumber from "../../services/FormatNumber"



export const ProductReturnView = ({ item }) => {
    const [opView, setOpView] = useState(false)
    const handleCloseView = () => setOpView(false)
    useEffect(() => {
    }, [item])

    return (
        <div className="row mb-2">
            <div className="col-md-12">
                <div className="ProductReView">
                    <div className="p-2 ProdImgView">
                        <img
                            src={item.CustomerLinkItemImageData !== "" ? `data:image/png;base64,${item.CustomerLinkItemImageData}` : notfound}
                            className="img-fluid me-2" alt="img prodreturntest" />
                        <div className="ProductReContent">
                            <h5 className="mb-2">{item.CustomerLinkItemName}</h5>
                            <p>Order Quantity in Boxes : {item.BoxOrderQty}  </p>
                            <p>Order Quantity in Pcs : {item.PcsOrderQty}</p>
                            <p>Order value : {formatIndianNumber(item.OrderDetVal)}</p>
                            <p>Amount (incl. Of Taxes) :{formatIndianNumber(item.OrderDetVal + (item.PcsOrderQty * item.CustomerLinkItemDP) * item.CustomerLinkItemTaxPer / 100)}</p>
                        </div>
                    </div>
                    <div>
                        <div className="d-flex justify-content-end">
                            <div className="ProductCont2 p-2">
                                <p>Return Qty (In Pcs)&nbsp;:&nbsp;{item.KartDetReturnQty}</p>
                                <p>Batch no&nbsp;:&nbsp;{item.KartDetReturnBatchNo}</p>
                                <p>Reason&nbsp;:&nbsp;{item.KartDetReturnReason}</p>

                                <h6
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        if (item.ReturnedItemImageData !== "") {
                                            setOpView(true)
                                        }
                                    }}
                                >Uploaded images&nbsp;<i class="fa-regular fa-eye text-dark"></i></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal backdrop="static" content="center" role="alertdialog" open={opView} onClose={handleCloseView} size="md">
                <Modal.Header></Modal.Header>
                <Modal.Body className="p-2">
                    <Carousel className="custom-slider">
                        <img className="img-fluid" src={item.ReturnedItemImageData} alt="" />
                    </Carousel>
                </Modal.Body>
            </Modal>
        </div>
    )
}