import './HomePage.css'
import { Banner } from "../../components/Banner/Banner"
import { LandingNav } from "../../components/LandingNav/LandingNav"
import hero from '../../assets/landing/Group 893.png'
import sgImg1 from '../../assets/surgical/s1.png'
import sgImg2 from '../../assets/surgical/s2.png'
import sgImg3 from '../../assets/surgical/s3.png'
import sgImg4 from '../../assets/surgical/s4.png'
import sgImg5 from '../../assets/surgical/s5.png'
import sgImg6 from '../../assets/surgical/s6.png'
import sgImg7 from '../../assets/surgical/s7.png'
import sgImg8 from '../../assets/surgical/s8.png'
import sgImg9 from '../../assets/surgical/s9.png'
import sgImg10 from '../../assets/surgical/s10.png'
import sgImg11 from '../../assets/surgical/s11.png'
import sgImg12 from '../../assets/surgical/s12.png'
import sgImg13 from '../../assets/surgical/s13.png'
import sgImg14 from '../../assets/surgical/s14.png'
import notFound from '../../assets/notfound.png'
import { AmsButton } from '../../core/Button/Button'
import { Footer } from '../../components/Footer/Footer'
import Slider from "react-slick";
/*import { useState, useEffect, useRef } from 'react'*/
import { useEffect, useRef, useState } from 'react'
import HomeService from '../../services/HomeService'
import { Modal } from 'rsuite'
import { AMToasterService } from '../../services/ToasterSerivce'
import UserService from '../../services/UserService'
import { Carousel } from 'bootstrap';
import { LoaderCheck } from "../../core/Loader/Loader"

export const HomePage = () => {
    const carouselRef = useRef(null);
    const [prodData, setProdData] = useState([])
    const sliderRef = useRef(null);
    const sliderRefMain = useRef(null);
    const [partner, setPartner] = useState(false)
    const handlePartner = () => setPartner(true)
    const handleClose = () => setPartner(false)
    const [error, setError] = useState(false)
    const [checkVal, setCheckVal] = useState("BS")
    const [up1, setUp1] = useState("")
    const [up2, setUp2] = useState("")
    const [up3, setUp3] = useState("")
    const [up4, setUp4] = useState("")
    const settings = {
        autoplay: true,
        speed: 1000,
        focusOnSelect: true,
        autoplaySpeed: 5000,
        cssEase: "linear",
        dots: false,
        infinite: true,
        slidesToShow: 8,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const settingsNew = {
        autoplay: false,
        speed: 1000,
        focusOnSelect: true,
        autoplaySpeed: 5000,
        cssEase: "linear",
        dots: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const settingsThree = {
        autoplay: false,
        speed: 1000,
        focusOnSelect: true,
        autoplaySpeed: 5000,
        cssEase: "linear",
        dots: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const dataSet = [
        { productTitle: 'Surgeon Care', productImage: sgImg1, id: 1 },
        { productTitle: 'General Surgery Care', productImage: sgImg7, id: 2 },
        { productTitle: 'Ortho Care', productImage: sgImg2, id: 3 },
        { productTitle: 'Cardio Care', productImage: sgImg3, id: 4 },
        { productTitle: 'Gynaec Care', productImage: sgImg4, id: 5 },
        { productTitle: 'Neuro Care', productImage: sgImg5, id: 6 },
        { productTitle: 'Uro Care', productImage: sgImg6, id: 7 }
    ]
    const dataSetTwo =[
        { productTitle: 'Transfusion Care', productImage: sgImg14, id: 8 },
        { productTitle: 'Transplant Care', productImage: sgImg8, id: 9 },
        { productTitle: 'Ophtha Care', productImage: sgImg9, id: 10 },
        { productTitle: 'ENT Care', productImage: sgImg10, id: 11 },
        { productTitle: 'Safety Care', productImage: sgImg12, id: 12 },
        { productTitle: 'Central Sterile Care', productImage: sgImg11, id: 13 },
        { productTitle: 'Respiratory Care', productImage: sgImg13, id: 14 },
    ]
    const [ctName, setCTName] = useState("")
    const [ctPhone, setCTPhone] = useState("")
    const [ctEmail, setCTEmail] = useState("")
    const [ctMessage, setMessage] = useState("")
    const [heroBanner1, setHeroBanner1] = useState("")
    const [heroBanner2, setHeroBanner2] = useState("")
    const resetValues = () => {
        setCTEmail("")
        setCTName("")
        setMessage("")
        setCTPhone("")
    }
    const handleContact = async () => {
        if (ctName !== "" && ctPhone.length !== 0) {
            let emailValid = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if (ctEmail.match(emailValid)) {
                let payload = {
                    name: ctName,
                    phone: ctPhone,
                    email: ctEmail,
                    message: ctMessage
                }
                let data = await UserService.getContactEmail(payload)
                if (data.Status) {
                    AMToasterService('S', 'Successfull')
                    resetValues()
                }
            } else {
                return AMToasterService("I", 'Email format not correct')
            }
        } else {
            AMToasterService('E', 'Fill the required fields')
        }
    }

    const [loading, setLoading] = useState(true);

    const getDataList = () => {
        return new Promise((resolve, reject) => {
            let payload = { itemgroupid: 0 };
            HomeService.getProductsGroups(payload)
                .then((res) => {
                    setProdData(res.Data[0]);
                    resolve(); // Resolve the promise after data is set
                })
                .catch((error) => {
                    console.log("Error in getDataList: ", error);
                    reject(error); // Reject the promise in case of an error
                });
        });
    };

    const getHeroBanner = () => {
        return new Promise(async (resolve, reject) => {
            try {
                let payload = {
                    custcode: "",
                    imagetype: 0,
                };
                let data = await HomeService.getBannerImage(payload);
                if (data.Data[0][0].length !== 0) {
                    setHeroBanner1(data.Data[0][0].CampaignImage);
                }
                if (data.Data[0][1].length !== 0) {
                    setHeroBanner2(data.Data[0][1].CampaignImage);
                }
                resolve(); // Resolve the promise after data is set
            } catch (error) {
                console.log("Error in getHeroBanner: ", error);
                reject(error); // Reject the promise in case of an error
            }
        });
    };

    useEffect(() => {
        setLoading(true); // Show loader while fetching data
        Promise.all([getDataList(), getHeroBanner()])
            .then(() => {
                /*console.log("Both functions completed successfully");*/
            })
            .catch((error) => {
                console.log("Error in fetching data: ", error);
            })
            .finally(() => {
                setLoading(false); // Hide loader after fetching is done
            });
    }, []);

    useEffect(() => {
        // Ensure the carousel instance is created after the component is mounted
        let carouselInstance = null;
        if (carouselRef.current) {
            try {
                // Initialize the carousel
                carouselInstance = new Carousel(carouselRef.current, {
                    interval: 3000,
                    wrap: true,
                });

                // Add event listeners for mouseenter and mouseleave
                const handleMouseEnter = () => carouselInstance.pause();
                const handleMouseLeave = () => carouselInstance.cycle();

                carouselRef.current.addEventListener('mouseenter', handleMouseEnter);
                carouselRef.current.addEventListener('mouseleave', handleMouseLeave);

                // Cleanup on unmount
                return () => {
                    if (carouselInstance) {
                        carouselInstance.dispose(); // Dispose the carousel instance
                    }
                    if (carouselRef.current) {
                        carouselRef.current.removeEventListener('mouseenter', handleMouseEnter);
                        carouselRef.current.removeEventListener('mouseleave', handleMouseLeave);
                    }
                };
            } catch (error) {
                console.error('Error initializing the carousel:', error);
            }
        }
    }, []);

    return (
        <div>
            {loading && <LoaderCheck text={'Loading...'} isLoad={loading}></LoaderCheck>}
            {!loading && (
                <div className='HomeContainer fade-image-class'>
                    <LandingNav />
                    <div ref={carouselRef} id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel" style={{ height: '90%' }}>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={`data:image/png;base64,${heroBanner1}`} className="d-block w-100" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={`data:image/png;base64,${heroBanner2}`} className="d-block w-100" alt="..." />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                    <div className="container-fluid text-center my-5">
                        <div className="row p-0">
                            <div className="col-md-3 col-sm-6 HomeBorder">
                                <div>
                                    <h1>15+</h1>
                                    <p className="HomePara">Medical specialties<br /> catered to</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 HomeBorder">
                                <div>
                                    <h1>14+</h1>
                                    <p className="HomePara">Years of <br />experience</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 HomeBorder">
                                <div>
                                    <h1>300+</h1>
                                    <p className="HomePara">Active channel <br />partners globally</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 HomeBorder2">
                                <div>
                                    <h1>50K+</h1>
                                    <p className="HomePara">Reaching healthcare <br />professionals daily</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid HomeSlick" style={{ marginTop: '-30px' }}>
                        <h3 className='mb-3'>Our Range of Products</h3>
                        <div className="row">
                            <Slider ref={sliderRefMain} {...settings}>
                                {prodData.map((item, index) => (
                                    <div className='col p-2' key={index}>
                                        <div className='HomeRanPro'>
                                            <img src={item.ItemImageData != "" ? `data:image/png;base64,${item.ItemImageData}` : notFound} alt="product-image" />
                                            <h6>{item.ItemGroupName}</h6>
                                        </div>
                                    </div>
                                ))}
                            </Slider>

                            <button className='HomeRPrev' onClick={() => sliderRefMain.current.slickPrev()}>
                                <i class="fa-solid fa-angle-right"></i>
                            </button>
                            <button className='HomeRNext' onClick={() => sliderRefMain.current.slickNext()}>
                                <i class="fa-solid fa-angle-left"></i>
                            </button>
                        </div>
                    </div>
                    <div className='container-fluid HomeSpec pb-2' style={{ backgroundColor: '#F5F5F5' }}>
                        <h3 className='mt-5 pt-2'>Specialised Surgical Solutions</h3>
                        <div className='row'>
                            <Slider ref={sliderRef} {...settingsNew}>
                                {dataSet.map((item, index) => (
                                    <div className='col-md-2 mb-3 mt-3' key={index}>
                                        <div className='HomeImgBox'>
                                            <img src={item.productImage} className='img-sec' alt="homeimage" />
                                            <h6>{item.productTitle}</h6>
                                        </div>
                                    </div>
                                ))}
                            </Slider>

                            <Slider ref={sliderRef} {...settingsThree}>
                                {dataSetTwo.map((item, index) => (
                                    <div className='col-md-2 mb-3 mt-3' key={index}>
                                        <div className='HomeImgBox'>
                                            <img src={item.productImage} className='img-sec' alt="homeimage" />
                                            <h6>{item.productTitle}</h6>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                    <div className='container-fluid mb-3 pb-2 mt-4'>
                        <div className='row text-center'>
                            <div className='col-md-12'>
                                <AmsButton
                                    onClick={handlePartner}
                                    textcolor={'#fff'}
                                    icon={''}
                                    buttonwid={'auto'}
                                    color={'#FF6A6C'}
                                    label={'Partner with us'}></AmsButton>
                            </div>
                        </div>
                    </div>
                    <Footer />

                    <Modal size={'sm'} open={partner} onClose={handleClose}>
                        <Modal.Header>

                        </Modal.Header>
                        <Modal.Body>
                            <div className='container-fluid mt-3 mb-3'>
                                <h4>Partner with us</h4>
                                <div class="row" style={{ paddingLeft: '0.2rem', paddingRight: '0.2rem' }}>
                                    <div class="mb-3">
                                        <input type="text" class="form-control"
                                            value={ctName}
                                            onChange={(e) => setCTName(e.target.value)}
                                            placeholder="Name*" aria-label="Name" />
                                    </div>
                                    <div class="mb-3">
                                        <input type="number" class="form-control"
                                            maxLength={10}
                                            value={ctPhone}
                                            onChange={(e) => {
                                                if (e.target.value.length > 10) {
                                                    AMToasterService('I', 'Invalid  phone number')
                                                    setCTPhone("")
                                                } else {
                                                    setCTPhone(e.target.value)
                                                }
                                            }}
                                            required
                                            placeholder="Phone*" aria-label="Phone" />
                                    </div>
                                    <div class="mb-3">
                                        <input type="email" class="form-control"
                                            value={ctEmail}
                                            required
                                            onChange={(e) => setCTEmail(e.target.value)}
                                            placeholder="Email*" aria-label="Email" />
                                    </div>
                                    <div class="mb-3">
                                        <textarea type="text" class="form-control"
                                            value={ctMessage}
                                            onChange={(e) => setMessage(e.target.value)}
                                            placeholder="Message" aria-label="Message"></textarea>
                                    </div>
                                    <div className='text-center mt-3'>
                                        <button type='button' className='ContactButton' onClick={handleContact}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            )}
        </div>
    )
}