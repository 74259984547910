import ReactApexChart from "react-apexcharts"

export const AMPiechart = ({labels,dataSeries}) => {

    // const options = {
    //     series: dataSeries,
    //     options: {
    //         plotOptions: {
    //             pie: {
    //               customScale: 2
    //             }
    //           },
    //         chart: {
    //             type: 'pie',
    //             width: '100%',
    //             height: '100%',
    //         },
    //         dataLabels: {
    //             enabled: true,
    //             style: {
    //               fontSize: "10px",
    //               fontWeight: "light"
    //             }
    //           },
    //         colors: ['#3D97EA', '#71BBFF', '#A9D6FF','#CBE6FF'],
    //         labels: labels,
    //         legend: {
    //             position: 'bottom'
    //         },
    //         responsive: [{
    //             breakpoint: 480,
    //             options: {
    //                 colors: ['#3D97EA', '#71BBFF', '#A9D6FF','#CBE6FF'],
    //                 chart: {
    //                     width: 200
    //                 },
    //                 legend: {
    //                     position: 'bottom',
    //                     horizontalAlign: 'center', 
    //                 }
    //             }
    //         }]
    //     },
    // }
    const options = {
        series: dataSeries,
        options: {
            plotOptions: {
            },
            chart: {
                type: 'pie',
                width: '100%',
                height: '100%',
            },
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: "10px",
                    fontWeight: "light"
                }
            },
            colors: ['#3D97EA', '#71BBFF', '#A9D6FF','#CBE6FF'],
            labels: labels,
            legend: {
                position: 'bottom'
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: '90%', // Change this value as needed
                        height: 'auto' // You can adjust height too if needed
                    },
                    legend: {
                        position: 'bottom',
                        horizontalAlign: 'center',
                    }
                }
            }]
        },
    };
    
    return (
        <div>
        <ReactApexChart  options={options.options} series={options.series} type="pie" />
        </div>
    )

}