import { useState } from 'react'
import { Banner } from '../Banner/Banner'
import { Footer } from '../Footer/Footer'
import { LandingNav } from '../LandingNav/LandingNav'
import './Contact.css'
import { AMToasterService } from '../../services/ToasterSerivce'
import UserService from '../../services/UserService'


export const Contactus = () => {
    const [ctName, setCTName] = useState("")
    const [ctPhone, setCTPhone] = useState("")
    const [ctEmail, setCTEmail] = useState("")
    const [ctMessage, setMessage] = useState("")

    const resetValues = async () => {
        setCTEmail("")
        setCTName("")
        setMessage("")
        setCTPhone("")
    }
    const handleContact = async () => {
        if (ctName !== "" && ctPhone.length !== 0) {
            let emailValid = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if (ctEmail.match(emailValid)) {
                let payload = {
                    name: ctName,
                    phone: ctPhone,
                    email: ctEmail,
                    message: ctMessage
                }
                let data = await UserService.getContactEmail(payload)
                if (data.Status) {
                    AMToasterService('S', 'Successfull')
                    resetValues()
                }
            } else {
                return AMToasterService("I", 'Email format not correct')
            }
        } else {
            AMToasterService('E', 'Fill the required fields')
        }
    }
    return (
        <div className='fade-image-class'>
            <LandingNav />
            <div className='container-fluid mb-5 pb-5 py-5'>
                <h3 className="mb-2 ms-3">Contact Us</h3>
                <div className="row">
                    <div className="col-md-7">
                        <div className='ContactBox' style={{ paddingBottom: '2rem' }}>
                            <div className="ContactAddress">
                                <div>
                                    <h6 className='display-8'>
                                        CORPORATE OFFICE
                                    </h6>
                                    <p>
                                        Amaryllis Healthcare Private Limited.<br />
                                        #301, Lyon Square, 14th 'B' Cross, 7th Main, 6th Sector, HSR Layout, Bangalore - 560102, Karnataka, INDIA.
                                    </p>
                                </div>
                                <div className='mt-3'>
                                    <h6 className='display-8'>
                                        REGISTERED OFFICE
                                    </h6>
                                    <p>
                                        Amaryllis Healthcare Private Limited.<br />
                                        #273/A Bommasandra Industrial Area, Bangalore - 560099, Karnataka, INDIA.
                                    </p>
                                </div>
                            </div>
                            <div className='ContactSubAdd'>
{/*                                <p>Landline : <a href="tel:+080-27831600">080-27831600</a></p>*/}
                                <p>Mobile :  <a href="tel:+91 9169 338 338">+91 9169 338 338</a></p>
                                <p>Email : <a href="mailto:info@amaryllishealthcare.com">info@amaryllishealthcare.com</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className='ContactBox pb-4'>
                            <div class="row">
                                <div class="mb-3">
                                    <input type="text" class="form-control"
                                        value={ctName}
                                        onChange={(e) => setCTName(e.target.value)}
                                        placeholder="Name*" aria-label="Name" />
                                </div>
                                <div class="mb-3">
                                    <input type="number" class="form-control"
                                        maxLength={10}
                                        value={ctPhone}
                                        onChange={(e) => {
                                            if (e.target.value.length > 10) {
                                                AMToasterService('I', 'Invalid  phone number')
                                                setCTPhone("")
                                            } else {
                                                setCTPhone(e.target.value)
                                            }
                                        }}
                                        required
                                        placeholder="Phone*" aria-label="Phone" />
                                </div>
                                <div class="mb-3">
                                    <input type="email" class="form-control"
                                        value={ctEmail}
                                        required
                                        onChange={(e) => setCTEmail(e.target.value)}
                                        placeholder="Email*" aria-label="Email" />
                                </div>
                                <div class="mb-3">
                                    <textarea type="text" class="form-control"
                                        value={ctMessage}
                                        onChange={(e) => setMessage(e.target.value)}
                                        placeholder="Message" aria-label="Message"></textarea>
                                </div>
                                <div className='text-center mt-3'>
                                    <button type='button' className='ContactButton' onClick={handleContact}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )

}