import axios from "axios"
import { ApiEndPoints } from "../constants/ApiEndpoints"
import { ApiUrl } from "../constants/Server"
export default {
    getCustomerTop5Prodcuts: async function(payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.CUSTOMER_LINKED_TOP5_PRODCUTS,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
        }
    },
    getCustomerLinkedProd: async function (payload) {
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.CUSTOMER_LINKED_PRODCUTS,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
        }
    },
    getCustomerSuggestedPrd: async function (payload) {
        try {
            const response = await axios({
                url: ApiUrl.SERVER_URL + ApiEndPoints.CUSTOMER_SUGGESTED_PRODUCTS,
                method: 'post',
                data: payload
            })
            return response.data
        } catch (error) {
            console.log("error ", error)
        }
    },
    getCustomerMissedPrd: async function (payload){
        try {
            const response = await axios({
                url: ApiUrl.SERVER_URL + ApiEndPoints.CUSTOMER_MISSED_PRODUCTS,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
        }
    },
    insertCartItems: async function (payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.INSERT_CART_ITEMS,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
        }
    },
    removeCartItemById: async function (payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.DELETE_CART_ITEM,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
        }
    },
    
    getCartItems: async function (payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.SELECT_CART_ITEMS,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
        }
    },
    getTransportCharge:async function (payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.GET_TRANSPORTATION_CHARGE,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
        }
    },
    getCustomerAddress:async function (payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.SELECT_CUSTOMER_ADDRESS,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
        }
    },
    getProductsByGroupId:async function (payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.PRODUCT,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
        }
    }
}