import axios from "axios"
import { ApiEndPoints } from "../constants/ApiEndpoints"
import { ApiUrl } from "../constants/Server"

export default {
    getProductsGroups: async function(payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.SELECT_PRODUCT_GROUP,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
        }
    },
    getNotifcations:async function(payload) {
        try {
            const response =  await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.SELECT_NOTIFICATIONS,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
        }
    },
    getCustomerLinkedProds: async function(payload) {
        try {
            const response =  await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.CUSTOMER_LINKED_PROD_BY_SALES,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
        }
    },
    getCustomerOfferProd: async function(payload) {
        try {
            const response =  await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.CUSTOMER_OFFER_PRODCUTS,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
        }
    },
    getCustomerStandProd: async function(payload) {
        try {
            const response =  await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.CUSTOMER_STANDARD_PRODCUTS,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
        }
    },
    getBannerImage: async function(payload){
        try {
            const response =  await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.GET_CAMPAIGN_IMAGE,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
        }
    },
    getReorderedProducts: async function(payload){
        try {
            const response =  await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.CUSTOMER_LAST_ORDERED,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
        }
    },
    getCampaignProducts: async function (payload) {
        try {
            const response = await axios({
                url: ApiUrl.SERVER_URL + ApiEndPoints.CUSTOMER_CAMPAIGN_PRODUCTS,
                method: 'post',
                data: payload
            })
            return response.data
        } catch (error) {
            console.log("error ", error)
        }
    },
    getLatestReorderProd: async function(payload){
        try {
            const response =  await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.CUSTOMER_REORDER_PRODUCTS,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
        }
    },
    getAnalysisData:async function(payload){
        try {
            const response =  await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.DISTRIBUTE_ANALYSIS_DATA,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
        }
    }
}