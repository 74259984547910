export default function formatIndianNumber(x) {
    // Convert the number to a string
    //if (x === "undefined") {
        let numStr = String(x);
        // Split the number into the integer part and decimal part (if any)
        let [integerPart, decimalPart] = numStr.split('.');

        // Format the integer part
        let lastThreeDigits = integerPart.slice(-3);
        let otherDigits = integerPart.slice(0, -3);

        if (otherDigits) {
            lastThreeDigits = ',' + lastThreeDigits;
        }

        // Insert commas every two digits in the remaining part
        let formattedInteger = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThreeDigits;
        if (typeof decimalPart === 'undefined') {
            decimalPart = '00'; // Set default decimal part
        }
        // Return the formatted number
        return decimalPart ? formattedInteger + '.' + decimalPart : formattedInteger;
    //} else {
    //    return '0.00';
    //}
}