import React, { useState } from "react";
import { Modal, Button, Input } from "rsuite"; // Assuming you're using RSuite components

export const RemarksModal = ({ inShow, handleReClose, item, onSave }) => {
    const [isEditing, setIsEditing] = useState(false); // Toggle for edit mode
    const [remarks, setRemarks] = useState(item.KartDetRemarks); // Local state for remarks

    const handleEditToggle = () => {
        setIsEditing(!isEditing); // Toggle editing mode
    };

    const handleSave = () => {
        onSave(remarks); // Callback to save changes
        setIsEditing(false); // Exit edit mode after saving
    };

    return (
        <Modal backdrop={true} keyboard={false} open={inShow} onClose={handleReClose}>
            <Modal.Header>
                <Modal.Title>Remarks</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isEditing ? (
                    // Editable input field
                    <Input
                        as="textarea"
                        rows={5}
                        value={remarks}
                        onChange={(value) => setRemarks(value)}
                    />
                ) : (
                    // Display read-only text
                    <p>{remarks}</p>
                )}
            </Modal.Body>
            <Modal.Footer>
                {isEditing ? (
                    <>
                        <Button onClick={handleSave} appearance="primary">
                            Save
                        </Button>
                        <Button onClick={handleEditToggle} appearance="subtle">
                            Cancel
                        </Button>
                    </>
                ) : (
                    <>
                        <Button onClick={handleEditToggle} appearance="primary">
                            Edit
                        </Button>
                        <Button onClick={handleReClose} appearance="subtle">
                            Close
                        </Button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    );
};

