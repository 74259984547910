import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Popover, Whisper } from "rsuite";
import ProductService from '../../services/ProductService'
import { GetSessions } from '../../services/SessionService';
import 'rsuite/dist/rsuite.min.css';
import './AMAutocomplete.css'

const AMAutocomplete = ({ options = [], value, placeholder, onChange, menuArray, onclickButton, ongetData, buttonClikColor }) => {
    const autocomplete = useRef();
    const [optionsData, setOptionsData] = useState([]);
    const [query, setQuery] = useState(value);
    const [isShow, setIsShow] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuData, setMenuData] = useState([])
    const [amuser, setAMUser] = useState(JSON.parse(GetSessions('AM_USER_RE')));
    const [selectedCategory, setSelectedCategory] = useState({ id: 0, title: 'Categories' });

    const handleMenuClose = () => {
        setMenuOpen(false);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutSide);
        return () => {
            document.removeEventListener("mousedown", handleClickOutSide);
        };
    }, []);

    useEffect(() => {
        optionsData.length !== 0 ? setIsShow(true) : setIsShow(false);
        setAmUserData();
        getMenuData();
    }, [optionsData]);

    const getMenuData = async () => {
        let payload = {
            CustomerCode: amuser.CustomerCode
        }
        let data = await ProductService.getMenuData(payload)
        setMenuData([])
        if (data.Data !== undefined) {
            setMenuData(data.Data)
        }
    }
    const resetSelectedCategory = async () => {
        
        setSelectedCategory({ id: 0, title: 'Categories'});
    }
    const renderMenus = () => {
        // Recursive function to render nested menu items
        const renderMenuItems = (items, level) => {

            if (!items || items.length === 0) {
                // If items are undefined or empty, return null
                return null;
            }
            return items.map(item => {
                // Set the title and keys based on the level
                const title = level === 'main' ? item.ItemMainGroupName :
                    level === 'catg' ? item.ItemCatgGroupName :
                        level === 'group' ? item.ItemGroupName :
                            item.ItemSubGroupName;

                const id = level === 'main' ? item.ItemMainGroupId :
                    level === 'catg' ? item.ItemCatgGroupId :
                        level === 'group' ? item.ItemGroupId :
                            item.ItemSubGroupId;

                if (item.children && item.children.length > 0) {
                    return (
                        <Dropdown.Menu onSelect={()=>console.log("menu clicked")} title={title} key={id}>
                            {renderMenuItems(item.children, nextLevel(level))} {/* Recursively render children */}
                        </Dropdown.Menu>
                    );
                } else {
                    return (
                        <Dropdown.Item key={id} eventKey={id}  onClick={() => setSelectedCategory({ id, title })}>
                            {title}
                        </Dropdown.Item>
                    );
                }
            });
        };

        // Function to determine the next level of nesting
        const nextLevel = (level) => {
            if (level === 'main') return 'catg';
            if (level === 'catg') return 'group';
            if (level === 'group') return 'subgroup';
            return 'subgroup';
        };

        return (
            <Popover full>
                <Dropdown.Menu open={menuOpen}
                    onClick={() => setMenuOpen(!menuOpen)} >
                    {renderMenuItems(menuData, 'main')} {/* Start with main group */}
                </Dropdown.Menu>
            </Popover>
        );
    };

    const handleInputChange = (v) => {
        setQuery(v);
        onChange(v);
        ongetData(v);
        v === ""
            ? setOptionsData([])
            : setOptionsData([
                ...options.filter(
                    (x) => x.CustomerLinkItemNameAndCode.toLowerCase().indexOf(v.toLowerCase()) > -1
                        && (selectedCategory.id > 0 ? x.ItemGroupId === selectedCategory.id : true)
                )
            ]);
    };

    const handleClickOutSide = (e) => {
        if (!autocomplete.current.contains(e.target)) {
            setIsShow(false);
        }
    };

    const hilightSearchText = (text) => {
        //var pattern = new RegExp("(" + query + ")", "gi");
        var pattern = new RegExp("(" + query.replace(/[.*+?^=!:${}()|\[\]\/\\]/g, "\\$&") + ")", "gi");
        var new_text = text.replace(pattern, `<b>${query}</b>`);
        return new_text;
    };

    const renderCategories = ({ onClose, left, top, className }, ref) => {
        const handleSelect = eventKey => {
            onClose();
        };
        return (
            <Popover ref={ref} className={className} style={{ left, top }} full>
                <Dropdown.Menu onSelect={handleSelect}>
                    <Dropdown.Menu title="Infection Control">
                        {menuArray.map((item, index) => (
                            item.type === 'ic' ? <Dropdown.Item key={index} eventKey={index}>{item.menuName}</Dropdown.Item> : ''
                        ))}
                    </Dropdown.Menu>
                    <Dropdown.Menu title="Medical Devices">
                        {menuArray.map((item, index) => (
                            item.type === 'md' ? <Dropdown.Item key={index}>{item.menuName}</Dropdown.Item> : ''
                        ))}
                    </Dropdown.Menu>
                </Dropdown.Menu>
            </Popover>
        );
    };

    const setAmUserData = () => {
        let cicode = JSON.parse(GetSessions('AM_USER_RE'))
        setAMUser(cicode)
    }
    return (
        <div className="QuickSearchBar" ref={autocomplete}>
            <div className="catDropdown">
                <Whisper placement="bottomStart" trigger="click" speaker={renderMenus()}>
                    <p>
                        {selectedCategory.title}&nbsp;
                        <i className="fa-solid fa-chevron-down"></i>
                        {selectedCategory.id > 0 && (
                            <i
                                className="fa-solid fa-times remove-filter-icon"
                                onClick={resetSelectedCategory}
                                style={{ marginLeft: '10px', cursor: 'pointer', color: '#ff0000' }}
                                title="Remove Filter"
                            ></i>
                        )}
                    </p>
                </Whisper>
            </div>
            <div style={{ position: "relative", flex: 3 }}>
                <input
                    type="search"
                    className="form-control"
                    value={query}
                    onChange={(e) => handleInputChange(e.target.value)}
                    placeholder={placeholder}
                />
                <i className="fa-solid fa-magnifying-glass"></i>
                {isShow && (
                    <div className="ListWrapper">
                        {optionsData.map((x, index) => (
                            <div
                                className="ListItem"
                                onClick={() => {
                                    ongetData(x);
                                    setQuery(x.CustomerLinkItemNameAndCode);
                                    setIsShow(false);
                                    onChange(x.CustomerLinkItemNameAndCode);
                                }}
                                key={index}
                            >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: hilightSearchText(x.CustomerLinkItemNameAndCode),
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <button
                type="button"
                className="QuickSearchbtn"
                onClick={onclickButton}
            >
                Last Ordered
            </button>
        </div>

        //<div className="row QuickSearchBar" ref={autocomplete}>
        //    <div className="col-md-2">
        //        <div className="catDropdown">
        //            <Whisper placement="bottomStart" trigger="click" speaker={renderMenus()}>
        //                <p>{selectedCategory.title}&nbsp;<i className="fa-solid fa-chevron-down"></i>
        //                    {selectedCategory.id > 0 && (
        //                        <i
        //                            className="fa-solid fa-times remove-filter-icon"
        //                            onClick={resetSelectedCategory}
        //                            style={{ marginLeft: '10px', cursor: 'pointer', color: '#ff0000' }}
        //                            title="Remove Filter"
        //                        ></i>
        //                    )}
        //                </p>
        //            </Whisper>
        //        </div>
        //    </div>
        //    <div className="col-md-8">
        //        <div className="">
        //            <input type="search"
        //                className="form-control"
        //                value={query}
        //                onChange={(e) => handleInputChange(e.target.value)}
        //                    placeholder={placeholder} />
        //            <i className="fa-solid fa-magnifying-glass"></i>
        //        </div>
        //        {isShow && (
        //    <div className="ListWrapper">
        //        {optionsData.map((x, index) => (
        //            <div className="ListItem"
        //                onClick={(item) => {
        //                    ongetData(x)
        //                    setQuery(x.CustomerLinkItemNameAndCode);
        //                    setIsShow(false);
        //                    onChange(x.CustomerLinkItemNameAndCode);
        //                }}
        //                key={index}
        //            >
        //                {
        //                    <div
        //                        dangerouslySetInnerHTML={{ __html: hilightSearchText(x.CustomerLinkItemNameAndCode) }}
        //                    />
        //                }
        //            </div>
        //        ))}
        //    </div>
        //)}
        //    </div>
        //    <div className="col-md-2">
        //        <button type="button"
                    
        //            className="QuickSearchbtn"
        //            onClick={onclickButton}>Last Ordered</button>
        //    </div>
        //</div>
    );
};

export default AMAutocomplete;
