import { useEffect, useState } from "react"
import notfound from "../../assets/notfound.png"
import heic2any from 'heic2any';
import { AMToasterService } from "../../services/ToasterSerivce"



export const ProductReturn = ({ item, onEmitValue, batchList }) => {
    const [qty, setQty] = useState(0)
    const [batchQty, setBatchQty] = useState(0)
    const [batch, setBatch] = useState("")
    const [reason, setReason] = useState("")
    const [baseimage, setBaseImage] = useState("")

    useEffect(() => {
        setBatch("")
        setQty(item.PcsReturnQty)
        setBatchQty(0)
        //console.log("item - ", JSON.stringify(item))
        //console.log("batch", JSON.stringify(batchList))
    }, [item])

    const handleIncrementOld = () => {
        if (qty < item.PcsOrderQty) {
            setQty(qty + 1);
        }
    };

    const handleIncrement = () => {
        if (qty < batchQty) {
            setQty(qty + 1);
        }
    };

    const handleDecrement = () => {
        if (qty > 1) {
            setQty(qty - 1);
        }
    };

    const getChangedValuesOld = () => {
        if (batch === "") {
            return AMToasterService('I', 'Provide batch number!')
        }
        if (parseInt(qty) === 0) {
            return AMToasterService('I', 'Provide atleast one quantity')
        }
        //console.log(JSON.stringify(item))
        item.PcsReturnQty = qty
        
        let totalAmoutnTax = ((item.PcsReturnQty * item.CustomerLinkItemDP) * item.CustomerLinkItemTaxPer / 100) + (item.PcsReturnQty * item.CustomerLinkItemDP)
        totalAmoutnTax = parseInt((totalAmoutnTax += 0.501).toString())
        onEmitValue({ item, batch, reason, totalAmoutnTax, baseimage })
    }

    const getChangedValues = () => {
        if (batch === "") {
            return AMToasterService('I', 'Provide batch number!');
        }
        if (parseInt(qty) === 0) {
            return AMToasterService('I', 'Provide at least one quantity');
        }

        // Validate if baseimage is a valid image
        if (!baseimage) {
            return AMToasterService('I', 'Upload returning item image!');
        }

        const image = new Image();
        image.onload = () => {
            // Valid image, continue processing
            item.PcsReturnQty = qty;

            let totalAmoutnTax = ((item.PcsReturnQty * item.CustomerLinkItemDP) * item.CustomerLinkItemTaxPer / 100) + (item.PcsReturnQty * item.CustomerLinkItemDP);
            totalAmoutnTax = parseInt((totalAmoutnTax += 0.501).toString());

            onEmitValue({ item, batch, reason, totalAmoutnTax, baseimage });
        };

        image.onerror = () => {
            // Invalid image
            AMToasterService('I', 'Invalid image provided!');
        };

        // Set the `src` to trigger the validation
        image.src = baseimage;
    };

    const handleUploadImage = async (event) => {
        let input = document.createElement('input');
        input.type = 'file';
        input.accept= '.png';
        input.onchange = () => {
            let files = Array.from(input.files);
            const newRead =  new FileReader(files[0])
            newRead.readAsDataURL(files[0])
            newRead.onloadend = () =>{
                setBaseImage(newRead.result)             
                AMToasterService('S',"File Successfuly Added")
            }
            
        };
        input.click();
    }

    return (
        <div className="row mb-2">
            <div className="col-md-4">
                <div className="quickFirstSecond">
                    <div className="quickImgBoxSecond">
                        <img
                            src={item.CustomerLinkItemImageData !== "" ? `data:image/png;base64,${item.CustomerLinkItemImageData}` : notfound}
                            className="img-fluid" alt="img prodreturntest" />
                    </div>
                    <div>
                    </div>
                    <div className="quickImgContentSecond">
                        <h5>{item.CustomerLinkItemName}</h5>
                        <p>Order Quantity in Boxes : {item.BoxOrderQty}  </p>
                        <p>Order Quantity in Pcs : {item.PcsOrderQty}</p>
                        <p>Order value : {item.OrderDetVal}</p>
                        <p>Amount (incl. Of Taxes) :{item.OrderDetVal + (item.PcsOrderQty * item.CustomerLinkItemDP) * item.CustomerLinkItemTaxPer / 100}</p>
                    </div>
                </div>
            </div>
            <div className="col-md-3">
                <div className="row mb-5">
                    <div className="col-sm-12">
                        {(batchList.length !== 0 && batchList !== undefined) &&
                            <select
                                style={{ outline: 'none' }}
                                value={batch}
                                onChange={(e) => {
                                    const findValue = batchList.find((item) => item.OrderBatchNo === e.target.value)
                                    setBatch(findValue.OrderBatchNo);
                                    //setQty(findValue.OrderBatchQty);
                                    setBatchQty(findValue.OrderBatchQty);
                                }} className="w-100 mt-2">
                                <option value="">Select batchno</option>
                                {batchList.filter((batch) => batch.OrderItemCode === item.CustomerLinkItemCode).map((item) => (
                                    <option value={item.OrderBatchNo} key={item}>{item.OrderBatchNo}</option>
                                ))}
                            </select>}
                    </div>
                </div>
                <div className="myReturnQty">
                    <p onClick={handleDecrement}>
                        <i className="fa-solid fa-minus"></i>
                    </p>
                    <div>
                        <input
                            style={{ border: 'none', textAlign: 'center', outline: 'none' }}
                            type="text"
                            value={qty}
                            onChange={e => {
                                if (e.target.value === "") {
                                    setQty("")
                                } else {
                                    setQty(e.target.value);
                                }
                            }}
                            onFocus={(e) => e.target.select(0, 0)} />
                    </div>
                    <p onClick={handleIncrement}>
                        <i className="fa-solid fa-plus"></i>
                    </p>
                </div>
            </div>
            <div className="col-md-3 QuickSecond">
                <div>
                    <textarea
                        onChange={(e) => setReason(e.target.value)}
                        placeholder="Reason to Return" rows={'3'} className="form-control"></textarea>
                </div>
            </div>
            <div className="col-md-2">
                <p onClick={handleUploadImage} className="text-center MyReturnPar" style={{ cursor: 'pointer' }}>
                    Upload Image &nbsp;<i className="fa-solid fa-arrow-up-from-bracket"></i>
                    <span className="MyRetuTooltip">added</span>
                </p>
                <div className="MyReturnButton">
                    <button onClick={getChangedValues} type="submit">Add to Return</button>
                </div>
            </div>
            <div className="QuickUnderline"></div>

        </div>
    )
}