export const StoreSessions = (key,value) =>{
    localStorage.setItem(key,value)
}

export const GetSessions = (key) =>{
    return localStorage.getItem(key)
}

export const RemoveSesssions = (key) =>{
    return localStorage.removeItem(key)
}
