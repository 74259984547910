import { Banner } from "../../components/Banner/Banner"
import { Footer } from "../../components/Footer/Footer"
import { UserHeader } from "../../components/UserHeader/UserHeader"
import notfound from '../../assets/notfound.png'
import { BreadCmp } from "../../core/BreadCrump/BreadCmp"
import { Link, useLocation } from "react-router-dom"
import { useState,useEffect } from "react"
import { useRecoilValue } from "recoil"
import { SpecOfferAtom } from "../../rec-state/atoms"
import { GetSessions } from "../../services/SessionService"
import HomeService from "../../services/HomeService"
import { Pagination } from "rsuite"


export const SpecialOfferProd = () =>{
    const [activePage, setActivePage] = useState(1);
    const [amuser,setAMUser] = useState(JSON.parse(GetSessions('AM_USER_RE')))
    const [offData,setOffData] = useState(useRecoilValue(SpecOfferAtom))
    const [banText,setBanText] = useState("")
    useEffect(() => {
        getOfferProds()
        getBannerText()
    }, []);

    const getOfferProds = async () => {
        if(offData.length==0){
            let payload = {
                custcode: amuser.CustomerCode
            }
            let data = await HomeService.getCustomerOfferProd(payload)
            await getBannerText(data.Data[1])
            setOffData(data.Data[1])
        }
    }

    const getBannerText =  async (dataoffer) =>{
        if(dataoffer!==undefined){
            setBanText(dataoffer.slice(0,3).map(item=> item.ItemPartnerOffer!=""?item.ItemPartnerOffer+" "+item.CustomerLinkItemName:'').join(','))
        }
    }

    const itemsPerPage = 12;
    const startIndex = (activePage - 1) * itemsPerPage;
    const currentItems = offData.slice(startIndex, startIndex + itemsPerPage);

    return(
        <div>
            <Banner
                text={banText}
            />
            <UserHeader />
            <BreadCmp link={useLocation().pathname}/>
            <div className="container-fluid mt-2 mb-4 fade-image-class">
                <div className="row mt-2 mb-3">
                <div className='col-md-12'>
                        <h3>Special Offer Products</h3>
                    </div>
                </div>
                <div className="row">
                    {currentItems.map((item, index) => (
                         <div className='col-md-3' key={index}>
                         <div className='ProductCardOne'>
                         <Link to={`/productDetails/${item.CustomerLinkItemCode}`} style={{cursor:'pointer'}}>
                         <img src={item.CustomerLinkItemImageData != "" ? `data:image/png;base64,${item.CustomerLinkItemImageData}` : notfound}
                              className={item.CustomerLinkItemImageData===""?'QuickNotFimg2':'img-fluid-pr'}  alt="" />
                         </Link>
                             <div className='ProductContent'>
                                 <div className='ProductTitle'>
                                     <div>{item.CustomerLinkItemName}</div>
                                     <div>Product&nbsp;Code:&nbsp;{item.CustomerLinkItemCode}</div>
                                     <div className="pb-2"><strike><span className="RuppeFont">₹</span>{item.CustomerLinkItemMRP}</strike>&nbsp;<span className="RuppeFont">₹</span>{item.CustomerLinkItemDP}</div>
                                 </div>
                                 <div className='ProductBtn'>
                                 <button type='button'>Add&nbsp;
                                         <svg width="16" height="16" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                             <path d="M7.29102 9.91663H27.4014C27.8089 9.91664 28.2119 9.9996 28.5843 10.1602C28.9568 10.3207 29.2905 10.5553 29.5639 10.8488C29.8373 11.1423 30.0444 11.4882 30.1718 11.8642C30.2992 12.2402 30.3441 12.638 30.3035 13.0319L29.4285 21.5319C29.3565 22.231 29.0197 22.8792 28.4835 23.3507C27.9472 23.8222 27.2497 24.0833 26.5264 24.0833H12.5993C11.9248 24.0836 11.2711 23.8567 10.7494 23.4413C10.2277 23.026 9.8703 22.4478 9.7381 21.8053L7.29102 9.91663Z" stroke="white" strokeWidth="1.5" strokeLinejoin="round" />
                                             <path d="M7.29102 9.91667L6.10977 5.32242C6.03077 5.01607 5.84865 4.74415 5.59236 4.54985C5.33607 4.35554 5.02029 4.25001 4.69518 4.25H2.91602M11.666 29.75H14.5827M23.3327 29.75H26.2493" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                         </svg>
                                     </button>
                                 </div>
                             </div>
                         </div>
                     </div>
                    ))}
                    <Pagination size="md" total={offData.length} limit={itemsPerPage} activePage={activePage} onChangePage={setActivePage} />
                    {offData.length==0 && <div className="text-center p-5">No data found</div>}
                </div>
            </div>
            <Footer/>
        </div>
    )
}