import { useEffect, useRef, useState,useLayoutEffect } from "react"
import notFound from "../../assets/notfound.png"
import { GetSessions } from "../../services/SessionService";
import { Button, Checkbox, Input, Modal } from 'rsuite'
import formatIndianNumber from "../../services/FormatNumber"
import { RemarksModal } from "../../pages/Cart/Remarks";
export const ProductLastCard = ({ item, onRemoveData }) => {
    const [boxQty, setBoxQty] = useState(item.KartDetQtyInBoxes);
    const [amuser, setAMUser] = useState(JSON.parse(GetSessions('AM_USER_RE')))
    const [deliveryDate, setDeliveryDate] = useState("")
    const [inShow, setInShow] = useState(false)
    const handleReClose = () => setInShow(false)
    const handleRemark = () => setInShow(true)
    useEffect(() => {
        setInShow(false)
    }, [])

    useLayoutEffect(()=>{
        setBoxQty(item.KartDetQtyInBoxes);
    },[item])
    const onEmitItemValue = (type, typeSpecific) => {
        let boxVal=0
        if(typeSpecific==='EXACTVAL'){
            boxVal = boxQty
        }else{
            boxVal = typeSpecific === 'ADDONE' ? boxQty + 1 : boxQty === 0 ? 1 : boxQty - 1;
        }
        const updateItem = {
            KartDetId: item.KartDetId,
            KartDetCustomerCode: amuser.CustomerCode,
            KartDetItemCode: item.KartDetItemCode,
            KartDetItemName: item.KartDetItemName,
            KartDetItemDP: item.KartDetItemDP,
            KartDetTaxPer: item.KartDetTaxPer,
            KartDetBoxQty: item.KartDetBoxQty,
            KartDetQtyInBoxes: parseInt(boxVal),
            KartDetOrderQty: parseInt(boxVal) * item.KartDetBoxQty,
            KartDetOrderVal: (parseInt(boxVal) * item.KartDetBoxQty) * item.KartDetItemDP,
            KartDetTaxAmount: ((parseInt(boxVal) * item.KartDetBoxQty) * item.KartDetItemDP) * item.KartDetTaxPer / 100,
            KartDetTotalAmount: (((parseInt(boxVal) * item.KartDetBoxQty) * item.KartDetItemDP) * item.KartDetTaxPer / 100) + ((parseInt(boxVal) * item.KartDetBoxQty) * item.KartDetItemDP),
            KartDetRemarks: "",
            KartDetTenderOrder: 0,
            KartDetFirstOrder: 0
        }
        onRemoveData({ Data: updateItem, param:type, spec: typeSpecific })
    };
    const setCustomerDeliveryDate = () => {
        const delDate = new Date();
        const userData = JSON.parse(GetSessions('AM_USER_RE'));
        delDate.setDate(delDate.getDate() + userData.CustomerLeadTime);

        const getOrdinalSuffix = (day) => {
            if (day > 3 && day < 21) return 'th'; // Covers 11th to 13th
            switch (day % 10) {
                case 1: return 'st';
                case 2: return 'nd';
                case 3: return 'rd';
                default: return 'th';
            }
        };
        const day = delDate.getDate();
        const month = delDate.toLocaleString('default', { month: 'short' });
        const year = delDate.getFullYear();
        const formattedDate = `${delDate.toLocaleString('en-US', { weekday: 'short' })}, ${day}${getOrdinalSuffix(day)} ${month} ${year}`;

        setDeliveryDate(formattedDate)
    }
    useEffect(() => {
        setCustomerDeliveryDate()
    }, []);
    const countChgQty = (type) => {
        switch (type) {
            case 'ADD':
                setBoxQty((e) => e + 1)
                onEmitItemValue('A', 'ADDONE')
                break;
            case 'SUB':
                setBoxQty((e) => e - 1)
                onEmitItemValue('A', 'SUBONE')
                break;
            default:
                break;
        }
    }
    const [showModal, setShowModal] = useState(false);
    const [itemR, setItem] = useState({ KartDetRemarks: item.KartDetRemarks || "" });

    const handleSaveRemarks = (newRemarks) => {
        setItem((prev) => ({ ...prev, KartDetRemarks: newRemarks })); // Update item in parent
    };
    return (
        <div className="row">
            <div className="col-md-6">
                <div className="quickFirst">
                    <div className="quickImgSec">
                        <img
                            src={item.CustomerLinkItemImageData !== "" ? `data:image/png;base64,${item.CustomerLinkItemImageData}` : notFound}
                            className="img-fluid"
                            style={{mixBlendMode:'multiply'}}
                            alt="Product"
                        />
                    </div>
                    <div className="quickImgContent ps-2">
                        <h5>{item.KartDetItemName}</h5>
                        <p>Product Code: {item.KartDetItemCode}</p>
                        <p>{item.KartDetBoxQty} pc/Box | Unit Price: <span className="RuppeFont">₹</span><strike>{formatIndianNumber(item.KartDetItemStPrice)}</strike> <span className="RuppeFont">₹</span>{formatIndianNumber(item.KartDetItemDP)}</p>
                        <p>Tender Order: {item.KartDetTenderOrder === "1" ? "True" : "False"}</p>
                        <p>Delivery by {deliveryDate}</p>
                    </div>
                </div>
            </div>
            <div className="col-md-3">
                <div className="CardProdDiv">
                    <div className="quickQty">
                        <p onClick={() => {countChgQty('SUB')}}>
                            <i className="fa-solid fa-minus"></i>
                        </p>
                        <div>
                            <input type="number" className="ProdLasInput"
                             onFocus={(e) => e.target.select(0,0)}
                             onBlur={()=>onEmitItemValue('A','EXACTVAL')} onChange={e => {
                                    if(e.target.value===""){
                                        setBoxQty("")
                                    }else{
                                        setBoxQty("")
                                        setBoxQty(e.target.value);
                                    }
                            }} value={parseInt(boxQty)} />
                        </div>
                        <p onClick={() => countChgQty('ADD')}>
                            <i className="fa-solid fa-plus"></i>
                        </p>
                    </div>
                    <p style={{ position: 'absolute', top: '2.3rem', fontSize: '0.7rem' }}>
                        Total Pcs: {boxQty * item.KartDetBoxQty}
                    </p>
                    <div className="ViewMarBtn">
                        {/*<button onClick={handleRemark}>View Remarks</button>*/}
                        <button onClick={() => setShowModal(true)}>View Remarks</button>
                    </div>
                </div>
            </div>
            <div className="col-md-3 QuickSecond d-flex flex-column pe-2">
                <p>Order Value: <span className="RuppeFont">₹</span>{formatIndianNumber(((item.KartDetOrderVal)).toFixed(2))}</p>
                <p>Tax Amount: <span className="RuppeFont">₹</span> {formatIndianNumber((item.KartDetTaxAmount).toFixed(2))}</p>
                <p>Net Amount: <span className="RuppeFont">₹</span> {formatIndianNumber((item.KartDetTaxAmount + item.KartDetOrderVal).toFixed(2))}</p>
                <div className="text-right">
                    <button onClick={() => onEmitItemValue('R')} className="QuicKRemove">Remove</button>
                </div>
            </div>
            <div className="QuickUnderline"></div>
            <RemarksModal
                inShow={showModal}
                handleReClose={() => setShowModal(false)}
                item={itemR}
                onSave={handleSaveRemarks}
            />
            {/*<Modal backdrop={true} keyboard={false} open={inShow} onClose={handleReClose}>*/}
            {/*    <Modal.Header>*/}
            {/*        <Modal.Title>Remarks</Modal.Title>*/}
            {/*    </Modal.Header>*/}
            {/*    <Modal.Body>*/}
            {/*        <p>{item.KartDetRemarks}</p>*/}
            {/*    </Modal.Body>*/}
            {/*    <Modal.Footer>*/}
            {/*        <Button onClick={handleReClose} appearance="subtle">*/}
            {/*            Cancel*/}
            {/*        </Button>*/}
            {/*    </Modal.Footer>*/}
            {/*</Modal>*/}
        </div>
    );
}