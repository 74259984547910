import { json, useLocation } from 'react-router-dom'
import { Banner } from '../../components/Banner/Banner'
import { UserHeader } from '../../components/UserHeader/UserHeader'
import { BreadCmp } from '../../core/BreadCrump/BreadCmp'
import { DatePicker, Modal } from 'rsuite';
import './PaymentDetails.css'
import { Checkbox } from 'rsuite'
import { useEffect, useState } from 'react';
import { GetSessions } from '../../services/SessionService';
import PaymentService from '../../services/PaymentService';
import { AMToasterService } from '../../services/ToasterSerivce';
import OrderService from '../../services/OrderService';
import { LoaderCheck } from '../../core/Loader/Loader';
import formatIndianNumber from '../../services/FormatNumber';
import { Footer } from "../../components/Footer/Footer"

export const PaymentDetails = () => {
    const [amuser, setAMUser] = useState(JSON.parse(GetSessions('AM_USER_RE')))
    const [checkInvoice, setCheckInvoice] = useState(false)
    const [pendData, setPendData] = useState([])
    const [collData, setCollData] = useState([])
    const [orderMaster, setOrderMaster] = useState([])
    const [invoiceList, setInvoiceList] = useState([])
    const [invShow, setInvShow] = useState(false)
    const [basePdf, setBasePdf] = useState("")
    const [totalAmnt, setTotalAmnt] = useState(0)
    const [amntAr, setAmntAr] = useState([])
    const [Cheque, setCheque] = useState(false);
    const [NEFT, setNEFT] = useState(true);
    const [creditLim,setCredLim] = useState(0)
    const [paymentType, setPaymentType] = useState('');
    const [paymentInvoiceNo, setPaymentInvoiceNo] = useState([]);
    const [paymentUtrNo, setPaymentUtrNo] = useState('');
    const [chequeNo, setChequeNo] = useState('');
    const [paymentDate, setPaymentDate] = useState('');
    const [paymentBankName, setPaymentBankName] = useState('');
    const [neftDate, setNeftDate] = useState(null);
    const [chequeDate, setChequeDate] = useState(null);
    const [advanceAmt, setAdvanceAmt] = useState(0)
    const [neftPaymentAmount, setNeftPaymentAmount] = useState(0)
    const [chequePaymentAmount, setChequePaymentAmount] = useState(0)
    const [updatedData, setUpdatedData] = useState([])
    const [AdvanceAmount, setAdvanceAmount] = useState(0);
    const [tempNeftValue, setNeftTempValue] = useState(neftPaymentAmount);
    const [tempChequeValue, setChequeTempValue] = useState(chequePaymentAmount);

    useEffect(() => {
        getPendingPayments()
        getPendingCollections()
        getOrderDetails()
    }, []);

    const handleCheque = (event) => {
        setCheque(event.target.checked);
        setNEFT(!event.target.checked);
    };

    const handleNeft = (event) => {
        setCheque(!event.target.checked);
        setNEFT(event.target.checked);
    };

    const getPendingPayments = async () => {
        let payload = {
            CustomerLinkCustCode: amuser.CustomerCode
        }
        let data = await PaymentService.getPaymentDetails(payload)
        //console.log("payment - ", JSON.stringify(data))
        let updatedAgingList = [...data.Data[0]];
        if(data.Data[0].length!==0){
            setCredLim(data.Data[0][0].AgingCreditLimit)
        }
        updatedAgingList = updatedAgingList.map(item => ({
            ...item,
            isSelected: false,
            Allocatedamt: 0,
        }));
        setPendData(updatedAgingList)

        setUpdatedData(updatedAgingList);
        //console.log('pendData ', JSON.stringify(pendData))
    }

    useEffect(() => {
        updateSelectedInvoice()
    }, [neftPaymentAmount, chequePaymentAmount]);

    const updateSelectedInvoiceOld = () => {
        let amt = { totalAmnt };
        let updatedAgingList = [...pendData];  // Clone the agingList state
        let advanceAmt = 0;
        let totAmt = amt.totalAmnt;
        // First, set all items' isSelected to false 
        updatedAgingList = updatedAgingList.map(item => ({
            ...item,
            isSelected: false,
            Allocatedamt: 0, // Reset allocated amount too if needed
        }));

        // Then, update based on the available amount
        updatedAgingList.forEach((element, index) => {
            if (totAmt > 0) {
                updatedAgingList[index].isSelected = true;
                if (totAmt >= element.AgingBalance) {
                    updatedAgingList[index].Allocatedamt = element.AgingBalance;
                } else if (totAmt < element.AgingBalance) {
                    updatedAgingList[index].Allocatedamt = amt;
                }

                totAmt = totAmt - element.AgingBalance;
            }
        });

        // Set advance amount if there's remaining balance
        if (totAmt > 0) {
            advanceAmt = totAmt;
        }

        // Update the state with new values
        setPendData(updatedAgingList);
        setAdvanceAmt(advanceAmt);
    };

    const insertPaymentDetails = async () => {
        if (NEFT) {
            setPaymentType('NEFT/RTGS');
        } else {
            setPaymentType('Cheque');
        }
        const Invlist = [];
        pendData.forEach(element => {
            if (element.isSelected) {
                Invlist.push({ InvoiceNo: element.AgingDocumentNumber, Amt: element.Allocatedamt });
            }
        });
        let advAmt = (parseFloat(neftPaymentAmount) + parseFloat(chequePaymentAmount)) - parseFloat(totalAmnt);
        if (advAmt < 0) {
            Invlist.push({ InvoiceNo: 'Advance', Amt: Math.abs(advAmt) });
        }
        if (paymentUtrNo === '' && chequeNo === '') {
            AMToasterService('E', 'Payment UTR/Cheque No is required');
            return false;
        } else if (totalAmnt === 0) {
            AMToasterService('E', 'Payment Amount is required');
            return false;
        } else if (Invlist.length === 0) {
            AMToasterService('E', 'Select Any Invoice.');
            return false;
        }
        const formattedInvoiceNos = Invlist.map(item => `${item.InvoiceNo}-${item.Amt}`).join(', ');
        const PayAmt = paymentType === 'Cheque' ? chequePaymentAmount : neftPaymentAmount;

        let payDate = paymentType === 'Cheque' ? new Date(chequeDate) : new Date(neftDate);
        let payload = {
            Custname: amuser.CustomerName, PaymentType: paymentType, PaymentBankName: paymentBankName,
            PaymentUtrNo: paymentUtrNo, PaymentAmount: PayAmt, PaymentDate: payDate,
            PaymentInvoiceNo: formattedInvoiceNos, PaymentDocumentExtension: '.pdf',
            PaymentDocument: basePdf
        };
        let data = await PaymentService.insertPaymentDetails(payload)
        if (data.Status === true) {
            AMToasterService('S', "Payment Successfull!!!")
            resetForm();
        } else {
            AMToasterService('E', "Payment Failed!!!")
        }
    }

    const getPendingCollections = async () => {
        let payload = {
            custcode: amuser.CustomerCode
        }
        let data = await PaymentService.getPendingCollections(payload)
        setCollData([]);
        const updatedAgingList = data.Data[0].map((element) => {
            return { ...element, isSelected: false };
        });
        setCollData(updatedAgingList)
    }

    const handleUpPayment = () => {
        let input = document.createElement('input');
        input.type = 'file';
        input.accept = '.pdf';
        input.onchange = () => {
            let files = Array.from(input.files);
            const newRead = new FileReader(files[0])
            newRead.readAsDataURL(files[0])
            newRead.onloadend = () => {
                const base64 = newRead.result.split(',')[1]
                setBasePdf(base64)
                AMToasterService('S', "File Successfuly Added")
            }

        };
        input.click();
    }

    const handleTotalAmnt = (event, item) => {
        setAmntAr(prevAmntAr => {
            let updatedAmntAr;
            if (event.target.checked) {
                updatedAmntAr = [...prevAmntAr, item];
            } else {
                updatedAmntAr = prevAmntAr.filter(data => data.AgingDocumentNumber !== item.AgingDocumentNumber);
            }
            setTotalAmnt(updatedAmntAr.reduce((sum, { AgingBalance }) => sum + AgingBalance, 0));
            return updatedAmntAr;
        });
    }

    const updateSelectedInvoice = () => {
        let amt = neftPaymentAmount > 0 ? neftPaymentAmount: chequePaymentAmount; // Use the current PaymentAmount value
        let updatedAgingList = [...pendData]; // Create a copy of the AgingList state
        let advanceAmt = 0; // Temporary variable to store AdvanceAmount

        // Reset all `isSelected` values to false
        updatedAgingList = updatedAgingList.map(item => ({
            ...item,
            isSelected: false,
            Allocatedamt: 0, // Reset Allocatedamt
        }));

        // Iterate through the AgingList and calculate allocations
        updatedAgingList = updatedAgingList.map(item => {
            if (amt > 0) {
                item.isSelected = true; // Mark the item as selected
                if (amt >= item.AgingBalance) {
                    item.Allocatedamt = item.AgingBalance; // Fully allocate
                } else {
                    item.Allocatedamt = amt; // Partially allocate
                }
                amt -= item.AgingBalance; // Deduct the allocated amount
            }
            return item;
        });

        // If there's remaining amount, set AdvanceAmount
        if (amt > 0) {
            advanceAmt = amt;
        }
        setTotalAmnt(updatedAgingList.reduce((sum, { Allocatedamt }) => sum + Allocatedamt, 0));
        // Update the state
        setPendData(updatedAgingList);
        setAdvanceAmount(advanceAmt);
    };

    const getOrderDetails = async (orderDoc) => {
        let payload = {
            OrderCustCode: amuser.CustomerCode,
            OrderDocNo: orderDoc === undefined ?"0": orderDoc,
            ShowOrderStatusOnly: "0"
        }
        let data = await OrderService.getOrderMasterDetails(payload)
        if (orderDoc === undefined) {
            setOrderMaster(data.Data[0])
        } else {
            setInvShow(true)
            let filterMap = data.Data[0]
            let newFilterMap = filterMap.map(item=>({
                OrderDetStatusInvoiceNo:item.OrderDetStatusInvoiceNo,
                OrderDetStatusInvoiceDate:item.OrderDetStatusInvoiceDate,
              })).filter(((obj, index, self) => 
                index === self.findIndex((t) => (
                  t.OrderDetStatusInvoiceNo === obj.OrderDetStatusInvoiceNo
                ))
            ))
            setInvoiceList(newFilterMap)
        }
    }

    const resetForm = () => {
        setPaymentUtrNo('');
        setTotalAmnt(0);
        setNeftPaymentAmount(0);
        setChequePaymentAmount(0);
        setPaymentInvoiceNo([]);
        setPaymentDate('');
        setPaymentBankName('');
    };

    const downloadInvoice = async (invoiceNo) => {
        setCheckInvoice(true)
        try {
            setInvShow(false)
            let payload = {
                invoiceno: invoiceNo
            }
            let data = await OrderService.getInvoice(payload)
            const blob = new Blob([data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'invoice.pdf');
            document.body.appendChild(link);
            link.click();
            link.remove();
            setCheckInvoice(false)
        } catch (error) {
            console.error('Error downloading the invoice:', error);
        } finally {
            setCheckInvoice(false)
        }
    };

    return (
        <div>
            <Banner
                text={""}
            />
            <UserHeader />
            <BreadCmp link={useLocation().pathname} />
            <div className='container-fluid fade-image-className'>
                <div className='row mb-4'>
                    <h3 className='mt-3'>Your Order Details</h3>
                    <div className='col-lg-12 MyProfileStrip'>
                        <div className='row'>
                            <div className="col-lg-3 text-center">Approved Credit: {formatIndianNumber((creditLim / 100000).toFixed(2))} L</div>
                            <div className="col-lg-3 text-center">Used credit: {formatIndianNumber((amuser.CustomerDue / 100000).toFixed(2))} L</div>
                            <div className="col-lg-3 text-center">Overdue Amount: {formatIndianNumber((creditLim - amuser.CustomerDue) < 0 ? (Math.abs(amuser.CustomerDue) / 100000).toFixed(2) : 0)} L</div>
                            <div className="col-lg-3 text-center">Available Credit: {formatIndianNumber((creditLim - amuser.CustomerDue) > 0 ? ((creditLim - amuser.CustomerDue) / 100000).toFixed(2) : 0)} L</div>
                        </div>
                    </div>
                    <div className="col-lg-6 mt-2">
                        <div className='paymentViaWrap' style={{ width: '97.5%' }}>
                            <div className="MyProfileSecondStrip">
                                <h5>Pending Payments</h5>
                                <p className='pe-2'>Total Due: <span className="RuppeFont">₹</span>{pendData.map((item, index) => (
                                    <>
                                        {index === 0 && <span key={index}>{formatIndianNumber((item.CustomerDue / 100000).toFixed(2))} L</span>}
                                    </>
                                ))} </p>
                            </div>
                            <div className='PaymentBox p-2'>
                                {pendData.map((item, index) => (
                                    <div key={index}>
                                        <div className='paymentSec pb-2'>
                                            <div className='paymentOne'>
                                                <Checkbox
                                                    disabled={item.InvoiceStatus === 'OnHold'}
                                                    onClick={e => handleTotalAmnt(e, item)}
                                                    value={item.isSelected}
                                                    checked={item.isSelected}
                                                    onChange={(e) => {
                                                        const updatedData = [...pendData];
                                                        updatedData[index].isSelected = !item.isSelected;
                                                        setPendData(updatedData);
                                                    }}
                                                >
                                                    <p>Invoice No    : {item.AgingDocumentNumber}</p>
                                                    <p>Invoice Date  : {new Date(item.AgingPostingDate).toDateString()}</p>
                                                    <p>Invoice Value : <span className="RuppeFont">₹</span> {formatIndianNumber(item.AgingBalance)}</p>
                                                    {/*Total No. of Boxes: {item.KartTotalBoxQty}*/}
                                                </Checkbox>
                                                {/*<p>Invoice Value : <span className="RuppeFont">₹</span> {formatIndianNumber(item.AgingBalance)}</p>*/}
                                            </div>
                                            <div className='paymentTwo'>
                                                {/*<p>{new Date(item.AgingPostingDate).toDateString()}</p>*/}
                                                {/*<p>Total No. of Items: {item.KartTotalItems}</p>*/}
                                                <>
                                                    {
                                                        parseInt(item.AgingDueDays) > 15 ? (
                                                            <div className='pendingDay' style={{backgroundColor:'#ff8080'}}>Due by {parseInt(item.AgingDueDays)} days</div>
                                                        ) : parseInt(item.AgingDueDays) > 6 && parseInt(item.AgingDueDays) < 16 ? (
                                                            <div className='pendingDay' style={{ backgroundColor: '#ffad33' }}>Due by {parseInt(item.AgingDueDays)} days</div>
                                                        ) : parseInt(item.AgingDueDays) >= 0 && parseInt(item.AgingDueDays) < 7 ? (
                                                            <div className='pendingDay' style={{ backgroundColor: '#79d279' }}>Due by {parseInt(item.AgingDueDays)} days</div>
                                                        ) : (
                                                            <div className='pendingDay' style={{backgroundColor:'#79d279'}}>{parseInt(item.AgingDueDays)}</div>
                                                        )
                                                    }
                                                </>
                                            </div>
                                        </div>
                                        <div className="MyProfileUnderLine"></div>
                                    </div>
                                ))}
                            </div>
                            <div className='paymentTotal mt-2'>
                                <div>
                                    <p className='ps-3'>Total : <span className="RuppeFont">₹</span>{formatIndianNumber(totalAmnt)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mt-2">
                        <div className='paymentViaWrap' style={{ width: '97.5%' }}>
                            <div className="MyProfileSecondStrip">
                                <h5>Payment Via</h5>
                            </div>
                            <Checkbox value={NEFT} className='ps-2' onClick={e => handleNeft(e)}>NEFT / RTGS</Checkbox>
                            <div className='row ps-4 pe-4'>
                                <div className="col-lg-6 MyProfileForm">
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <label>UTR No.</label>
                                        <input type="text" className='form-control' value={paymentUtrNo} onChange={(e) => { setPaymentUtrNo(e.target.value) }} />
                                    </div>
                                </div>
                                <div className="col-lg-6 MyProfileForm">
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <label>Date</label>
                                        <DatePicker className='date-control' selected={neftDate}
                                            onChange={(date) => setNeftDate(date)}
                                            format="dd/MM/yyyy" />
                                    </div>
                                </div>
                                <div className='col-lg-12 mt-3 MyProfileForm2'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <label>Amount</label>
                                        <input type="text" className='form-control'
                                            value={tempNeftValue}
                                            onChange={(e) => {
                                                setNeftTempValue(e.target.value); // Update the temporary state
                                            }}
                                            onBlur={(e) => { setNeftPaymentAmount(e.target.value) }} />
                                    </div>
                                </div>
                                <div className='col-lg-2'></div>
                                <div className="col-lg-6 ms-2 mt-3">
                                    <button className='payViaBt1' onClick={handleUpPayment}>Upload Bank Invoice</button>
                                </div>
                                <div className="col-lg-3 mt-3">
                                    <button className='payViaBt2' onClick={() => insertPaymentDetails()} >Pay Now</button>
                                </div>
                                <div className='col-lg-12'>
                                    <div className="MyProfileUnderLine mt-4 mb-4"></div>
                                </div>
                            </div>
                            <Checkbox value={Cheque} className='ps-2' onClick={e => handleCheque(e)}>Cheque</Checkbox>
                            <div className='row ps-4 pe-4'>
                                <div className="col-lg-6 MyProfileForm">
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <label>Cheque No.</label>
                                        <input type="text" className='form-control' value={chequeNo} onChange={(e) => { setChequeNo(e.target.value) }} />
                                    </div>
                                </div>
                                <div className="col-lg-6 MyProfileForm">
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <label>Date</label>
                                        <DatePicker className='date-control' selected={chequeDate}
                                            onChange={(date) => setChequeDate(date)}
                                            format="dd/MM/yyyy" />
                                    </div>
                                </div>
                                <div className='col-lg-12 mt-3 MyProfileForm2'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <label>Amount</label>
                                        <input type="text" className='form-control'
                                            value={tempChequeValue}
                                            onChange={(e) => {
                                                setChequeTempValue(e.target.value); // Update the temporary state
                                            }}
                                            onBlur={(e) => { setChequePaymentAmount(e.target.value) }} />
                                    </div>
                                </div>
                                <div className='col-lg-12 mt-3 MyProfileForm3'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <label>Bank Name</label>
                                        <input type="text" className='form-control' />
                                        <button className='payViaBt2' onClick={() => insertPaymentDetails()}>Pay Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-12 payLastDetil' style={{ width: '99%' }}>
                        <h5 className='mt-4 mb-2'>Last Payment Details</h5>
                        <div className='payScrollDiv'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Mode</th>
                                    <th style={{width:'10%', textAlign: 'right'}}>Amount</th>
                                    <th>Bank Name</th>
                                    <th>Reference No</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                    {collData.sort((a, b) => {
                                        // Convert the CollectionInvoiceDate to Date objects for proper sorting
                                        const dateA = new Date(a.CollectionInvoiceDate);
                                        const dateB = new Date(b.CollectionInvoiceDate);

                                        // Sort in descending order
                                        return dateB - dateA;
                                    }).map((item, index) => (
                                    <tr>
                                        <td className='ps-3'>{new Date(item.CollectionInvoiceDate).toISOString().split('T')[0].split('-')[2] + '-' + new Date(item.CollectionInvoiceDate).toISOString().split('T')[0].split('-')[1] + '-' + new Date(item.CollectionInvoiceDate).toISOString().split('T')[0].split('-')[0]}</td>
                                        <td>{item.CollectionModeofPayment}</td>
                                        <td style={{ textAlign: 'right' }}>{formatIndianNumber(item.CollectionTotal)}</td>
                                        <td>{item.PaymentBankName}</td>
                                        <td>{item.PaymentUtrNo}</td>
                                        <td>
                                            {item.CollectionStatus === "Verified" ? (
                                                <span style={{ color: '#28a745' }}>
                                                    {item.CollectionStatus}
                                                    &nbsp;<i className="fa-regular fa-circle-check"></i>
                                                </span>
                                            ) : (
                                                <span style={{ color: 'red' }}>
                                                    {item.CollectionStatus}
                                                </span>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        </div>
                    </div>
                    <div className='col-lg-12 payLastDetil1' style={{ width: '99%' }}>
                        <h5 className='mt-4 mb-2'>Your Order Details</h5>
                        <div className='payScrollDiv'>
                        <table className="table">
                            <thead>
                                <tr >
                                    <th>Date</th>
                                    <th>Order ID</th>
                                    <th>Ekart Order No.</th>
                                    <th style={{ textAlign: 'left' }}>No. of Boxes</th>
                                    <th style={{ width: '11%', textAlign: 'right'}}>Order Value</th>
                                    <th>Order Status</th>
                                    <th>Invoice</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderMaster.map((item) => (
                                    <tr>
                                        <td className='ps-3'>{item.OrderDocDate.replaceAll("/","-")}</td>
                                        <td>{item.OrderDocNo}</td>
                                        <td>{item.KartInvoiceNo}</td>
                                        <td style={{ textAlign: 'left' }}>{item.BoxQty}</td>
                                        <td style={{ textAlign: 'right' }}>{formatIndianNumber(item.KartNetAmount)}</td>
                                        <td>{item.OrderStatus}</td>
                                        <td style={{color:'#0d6efd',cursor:'pointer'}} onClick={() => getOrderDetails(item.OrderDocNo)}>Download Invoice</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Modal open={invShow} size={'xs'} onClose={()=>setInvShow(false)}>
                <Modal.Header>

                </Modal.Header>
                <Modal.Body className='payLastDetil'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Slno.</th>
                                <th>Download Invoice</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoiceList.map((item,index) => (
                                <tr>
                                    <td>{index+1}</td>
                                    <td>{item.OrderDetStatusInvoiceNo ? (
                                            <>
                                                <a
                                                    href="#"
                                                    className="invoice-link"
                                                    onClick={(e) => {
                                                        downloadInvoice(item.OrderDetStatusInvoiceNo);
                                                    }}>
                                                    {item.OrderDetStatusInvoiceNo}
                                                </a>
                                                {" / "}
                                                {item.OrderDetStatusInvoiceDate}
                                            </>
                                        ) : (
                                            item.OrderDetStatusInvoiceDate
                                        )}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Modal.Body>
            </Modal>
            <LoaderCheck text={'Invoice Downloading...'} isLoad={checkInvoice}></LoaderCheck>
        </div>
    )
}