import { Bounce, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const AMToasterService = (key, message) => {
    try {
        switch (key) {
            case 'E':
                toast.error(message, {
                    position: "top-right",
                    autoClose: 700,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: Bounce,
                })
                break;
            case 'S':
                toast.success(message, {
                    position: "top-right",
                    autoClose: 700,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: Bounce,
                })
                break;
            case 'I':
                toast.info(message, {
                    position: "top-right",
                    autoClose: 700,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: Bounce,
                })
                break;
            default:
                break;
        }
    } catch (error) {
        console.log(error)
    }
}