import { Link, useLocation } from "react-router-dom"
import { Banner } from "../../components/Banner/Banner"
import { UserHeader } from "../../components/UserHeader/UserHeader"
import { BreadCmp } from "../../core/BreadCrump/BreadCmp"
import { useState, useEffect } from "react"
import { Footer } from "../../components/Footer/Footer"
import './ProductDet.css'
import { AmsButton } from "../../core/Button/Button"
import { Accordion, Checkbox, Placeholder, Button, Input } from "rsuite"
import QuickOrderService from "../../services/QuickOrderService"
import { GetSessions } from "../../services/SessionService"
import { AMToasterService } from "../../services/ToasterSerivce"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { CartAtom, LinkedItem } from "../../rec-state/atoms"
import { LoaderCheck } from "../../core/Loader/Loader"
import notFound from "../../assets/notfound.png"
import ProductService from "../../services/ProductService"
import { Modal } from "rsuite"
import UserService from "../../services/UserService"
import formatIndianNumber from "../../services/FormatNumber"
import notfound from "../../assets/notfound.png"
import { QuickOrder } from "../QuickOrder/QuickOrder"

export const ProductDetailsPage = () => {
    const getPage = useLocation()
    const [amuser, setAMUser] = useState(JSON.parse(GetSessions('AM_USER_RE')))
    const [prodDet, setProdDet] = useState({
        CustomerLinkDetCode: "",
        CustomerLinkDetId: 0,
        CustomerLinkItemAvgSalesQty: 0,
        CustomerLinkItemBoxQty: 0,
        CustomerLinkItemCode: "",
        CustomerLinkItemDP: 0,
        CustomerLinkItemImageData: "",
        CustomerLinkItemMRP: 0,
        CustomerLinkItemName: "",
        CustomerLinkItemNameAndCode: "",
        CustomerLinkItemPrice: 0,
        CustomerLinkItemStPrice: 0,
        CustomerLinkItemTaxPer: 0,
        ItemBrand: "",
        ItemColor: "",
        ItemLength: "",
        ItemMaterialDescription: "",
        ItemMaterialType: "",
        ItemPartnerOffer: "",
        ItemProductCode: "",
        ItemProductDetails: "",
        ItemProductType: "",
        ItemSize: "",
        ItemSpeciality: "",
        ItemWidth: "",
        ItemAttachedLeggings: "",
        ItemAbsorbentLayer: "",
        ItemFenestration: "",
        ItemIncise: "",
        Images: []
    })
    const linkedProds = useRecoilValue(LinkedItem)
    const [qty, setQty] = useState(1)
    const routeData = useLocation()
    const [cart, setCart] = useState([])
    const [imgCheck, setimgCheck] = useState("amImgOne")
    const [imgUpdate, setImgUpdate] = useState("")
    const [deliveryDate, setDeliveryDate] = useState("")
    const [similarProductList, setSimilarProductList] = useState([])
    const [cuSuggested, setCuSuggested] = useState([])
    const setCartCount = useSetRecoilState(CartAtom)
    const [tenderCheck, setTenderCheck] = useState(false)
    const [firstOrderCheck, setFirstOrderCheck] = useState(false)
    const [reMark, setRemark] = useState("")
    const [checkProdfound, setCheckProdFound] = useState(false)
    const [usProduct, setUsProduct] = useState("")
    const [open, setOpen] = useState(false)
    const handleClose = () => { setOpen(false); setUsProduct("") }
    const handleOpen = () => setOpen(true);
    const handleRemark = () => setInShow(true)
    const [usName, setUsName] = useState("")
    const [usPhone, setUsPhone] = useState("")
    const [usEmail, setUsEmail] = useState("")
    const [usRemark, setUsRemark] = useState("")
    const [error, setError] = useState(false)

    const handleTenderCheck = (e) => {
        setTenderCheck(e.target.checked);
    };
    const handleFirstOrderCheck = (e) => {
        setFirstOrderCheck(e.target.checked);
    };
    const [inShow, setInShow] = useState(false)
    const handleReClose = () => setInShow(false)

    useEffect(() => {
        setInShow(false)
    }, [])

    useEffect(() => {
        getProductDetails()
        setAMUser(JSON.parse(GetSessions('AM_USER_RE')))
        setAmUserData()
        getCartBoxes()
        getSimilarProducts()
        getSuggestedProd()
    }, [routeData])

    useEffect(() => {
        if (usProduct) {
            handleOpen();
        }
    }, [usProduct]);

    const handleQuoteClick = (itemCode) => {
        setUsProduct(itemCode);
    };

    const resetQuotes = () => {
        setUsEmail("")
        setUsName("")
        setUsRemark("")
        setUsPhone("")
        setUsProduct("")
    }

    const getSuggestedProd = async () => {
        const itemcode = routeData.pathname.split('/')[2];
        let payload = {
            custcode: amuser.CustomerCode,
            itemcode: itemcode
        }
        let data = await QuickOrderService.getCustomerSuggestedPrd(payload)
        setCuSuggested(data.Data.filter((item) => item.ItemDp !== ""))
    }

    const getSimilarProducts = async () => {
        const itemCode = routeData.pathname.split('/')[2];
        let payload = {
            custcode: JSON.parse(GetSessions('AM_USER_RE')).CustomerCode,
            itemcode: itemCode
        };

        let data = await ProductService.getSimilarProdList(payload);
        let result = data.Data;
        if (result) {
            setSimilarProductList(result);
        } else {
            console.log("No similar products found");
        }
    };

    const getCartBoxes = async () => {
        let payload = {
            KartDetCustomerCode: JSON.parse(GetSessions('AM_USER_RE')).CustomerCode
        }
        let data = await QuickOrderService.getCartItems(payload)
        setCart(data.Data)
    }

    /**
     * assume default qty is one 
     * @param data 
     */
    const insertCartBox = async (data) => {
        let addIndex = cart.findIndex((item) => item.KartDetItemCode === data.CustomerLinkItemCode)
        if (addIndex !== -1) {
            const updateItem = [...cart]
            updateItem[addIndex] = {
                ...updateItem[addIndex],
                KartDetCustomerCode: JSON.parse(GetSessions('AM_USER_RE')).CustomerCode,
                KartDetItemCode: prodDet.CustomerLinkItemCode,
                KartDetItemName: prodDet.CustomerLinkItemName,
                KartDetItemDP: prodDet.CustomerLinkItemDP,
                KartDetTaxPer: prodDet.CustomerLinkItemTaxPer,
                KartDetBoxQty: prodDet.CustomerLinkItemBoxQty,
                KartDetQtyInBoxes: qty,
                KartDetOrderQty: qty * prodDet.CustomerLinkItemBoxQty,
                KartDetOrderVal: (qty * prodDet.CustomerLinkItemBoxQty) * prodDet.CustomerLinkItemDP,
                KartDetTaxAmount: ((qty * prodDet.CustomerLinkItemBoxQty) * prodDet.CustomerLinkItemDP) * (prodDet.CustomerLinkItemTaxPer / 100),
                KartDetTotalAmount: ((qty * prodDet.KartDetBoxQty) * prodDet.KartDetItemDP) + (((qty * prodDet.KartDetBoxQty) * prodDet.KartDetItemDP) * (prodDet.KartDetTaxPer / 100)),
                //KartDetTotalAmount: ((qty * prodDet.CustomerLinkItemBoxQty) * prodDet.CustomerLinkItemDP) + ((qty * data.CustomerLinkItemBoxQty) * (data.CustomerLinkItemTaxPer / 100)),
                KartDetRemarks: reMark,
                KartDetTenderOrder: tenderCheck ? 1 : 0,
                KartDetFirstOrder: firstOrderCheck ? 1 : 0
            }
            setCart(updateItem);
            insertToCart(updateItem)
        } else {
            const newItem = {
                KartDetCustomerCode: JSON.parse(GetSessions('AM_USER_RE')).CustomerCode,
                KartDetItemCode: data.CustomerLinkItemCode,
                KartDetItemName: data.CustomerLinkItemName,
                KartDetItemDP: data.CustomerLinkItemDP,
                KartDetTaxPer: data.CustomerLinkItemTaxPer,
                KartDetBoxQty: data.CustomerLinkItemBoxQty,
                KartDetQtyInBoxes: qty,
                KartDetOrderQty: qty * data.CustomerLinkItemBoxQty,
                KartDetOrderVal: (qty * data.CustomerLinkItemBoxQty) * data.CustomerLinkItemDP,
                KartDetTaxAmount: ((qty * data.CustomerLinkItemBoxQty) * data.CustomerLinkItemDP) *
                    (data.CustomerLinkItemTaxPer / 100),
                KartDetTotalAmount: ((qty * prodDet.KartDetBoxQty) * prodDet.KartDetItemDP) +
                    (((qty * prodDet.KartDetBoxQty) * prodDet.KartDetItemDP) * (prodDet.KartDetTaxPer / 100)),
                KartDetRemarks: reMark,
                KartDetTenderOrder: tenderCheck ? 1 : 0,
                KartDetFirstOrder: firstOrderCheck ? 1 : 0,
            };
            const newCart = [...cart, newItem];
            insertToCart(newCart)
        }
    }

    const insertToCart = async (item) => {
        let payload = {
            custcode: JSON.parse(GetSessions('AM_USER_RE')).CustomerCode,
            items: item
        }
        let data = await QuickOrderService.insertCartItems(payload)
        setCartCount(item.length)
        AMToasterService('S', "Added to Cart")
        getCartBoxes()
    }
    const setAmUserData = () => {
        const delDate = new Date();
        const userData = JSON.parse(GetSessions('AM_USER_RE'));
        delDate.setDate(delDate.getDate() + userData.CustomerLeadTime);

        const getOrdinalSuffix = (day) => {
            if (day > 3 && day < 21) return 'th'; // Covers 11th to 13th
            switch (day % 10) {
                case 1: return 'st';
                case 2: return 'nd';
                case 3: return 'rd';
                default: return 'th';
            }
        };
        const day = delDate.getDate();
        const month = delDate.toLocaleString('default', { month: 'short' });
        const year = delDate.getFullYear();
        const formattedDate = `${delDate.toLocaleString('en-US', { weekday: 'short' })}, ${day}${getOrdinalSuffix(day)} ${month} ${year}`;

        let cicode = JSON.parse(GetSessions('AM_USER_RE')).CustomerCode
        setAMUser(cicode)
        setDeliveryDate(formattedDate)
    }
    const readMoreText = () => {
        var dots = document.getElementById("dots");
        var moreText = document.getElementById("MoreKnow");
        var btnText = document.getElementById("read");

        if (dots.style.display === "none") {
            dots.style.display = "inline";
            btnText.innerHTML = "Read more";
            moreText.style.display = "none";
        } else {
            dots.style.display = "none";
            btnText.innerHTML = "Read less";
            moreText.style.display = "inline";
        }
    }

    useEffect(() => {
        setCheckProdFound(true)
        getProductDetails()
    }, [linkedProds])

    const getProductDetails = async () => {
        setCheckProdFound(true)
        const itemCode = routeData.pathname.split('/')[2];
        let result = linkedProds.find((item) => item.CustomerLinkItemCode === itemCode);
        if (result) {
            setProdDet(result);
            setCheckProdFound(false)
        } else {
            setCheckProdFound(true)
        }
    };

    const changQty = (type) => {
        switch (type) {
            case 'ADD':
                setQty(e => e + 1)
                break;
            case 'SUB':
                setQty(e => e === 0 ? 1 : e - 1)
            default:
                break;
        }
    }
    const handleAddCart = () => {
        if (qty !== 0) {
            insertCartBox(prodDet)
        } else {
            AMToasterService('I', 'Select Product Qty')
        }
    }

    const handleGetQuote = async () => {
        if (usEmail != "" && usName != "" && usPhone != "" && usProduct != "") {
            let payload = {
                customer: JSON.parse(GetSessions('AM_USER_RE')).CustomerCode + ' - ' + JSON.parse(GetSessions('AM_USER_RE')).CustomerName,
                email: usEmail,
                name: usName,
                message: usRemark,
                phone: usPhone,
                product: usProduct
            }
            let data = await UserService.insertQuoteDetails(payload)
            if (data.Status) {
                AMToasterService('S', 'Successfull')
                resetQuotes()
            }
            handleClose()
        } else {
            setError(true)
            AMToasterService('E', 'Fill required fields')
        }
    }

    const clickToZoom = async () => {
        const image = document.getElementById("zoomImage");
        image.addEventListener("click", function (event) {
            const rect = image.getBoundingClientRect();
            const x = event.clientX - rect.left; // Mouse click position (X)
            const y = event.clientY - rect.top;  // Mouse click position (Y)

            // Normalize the coordinates to percentages
            const xPercent = (x / rect.width) * 100;
            const yPercent = (y / rect.height) * 100;

            // Set the transform-origin to the clicked position
            image.style.transformOrigin = `${xPercent}% ${yPercent}%`;

            // Toggle zoom effect
            image.classList.toggle("zoomed");
        });

    }

    if (prodDet === undefined) {
        return (
            <LoaderCheck isLoad={true}></LoaderCheck>
        )
    }
    if (checkProdfound) { return <LoaderCheck text={'Loading...'} isLoad={checkProdfound}></LoaderCheck> }

    return (
        <div>
            <Banner
                text={""}
            />
            <UserHeader />
            <BreadCmp link={getPage.pathname} />
            <div className="container-fluid fade-image-className mb-4">
                <div className="row">
                    <div className="col-md-6 mt-2">
                        <div className="ProDetPageImg mb-5">
                            <div className="ProdImgSpec">
                                {prodDet.Images.length > 1 ? (
                                    <>
                                        <div className={imgCheck === 'amImgOne' ? 'ProImgSdw' : ''}
                                            onClick={() => { setimgCheck('amImgOne'); setImgUpdate(prodDet.Images[0].CustomerLinkItemImageData !== "" ? prodDet.Images[0].CustomerLinkItemImageData : "") }}>
                                            <img src={prodDet.Images[0] && prodDet.Images[0].CustomerLinkItemImageData != "" ? `data:image/png;base64,${prodDet.Images[0].CustomerLinkItemImageData}` : prodDet.CustomerLinkItemImageData != "" ? `data:image/png;base64,${prodDet.CustomerLinkItemImageData}` : notFound} alt="" />
                                        </div>
                                        <div className={imgCheck === 'amImgTwo' ? 'ProImgSdw' : ''}
                                            onClick={() => { setimgCheck('amImgTwo'); setImgUpdate(prodDet.Images[1].CustomerLinkItemImageData !== "" ? prodDet.Images[1].CustomerLinkItemImageData : "") }}>
                                            <img src={prodDet.Images[1] && prodDet.Images[1].CustomerLinkItemImageData != "" ? `data:image/png;base64,${prodDet.Images[1].CustomerLinkItemImageData}` : prodDet.CustomerLinkItemImageData != "" ? `data:image/png;base64,${prodDet.CustomerLinkItemImageData}` : notFound} alt="" />
                                        </div>
                                        <div className={imgCheck === 'amImgThree' ? 'ProImgSdw' : ''}
                                            onClick={() => { setimgCheck('amImgThree'); setImgUpdate(prodDet.Images[2].CustomerLinkItemImageData !== "" ? prodDet.Images[2].CustomerLinkItemImageData : "") }}>
                                            <img src={prodDet.Images[2] && prodDet.Images[2].CustomerLinkItemImageData != "" ? `data:image/png;base64,${prodDet.Images[2].CustomerLinkItemImageData}` : prodDet.CustomerLinkItemImageData != "" ? `data:image/png;base64,${prodDet.CustomerLinkItemImageData}` : notFound} alt="" />
                                        </div>
                                        <div className={imgCheck === 'amImgFour' ? 'ProImgSdw' : ''}
                                            onClick={() => { setimgCheck('amImgFour'); setImgUpdate(prodDet.Images[3].CustomerLinkItemImageData !== "" ? prodDet.Images[3].CustomerLinkItemImageData : "") }}>
                                            <img src={prodDet.Images[3] && prodDet.Images[3].CustomerLinkItemImageData != "" ? `data:image/png;base64,${prodDet.Images[3].CustomerLinkItemImageData}` : prodDet.CustomerLinkItemImageData != "" ? `data:image/png;base64,${prodDet.CustomerLinkItemImageData}` : notFound} alt="" />
                                        </div></>
                                ) : (
                                        <>
                                            <div className={imgCheck === 'amImgOne' ? 'ProImgSdw' : ''}
                                            onClick={() => { setimgCheck('amImgOne'); }}>
                                            <img src={prodDet.Images[0] && prodDet.Images[0].CustomerLinkItemImageData != "" ? `data:image/png;base64,${prodDet.Images[0].CustomerLinkItemImageData}` : prodDet.CustomerLinkItemImageData != "" ? `data:image/png;base64,${prodDet.CustomerLinkItemImageData}` : notFound} alt="" />
                                            </div>
                                        </>
                                    //<>
                                    //    <div className={imgCheck === 'amImgOne' ? 'ProImgSdw' : ''}
                                    //        onClick={() => { setimgCheck('amImgOne'); }}>
                                    //        <img src={prodDet.Images[0] && prodDet.Images[0].CustomerLinkItemImageData != "" ? `data:image/png;base64,${prodDet.Images[0].CustomerLinkItemImageData}` : prodDet.CustomerLinkItemImageData != "" ? `data:image/png;base64,${prodDet.CustomerLinkItemImageData}` : notFound} alt="" />
                                    //    </div>
                                    //    <div className={imgCheck === 'amImgTwo' ? 'ProImgSdw' : ''}
                                    //        onClick={() => { setimgCheck('amImgTwo'); }}>
                                    //        <img src={prodDet.Images[1] && prodDet.Images[1].CustomerLinkItemImageData != "" ? `data:image/png;base64,${prodDet.Images[1].CustomerLinkItemImageData}` : prodDet.CustomerLinkItemImageData != "" ? `data:image/png;base64,${prodDet.CustomerLinkItemImageData}` : notFound} alt="" />
                                    //    </div>
                                    //    <div className={imgCheck === 'amImgThree' ? 'ProImgSdw' : ''}
                                    //        onClick={() => { setimgCheck('amImgThree'); }}>
                                    //        <img src={prodDet.Images[2] && prodDet.Images[2].CustomerLinkItemImageData != "" ? `data:image/png;base64,${prodDet.Images[2].CustomerLinkItemImageData}` : prodDet.CustomerLinkItemImageData != "" ? `data:image/png;base64,${prodDet.CustomerLinkItemImageData}` : notFound} alt="" />
                                    //    </div>
                                    //    <div className={imgCheck === 'amImgFour' ? 'ProImgSdw' : ''}
                                    //        onClick={() => { setimgCheck('amImgFour'); }}>
                                    //        <img src={prodDet.Images[3] && prodDet.Images[3].CustomerLinkItemImageData != "" ? `data:image/png;base64,${prodDet.Images[3].CustomerLinkItemImageData}` : prodDet.CustomerLinkItemImageData != "" ? `data:image/png;base64,${prodDet.CustomerLinkItemImageData}` : notFound} alt="" />
                                    //    </div>
                                    //</>
                                )}
                            </div>
                            <div className="ProdImgSpec2 mt-3">
                                <img  src={prodDet.CustomerLinkItemImageData != "" ? `data:image/png;base64,${imgUpdate !== "" ? imgUpdate : prodDet.CustomerLinkItemImageData}` : notFound} alt="img-proslider" />
                            </div>
                        </div>
                        <AmsButton
                            onClick={handleAddCart} icon={'cart_icon'} textcolor={'#fff'} color={'#3D97EA'} buttonwid={'96%'} label={'Add'} />
                    </div>
                    <div className="col-md-6 mt-3 ms-auto">
                        <div className="row">
                            <div className="col me-4">
                                <h4>{prodDet.CustomerLinkItemName}</h4>
                                <div className="ProdDetUnderLine mt-1 mb-2"></div>
                                <div className="">
                                    <div className="d-flex justify-content-between">
                                        <div>Product Code: {prodDet.CustomerLinkItemCode}</div>
                                        {prodDet.ItemPartnerOffer !== "" && <div className="itemOffText"><i class="fa-solid fa-tag fa-beat"></i>&nbsp;&nbsp;&nbsp;{prodDet.ItemPartnerOffer}</div>}
                                    </div>
                                    <div>
                                        Special Unit Price: <strike><span className="RuppeFont">₹</span>
                                            {formatIndianNumber(prodDet.CustomerLinkItemMRP)}</strike>&nbsp;<span className="RuppeFont">₹</span>
                                        {<span className="ProdBorWeight">{formatIndianNumber(prodDet.CustomerLinkItemDP)}</span>}
                                    </div>
                                </div>
                                <div className="ProdDetUnderLine mt-1 mb-2"></div>
                                <div className="d-flex justify-content-between mb-3">
                                    <div className="ProdDescText">
                                        <p>Available Offers <span id="dots">...</span>
                                            {/*<span id="MoreKnow">*/}
                                            {/*    Partner Offer Extra 10% Off up to <span className="RuppeFont">₹</span>60 on your next purchase </span></p>*/}
                                            <span id="MoreKnow">
                                                {prodDet.ItemPartnerOffer} </span></p>
                                            <span id="read" onClick={readMoreText} className="prodTextInfo text-primary">Read More</span>
                                    </div>
                                    <div className="mt-auto">
                                        <button className="ProdRemark" onClick={handleRemark}>Add Remarks</button>
                                        {inShow &&
                                            <div className='d-flex justify-content-between mb-2'>
                                                <Input type="text" placeholder='Add remarks...'
                                                    onChange={e => {
                                                        if (e.target.value === "") {
                                                            setRemark("")
                                                        } else {
                                                            setRemark(e.target.value);
                                                        }
                                                    }} value={reMark} />
                                                <Button size='xs' appearance="primary" onClick={handleReClose}>Close</Button>
                                            </div>}
                                    </div>
                                </div>
                                <div className="ProdDetUnderLine mt-1 mb-2"></div>
                                <div className="d-flex justify-content-between mt-2">
                                    <p>Qty Per Box : {prodDet.CustomerLinkItemBoxQty} Pcs</p>
                                    <div className="w-25">
                                        <div className="quickQty" style={{ marginLeft: '19%' }}>
                                            <p onClick={() => changQty('SUB')}>
                                                <i className="fa-solid fa-minus"></i>
                                            </p>
                                            <div><input
                                                type="number"
                                                className="ProdDetInput"
                                                onChange={(e) => setQty(Number(e.target.value))}
                                                onFocus={(e) => e.target.select(0, 0)}
                                                value={qty} onBlur={(e) => { console.log("event values ", e.target.value) }} /></div>
                                            <p onClick={() => changQty('ADD')}>
                                                <i className="fa-solid fa-plus"></i>
                                            </p>
                                        </div>
                                        <p className="ms-auto" style={{ fontSize: '0.7rem', marginLeft: '19% !important', float: 'center' }}>Total: {qty * prodDet.CustomerLinkItemBoxQty} Pcs</p>
                                    </div>
                                </div>
                                <div className="ProCheckBox">
                                    <Checkbox checked={tenderCheck} onCheckboxClick={handleTenderCheck} className="p-0">Tender Order</Checkbox>
                                    <Checkbox checked={firstOrderCheck} onCheckboxClick={handleFirstOrderCheck} className="ms-3">1st Time Order</Checkbox>
                                </div>
                                <div className="d-flex justify-content-between ProdBordeSec">
                                    <div>
                                        <p>Order Value</p>
                                        <span className="ProdBorWeight"><span className="RuppeFont">₹</span> {formatIndianNumber((qty * prodDet.CustomerLinkItemBoxQty) * (prodDet.CustomerLinkItemDP))}</span>
                                    </div>
                                    <div className="ProdBorder"></div>
                                    <div>
                                        <p>Tax Percentage</p>
                                        <span className="ProdBorWeight"><span className="RuppeFont"></span> {prodDet.CustomerLinkItemTaxPer}</span>
                                    </div>
                                    <div className="ProdBorder"></div>
                                    <div>
                                        <p>Tax Amount</p>
                                        <span className="ProdBorWeight"><span className="RuppeFont">₹</span> {formatIndianNumber(((qty * prodDet.CustomerLinkItemBoxQty) * (prodDet.CustomerLinkItemDP)) * prodDet.CustomerLinkItemTaxPer / 100)}</span>
                                    </div>
                                    <div className="ProdBorder"></div>
                                    <div>
                                        <p>Total Amount</p>
                                        <span className="ProdBorWeight"><span className="RuppeFont">₹</span> {formatIndianNumber((((qty * prodDet.CustomerLinkItemBoxQty) * (prodDet.CustomerLinkItemDP)) * prodDet.CustomerLinkItemTaxPer / 100) + (qty * prodDet.CustomerLinkItemBoxQty) * (prodDet.CustomerLinkItemDP))}</span>
                                    </div>
                                </div>
                                <div className="ProdDetUnderLine mt-2"></div>
                                <div className="d-flex align-items-center mt-2 mb-2 align-content-center justify-content-between">
                                    <p>Delivery by : {deliveryDate}</p>
                                    {/*<p style={{ marginTop: '-0.1rem' }}>Select delivery location</p>*/}
                                </div>
                                <div className="ProdDetUnderLine mt-1 mb-2"></div>
                                <div style={{ marginLeft: '-1rem', width: '100%' }}>
                                    <Accordion>
                                        <Accordion.Panel header="Product Details">
                                            <div className="ps-4">
                                                <p>Code: {prodDet.ItemProductCode}</p>
                                                <p>Brand: {prodDet.ItemBrand}</p>
                                                {/*<p>Material: {prodDet.ItemMaterialType}</p>*/}
                                                {/*<p>Product Colour: {prodDet.ItemColor}</p>*/}
                                                <p>Product Speciality: {prodDet.ItemSpeciality}</p>
                                                <p>Product Type: {prodDet.ItemProductType}</p>
                                            </div>
                                        </Accordion.Panel>
                                        <Accordion.Panel header="Specifications">
                                            <div className="ps-4">
                                                <p>Product Length: {prodDet.ItemLength && prodDet.ItemLength !== '0' ? `${prodDet.ItemLength} Cm` : "N/A"}</p>
                                                <p>Product Width: {prodDet.ItemWidth && prodDet.ItemWidth !== '0' ? `${prodDet.ItemWidth} Cm` : "N/A"}</p>
                                                <p>Attached Leggings: {prodDet.ItemAttachedLeggings}</p>
                                                <p>Absorbent Layer: {prodDet.ItemAbsorbentLayer}</p>
                                                <p>Fenestration: {prodDet.ItemFenestration}</p>
                                                <p>Incise: {prodDet.ItemIncise}</p>
                                            </div>
                                        </Accordion.Panel>
                                        {/*<Accordion.Panel header="Resources">*/}
                                        {/*    <Placeholder.Paragraph />*/}
                                        {/*</Accordion.Panel>*/}
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className='d-flex justify-content-between align-items-center pb-2'>
                        <h5 className='mt-2'>Suggested Products</h5>
                        <p>{cuSuggested.length > 6 ? 'View All' : ''}</p>
                    </div>
                    {cuSuggested.map((item, index) => (
                        <div className='col-lg-3 mt-4' key={index}>
                            {index < 4 && <div className='ProductCardOne'>
                                {item.CustomerLinkItemDP ? (
                                    <Link to={`/productDetails/${item.CustomerLinkItemCode}`} style={{ cursor: 'pointer', color: '#222222' }}>
                                        <img src={item.CustomerLinkItemImageData != "" ? `data:image/png;base64,${item.CustomerLinkItemImageData}` : notfound}
                                            className={item.CustomerLinkItemImageData === "" ? 'QuickNotFimg2' : 'img-fluid-pr'} alt="" />
                                    </Link>
                                ) : (
                                    <span style={{ cursor: 'not-allowed', color: 'gray' }}>
                                        <img src={item.CustomerLinkItemImageData != "" ? `data:image/png;base64,${item.CustomerLinkItemImageData}` : notfound}
                                            className={item.CustomerLinkItemImageData === "" ? 'QuickNotFimg2' : 'img-fluid-pr'} alt="" />
                                    </span>
                                )}
                                <div className='ProductContent'>
                                    <div className='ProductTitle'>
                                        <div>{item.CustomerLinkItemName}</div>
                                        <div>Product Code&nbsp;:&nbsp;{item.CustomerLinkItemCode}</div>
                                        {item.CustomerLinkItemDP > 0 ? (
                                            <div><span className="RuppeFont">₹</span>{formatIndianNumber(item.CustomerLinkItemDP)}&nbsp;&nbsp;<span className="RuppeFont">₹</span> <strike>{formatIndianNumber(item.CustomerLinkItemStPrice)}</strike></div>
                                        ) :
                                            (<div></div>)}
                                        <div className='ProductBtn'>
                                            {item.CustomerLinkItemDP > 0 ? (
                                                <Link to={`/productDetails/${item.CustomerLinkItemCode}`} style={{ cursor: 'pointer', color: '#222222' }}>
                                                    <button type='button'>Add&nbsp;
                                                        <svg width="16" height="16" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M7.29102 9.91663H27.4014C27.8089 9.91664 28.2119 9.9996 28.5843 10.1602C28.9568 10.3207 29.2905 10.5553 29.5639 10.8488C29.8373 11.1423 30.0444 11.4882 30.1718 11.8642C30.2992 12.2402 30.3441 12.638 30.3035 13.0319L29.4285 21.5319C29.3565 22.231 29.0197 22.8792 28.4835 23.3507C27.9472 23.8222 27.2497 24.0833 26.5264 24.0833H12.5993C11.9248 24.0836 11.2711 23.8567 10.7494 23.4413C10.2277 23.026 9.8703 22.4478 9.7381 21.8053L7.29102 9.91663Z" stroke="white" strokeWidth="1.5" strokeLinejoin="round" />
                                                            <path d="M7.29102 9.91667L6.10977 5.32242C6.03077 5.01607 5.84865 4.74415 5.59236 4.54985C5.33607 4.35554 5.02029 4.25001 4.69518 4.25H2.91602M11.666 29.75H14.5827M23.3327 29.75H26.2493" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </button>
                                                </Link>
                                            ) :
                                                (<div> <button onClick={() => handleQuoteClick(item.CustomerLinkItemCode)}>Get Quote</button>
                                                </div>)}
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
            <Modal size={'sm'} backdrop={'static'} style={{ marginTop: '3rem' }} open={open} onClose={handleClose}>
                <Modal.Header>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <h5>Quotation Request</h5>
                        <div className='row mt-3'>
                            <div className='col-md-12 mb-3'>
                                <input
                                    value={usName}
                                    onInput={() => setError(false)}
                                    style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                    onChange={(event) => setUsName(event.target.value)}
                                    type="text" placeholder="Enter Name" className='form-control' />
                            </div>
                            <div className='col-md-12 mb-3'>
                                <input
                                    type="text"
                                    onInput={() => setError(false)}
                                    style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                    value={usPhone}
                                    onChange={(event) => setUsPhone(event.target.value)}
                                    placeholder="Enter Phone" className='form-control' />
                            </div>
                            <div className='col-md-12 mb-3'>
                                <input
                                    type="text"
                                    onInput={() => setError(false)}
                                    style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                    value={usEmail}
                                    onChange={(event) => setUsEmail(event.target.value)}
                                    placeholder="Enter Email"
                                    className='form-control' />
                            </div>
                            <div className='col-md-12 mb-3'>
                                <textarea
                                    type="number"
                                    onInput={() => setError(false)}
                                    style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                    value={usRemark}
                                    onChange={(event) => setUsRemark(event.target.value)}
                                    placeholder="Enter Remarks"
                                    className='form-control' ></textarea>
                            </div>
                            <div className='col-md-12 text-center'>
                                <AmsButton
                                    onClick={handleGetQuote}
                                    marginBottom={20}
                                    textcolor={'#fff'}
                                    icon={''}
                                    buttonwid={'75%'}
                                    color={'#3D97EA'}
                                    label={'Submit'} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}