import addICon from '../../assets/quick/addicon.png'
import notFound from "../../assets/notfound.png"
import { useEffect, useState } from 'react'
import { GetSessions } from '../../services/SessionService'
import formatIndianNumber from "../../services/FormatNumber"

export const ProdcutCard = ({ item,onAddCart }) => {
    const [countQty, setCountQty] = useState(1)
    const [amuser,setAMUser] = useState(JSON.parse(GetSessions('AM_USER_RE')))
    useEffect(()=>{
    },[])
    const omitValue = () =>{
        const updateItem = {
            KartDetCustomerCode:amuser.CustomerCode,
            KartDetItemCode:item.CustomerLinkItemCode,
            KartDetItemName:item.CustomerLinkItemName,
            KartDetItemDP:item.CustomerLinkItemDP,
            KartDetTaxPer:item.CustomerLinkItemTaxPer,
            KartDetBoxQty:item.CustomerLinkItemBoxQty,
            KartDetQtyInBoxes:parseInt(countQty),
            KartDetOrderQty:parseInt(countQty) * item.CustomerLinkItemBoxQty,
            KartDetOrderVal:(parseInt(countQty) * item.CustomerLinkItemBoxQty) * item.CustomerLinkItemDP,
            KartDetTaxAmount:((parseInt(countQty) * item.CustomerLinkItemBoxQty) * item.CustomerLinkItemDP) * item.CustomerLinkItemTaxPer/100 ,
            KartDetTotalAmount:((parseInt(countQty) * item.CustomerLinkItemBoxQty) * item.CustomerLinkItemDP) + ((parseInt(countQty) * item.CustomerLinkItemBoxQty) * item.CustomerLinkItemTaxPer/100),
            KartDetRemarks: "",
            KartDetTenderOrder: 0,
            KartDetFirstOrder: 0
        }
        onAddCart({Data:updateItem,param:'A'})
    }
    const countChgQty = (type) => {
        switch (type) {
            case 'ADD':
                setCountQty((e) => e + 1)
                break;
            case 'SUB':
                setCountQty((e) => e > 1 ? e - 1 : 1)
                break;
            default:
                break;
        }
    }
    return (
        <div className="row mb-2">
            <div className="col-md-5">
                <div className="quickFirst">
                    <div className="quickImgBox">
                        <img 
                        style={{mixBlendMode:'multiply'}}
                        src={item.CustomerLinkItemImageData != "" ? `data:image/png;base64,${item.CustomerLinkItemImageData}` : notFound} 
                        className="img-fluid" alt="img prodtest" />
                    </div>
                    <div className="quickImgContent">
                        <h5>{item.CustomerLinkItemName}</h5>
                        <p>Product Code:&nbsp;{item.CustomerLinkItemCode}</p>
                        <p>{item.CustomerLinkItemBoxQty} pc/Box</p>
                        <p>Unit Price: <span className="RuppeFont">₹</span><strike>{formatIndianNumber(item.CustomerLinkItemPrice)}</strike>&nbsp;&nbsp;<span className="RuppeFont"><span className="RuppeFont">₹</span></span>{formatIndianNumber(item.CustomerLinkItemDP)}</p>
                    </div>
                </div>
            </div>
            <div className="col-md-2">
                <div className="quickQty">
                    <p onClick={() => countChgQty('SUB', item)}>
                        <i className="fa-solid fa-minus"></i>
                    </p>
                    <div>
                        <input type="number" className="ProdCartInput"
                             onFocus={(e) => e.target.select(0,0)}
                             onChange={e => {
                                    if(e.target.value===""){
                                        setCountQty("")
                                    }else{
                                        setCountQty(e.target.value);
                                    }
                            }} value={parseInt(countQty)} />
                    </div>
                    <p onClick={() => countChgQty('ADD', item)}>
                        <i className="fa-solid fa-plus"></i>
                    </p>
                </div>
                <p style={{fontSize: '0.7rem' }}>
                    Total Pcs: {countQty * item.CustomerLinkItemBoxQty}
                </p>
            </div>
            <div className="col-md-3 QuickSecond">
                <p>Order Value: {formatIndianNumber(((item.CustomerLinkItemBoxQty * countQty) * item.CustomerLinkItemDP).toFixed(2))}</p>
                <p>Avg Monthly PO Qty (Pcs) {item.CustomerLinkItemAvgSalesQty}</p>
                <p style={{fontWeight:400,fontSize:'0.7rem'}}>Last Order on: <br/>{item.LastPurchasedDate} / {item.LastPurchasedQuantity} Pcs</p>
            </div>
            <div className="col-md-2">
                <button className="QuickAddbtn" onClick={omitValue}>Add <img src={addICon} className="img-fluid" alt="add icon" /></button>
            </div>
            <div className="QuickUnderline"></div>
        </div>
    )
}