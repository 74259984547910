import './UserHome.css'
import sl1 from '../../assets/slider/fte.png'
import sl2 from '../../assets/slider/sl2.png'
import sl3 from '../../assets/slider/sl3.png'
import h1img from '../../assets/home/h1.png'
import h2img from '../../assets/home/h2.png'

import { Footer } from '../Footer/Footer'
import { UserHeader } from '../UserHeader/UserHeader'
import { useEffect, useRef, useState } from 'react'
import { Carousel } from 'bootstrap';
import { Link } from 'react-router-dom'
import HomeService from '../../services/HomeService'
import { useSetRecoilState } from 'recoil'
import { FeaOrderAtom, ReOrderAtom } from '../../rec-state/atoms'
import notFound from "../../assets/notfound.png"
import { GetSessions } from '../../services/SessionService'
import { Modal } from 'rsuite'
import UserService from '../../services/UserService'
import { AMToasterService } from '../../services/ToasterSerivce'
import { AmsButton } from '../../core/Button/Button'
import notfound from "../../assets/notfound.png"
import QuickOrderService from '../../services/QuickOrderService'
import formatIndianNumber from '../../services/FormatNumber'
import { saveToIndexedDB, removeFromIndexedDB } from '../../constants/IndexedDb.jsx'
import { LoaderCheck } from "../../core/Loader/Loader"
import { useMutation } from '@tanstack/react-query'

export const UserHome = () => {
    const carouselRef = useRef(null);
    const [offProd, setOffProd] = useState([])
    const [open, setOpen] = useState(false)
    const [stdProd, setStdProd] = useState([])
    const [reoProd, setReoProd] = useState([])
    const [usName, setUsName] = useState("")
    const [usPhone, setUsPhone] = useState("")
    const [usEmail, setUsEmail] = useState("")
    const [usRemark, setUsRemark] = useState("")
    const [error, setError] = useState(false)
    const [anlyData, setAnalyData] = useState([{ data: "", label: "" }])
    const setReProd = useSetRecoilState(ReOrderAtom)
    const setFeaProd = useSetRecoilState(FeaOrderAtom)
    const handleClose = () => {setOpen(false); setUsProduct("")}
    const handleOpen = () => setOpen(true);
    const [amuser, setAMUser] = useState(JSON.parse(GetSessions('AM_USER_RE')))
    const [cart, setCart] = useState([])
    const [usProduct, setUsProduct] = useState("")
    const [bannerImg1, setBannerImg1] = useState("")
    const [bannerImgName1, setBannerImgName1] = useState("")
    const [bannerImg2, setBannerImg2] = useState("")
    const [bannerImgName2, setBannerImgName2] = useState("")
    const [loading, setLoading] = useState(true);
    const [allProducts, setAllProducts] = useState([]);
    const INITIAL_PAGE_SIZE = 100;
    const PAGE_SIZE = 50;

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true); // Show loader while fetching data

                // Trigger all the functions and the mutation in parallel
                await Promise.all([
                    getStandardList(),
                    getOfferProds(),
                    getReorderProd(),
                    getAnalysis(),
                    getBannerImage(),
                    fetchPaginatedData(),
                    //linkMutation.mutateAsync({ custcode: amuser.CustomerCode, itemgroupid: 0 }),
                ]);

                /* console.log("All data fetched successfully"); */
            } catch (error) {
                console.error("Error in fetching data: ", error);
            } finally {
                setLoading(false); // Hide loader after fetching is done
            }
        };

        fetchData(); // Call the async function
    }, []); // Empty dependency array to run only once when the component mounts

    const fetchPaginatedData = async () => {
        let currentPage = 1; // Start from page 1
        let hasMoreData = true; // Controls pagination loop

        try {
            removeFromIndexedDB('customerLinkedProducts');
            while (hasMoreData) {
                // Fetch current page data
                const response = await QuickOrderService.getCustomerLinkedProd({
                    custcode: amuser.CustomerCode,
                    itemgroupid: 0,
                    pagenumber: currentPage,
                    pagesize: currentPage === 1 ? INITIAL_PAGE_SIZE : PAGE_SIZE,
                });
                const pageData = response.Data || [];
                if (Array.isArray(pageData) && pageData.length > 0) {
                    setAllProducts((prev) => [...prev, ...pageData]);
                    // Save the current page data to IndexedDB
                    await saveToIndexedDB('customerLinkedProducts', pageData);
                }
                // Stop the loader after the first page loads
                if (currentPage === 1) {
                    setLoading(false);
                }
                // Determine if there are more pages to fetch
                if (pageData.length < (currentPage === 1 ? INITIAL_PAGE_SIZE : PAGE_SIZE)) {
                    hasMoreData = false; // Stop if fewer than `PAGE_SIZE` items are returned
                } else {
                    currentPage++; // Move to the next page
                }
            }
        } catch (error) {
            console.error("Error fetching paginated data:", error);
        }
    };

    //const linkMutation = useMutation({
    //    mutationFn: QuickOrderService.getCustomerLinkedProd,
    //    onSuccess: async (data) => {
    //        try {
    //            await saveToIndexedDB('customerLinkedProducts', data.Data); // Save to IndexedDB
    //            /* console.log('Data saved to IndexedDB:', JSON.stringify(data.Data)); */
    //        } catch (error) {
    //            console.error("Error saving data to IndexedDB:", error);
    //        }
    //    },
    //    onError: (error) => {
    //        console.error("Error posting data:", error);
    //    },
    //});

    //useEffect(() => {
    //    getStandardList()
    //    getOfferProds()
    //    getReorderProd()
    //    getAnalysis()
    //    getBannerImage()
    //}, []);

    //useEffect(() => {
    //    setLoading(true); // Show loader while fetching data
    //    Promise.all([getStandardList(), getOfferProds(), getReorderProd(), getAnalysis(), getBannerImage(),
    //        await linkMutation.mutateAsync({ custcode: amuser.CustomerCode, itemgroupid: 0 })        ])
    //        .then(() => {
    //            /*console.log("Both functions completed successfully");*/
    //        })
    //        .catch((error) => {
    //            console.log("Error in fetching data: ", error);
    //        })
    //        .finally(() => {
    //            setLoading(false); // Hide loader after fetching is done
    //        });
    //}, []);

    //const linkMutation = useMutation({
    //    mutationFn: QuickOrderService.getCustomerLinkedProd,
    //    onSuccess: async (data) => {
    //        setFoundArray(data.Data);
    //        setLinkedProds(data.Data);
    //        setFItem(data.Data);
    //        await saveToIndexedDB('customerLinkedProducts', data.Data); // Wait for this to complete
    //        /*console.log('filter data - ', JSON.stringify(data.Data));*/
    //    },
    //    onError: (error) => {
    //        console.error('Error posting data:', error);
    //    },
    //});

    useEffect(() => {
        if (usProduct) {
            handleOpen();
        }
    }, [usProduct]);

    const handleQuoteClick = (itemCode,linkitem) => {
        setUsProduct(itemCode+" ------ "+linkitem);
    };

    useEffect(() => {
        // Ensure the carousel instance is created after the component is mounted
        let carouselInstance = null;
        if (carouselRef.current) {
            try {
                // Initialize the carousel
                carouselInstance = new Carousel(carouselRef.current, {
                    interval: 3000,
                    wrap: true,
                });

                // Add event listeners for mouseenter and mouseleave
                const handleMouseEnter = () => carouselInstance.pause();
                const handleMouseLeave = () => carouselInstance.cycle();

                carouselRef.current.addEventListener('mouseenter', handleMouseEnter);
                carouselRef.current.addEventListener('mouseleave', handleMouseLeave);

                // Cleanup on unmount
                return () => {
                    if (carouselInstance) {
                        carouselInstance.dispose(); // Dispose the carousel instance
                    }
                    if (carouselRef.current) {
                        carouselRef.current.removeEventListener('mouseenter', handleMouseEnter);
                        carouselRef.current.removeEventListener('mouseleave', handleMouseLeave);
                    }
                };
            } catch (error) {
                console.error('Error initializing the carousel:', error);
            }
        }
    }, []);

    const resetQuotes = () => {
        setUsEmail("")
        setUsName("")
        setUsRemark("")
        setUsPhone("")
        setUsProduct("")
    }

    const handleGetQuote = async () => {
        if (usEmail != "" && usName != "" && usPhone != "" && usProduct != "") {
            let payload = {
                customer: JSON.parse(GetSessions('AM_USER_RE')).CustomerCode + ' - ' + JSON.parse(GetSessions('AM_USER_RE')).CustomerName,
                email: usEmail,
                name: usName,
                message: usRemark,
                phone: usPhone,
                product: usProduct
            }
            let data = await UserService.insertQuoteDetails(payload)
            if (data.Status) {
                AMToasterService('S', 'Successfull')
                resetQuotes()
            }
            handleClose()
        } else {
            setError(true)
            AMToasterService('E', 'Fill required fields')
        }
    }

    const getStandardList = async () => {
        try {
            let payload = {
                custcode: amuser.CustomerCode
            }
            //console.log(JSON.stringify(payload))
            let data = await HomeService.getCustomerStandProd(payload)
            //console.log(JSON.stringify(data))
            setStdProd(data.Data[1].filter((item, index) => { return index < 4 }))
            setFeaProd(data.Data[1])
            removeFromIndexedDB('customerFeaturedProducts');
            await saveToIndexedDB('customerFeaturedProducts', data.Data[1]);
        } catch (error) {
            console.log("error ",error)
        } finally{
            //console.log("standard products executed!")
        }
    }
    const getOfferProds = async () => { 
        try {
        let payload = {
            custcode: amuser.CustomerCode
        }
        let data = await HomeService.getCustomerOfferProd(payload)
        let bannerVals = data.Data[1].slice(0,3).map(item=> item.ItemPartnerOffer!=""?item.ItemPartnerOffer+" "+item.CustomerLinkItemName:'').toString().replaceAll(",","\t\t\t")
        setOffProd(data.Data[1].filter((item, index) => { return index < 4 }))
        } catch (error) {
            console.log("error ",error)
        } finally{

        }
    }
    const getReorderProd = async () => {
        try {
            let payload = {
                custcode: amuser.CustomerCode,
                itemgroupid: 0
            }
            let data = await HomeService.getLatestReorderProd(payload)
            if (data !== undefined) {
                setReoProd(data.Data[1].filter((item, index) => { return index < 4 }))
                setReProd(data.Data[1])
            }
        } catch (error) {
            
        }finally{
            //console.log("reorder products executed")
        }
    }
    const getAnalysis = async () => {
        try {
            let payload = {
                customercode: amuser.CustomerCode,
            }
            let data = await HomeService.getAnalysisData(payload)
            setAnalyData(data.Data[0])
        } catch (error) {
            
        } finally{

        }
    }
    const getCartBoxes = async () => {
        try {
            let payload = {
                KartDetCustomerCode: amuser.CustomerCode
            }
            let data = await QuickOrderService.getCartItems(payload)
            setCart(data.Data)
        } catch (error) {
            
        }finally{

        }
    }
    const getBannerImage = async () => {
        try {
            let payload = {
                custcode: amuser.CustomerCode,
                imagetype: 1
            }
            let data = await HomeService.getBannerImage(payload)
            if (data.Data[0][0].length !== 0) {
                setBannerImg1(data.Data[0][0].CampaignImage)
                setBannerImgName1(data.Data[0][0].CampaignName)
            } else {
            }
            if (data.Data[0][1].length !== 0) {
                setBannerImg2(data.Data[0][1].CampaignImage)
                setBannerImgName2(data.Data[0][1].CampaignName)
            } else {
            }
        } catch (error) {

        } finally {
        }
    }

    return (
        <div>
            {loading && <LoaderCheck text={'Loading...'} isLoad={loading}></LoaderCheck>}
            {!loading && (
                <div className='fade-image-className'>
                    <UserHeader />
                    <div ref={carouselRef} id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={sl1} className="d-block w-100" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={sl2} className="d-block w-100" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={sl3} className="d-block w-100" alt="..." />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                    <div className='UserDivSection'>
                        <div className='UserPageTypo'>
                            <Link to="/dashBoard">
                            <h5 style={{ textDecoration: 'none' }}>Target-Achievement Analysis 
                                    <i className="fa-solid fa-arrow-right-long"></i>
                                </h5>
                            </Link>
                        </div>
                    </div>
                    <div className='container-fluid'>
                        <div className='row mt-2 d-flex justify-content-between'>
                            <div className='col-md-3 BorderRight'>
                                <div className='UserPageCounter'>
                                    <h5 className='green'>{(anlyData[0].data.split(",")[0] / 100000).toFixed(0)}L</h5>
                                    <h6>Current Quarter<br /> Target</h6>
                                </div>
                            </div>
                            <div className='col-md-3 BorderRight'>
                                <div className='UserPageCounter'>
                                    <h5 className='blue'>
                                        {
                                            isNaN(anlyData[0].data.split(",")[1] / anlyData[0].data.split(",")[0]) || !isFinite(anlyData[0].data.split(",")[1] / anlyData[0].data.split(",")[0])
                                                ? 0
                                                : (((anlyData[0].data.split(",")[1] / anlyData[0].data.split(",")[0])) * 100).toFixed(0)
                                        }%
                                    </h5>
                                    <h6>Current Quarter<br /> Achieved</h6>
                                </div>
                            </div>
                            <div className='col-md-3 BorderRight'>
                                <div className='UserPageCounter'>
                                    <h5 className='orange'>{(anlyData[0].data.split(",")[2] / 100000).toFixed(0)}L</h5>
                                    <h6>YTD <br />Target</h6>
                                </div>
                            </div>
                            <div className='col-md-3' style={{ paddingLeft: 30 }}>
                                <div className='UserPageCounter'>
                                    <h5 className='blue'>
                                        {
                                            isNaN(anlyData[0].data.split(",")[3] / anlyData[0].data.split(",")[2]) || !isFinite(anlyData[0].data.split(",")[3] / anlyData[0].data.split(",")[2])
                                                ? 0
                                                : (((anlyData[0].data.split(",")[3] / anlyData[0].data.split(",")[2])) * 100).toFixed(0)
                                        }%
                                    </h5>
                                    <h6>YTD <br />Achieved</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    {stdProd.length != 0 && <div className='container-fluid mt-4 FeatureClass' style={{ backgroundColor: 'rgba(244,244,244,1)' }}>
                        <div className='row'>
                            <div className='d-flex justify-content-between align-items-center pb-2'>
                                <h3 className='mt-2'>Featured Products</h3>
                                <Link style={{ color: 'black', cursor: 'pointer' }} to={'/feaProduct'}><p>View All</p></Link>
                            </div>
                            {stdProd.map((item, index) => (
                                <div className='col-lg-3' key={index}>
                                    <div className='ProductCardOne'>
                                        {item.ItemDp ? (
                                            <Link to={`/productDetails/${item.CustomerLinkItemCode}`} style={{ cursor: 'pointer', color: '#222222' }}>
                                                <img src={item.CustomerLinkItemImageData != "" ? `data:image/png;base64,${item.CustomerLinkItemImageData}` : notfound}
                                                 className={item.CustomerLinkItemImageData===""?'QuickNotFimg2':'img-fluid-pr'} alt="" />
                                            </Link>
                                        ) : (
                                            <span style={{ cursor: 'not-allowed', color: 'gray' }}>
                                                <img src={item.CustomerLinkItemImageData != "" ? `data:image/png;base64,${item.CustomerLinkItemImageData}` : notfound}
                                                 className={item.CustomerLinkItemImageData===""?'QuickNotFimg2':'img-fluid-pr'} alt="" />
                                            </span>
                                        )}
                                        <div className='ProductContent'>
                                            <div className='ProductTitle'>
                                                <div>{item.CustomerLinkItemName}</div>
                                                <div>Product&nbsp;Code:&nbsp;{item.CustomerLinkItemCode}</div>
                                                {item.ItemDp !== 0 && <div className="pb-2">MRP:&nbsp;<strike><span className="RuppeFont">₹</span>{formatIndianNumber(item.CustomerLinkItemMRP)}</strike>&nbsp;DP:&nbsp;<span className="RuppeFont">₹</span>&nbsp;{formatIndianNumber(item.CustomerLinkItemDP)}</div>}
                                            </div>
                                            <div className='ProductBtn'>
                                                {item.ItemDp > 0 ? (
                                                    <button type='button'>Add&nbsp;
                                                        <svg width="16" height="16" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M7.29102 9.91663H27.4014C27.8089 9.91664 28.2119 9.9996 28.5843 10.1602C28.9568 10.3207 29.2905 10.5553 29.5639 10.8488C29.8373 11.1423 30.0444 11.4882 30.1718 11.8642C30.2992 12.2402 30.3441 12.638 30.3035 13.0319L29.4285 21.5319C29.3565 22.231 29.0197 22.8792 28.4835 23.3507C27.9472 23.8222 27.2497 24.0833 26.5264 24.0833H12.5993C11.9248 24.0836 11.2711 23.8567 10.7494 23.4413C10.2277 23.026 9.8703 22.4478 9.7381 21.8053L7.29102 9.91663Z" stroke="white" strokeWidth="1.5" strokeLinejoin="round" />
                                                            <path d="M7.29102 9.91667L6.10977 5.32242C6.03077 5.01607 5.84865 4.74415 5.59236 4.54985C5.33607 4.35554 5.02029 4.25001 4.69518 4.25H2.91602M11.666 29.75H14.5827M23.3327 29.75H26.2493" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </button>
                                                ) : (
                                                    <div>
                                                        <button onClick={() => handleQuoteClick(item.CustomerLinkItemCode,item.CustomerLinkItemName)}>Get Quote</button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>}
                    {reoProd.length != 0 && <div className='container-fluid mt-4 FeatureClass'>
                        <div className='row'>
                            <div className='d-flex justify-content-between align-items-center pb-2'>
                                <h3 className='mt-2'>Reorder Products</h3>
                                <Link style={{ color: 'black', cursor: 'pointer' }} to={'/reOrderProducts'}><p>View All</p></Link>
                            </div>
                            {reoProd.map((item, index) => (
                                <div className='col-lg-3' key={index}>
                                    <Link to={`/productDetails/${item.CustomerLinkItemCode}`} style={{ cursor: 'pointer', color: '#222222' }}>
                                        <div className='ProductCardOne'>
                                            <Link to={`/productDetails/${item.CustomerLinkItemCode}`} style={{ cursor: 'pointer' }}>
                                                <img src={item.CustomerLinkItemImageData != "" ? `data:image/png;base64,${item.CustomerLinkItemImageData}` : notfound}
                                                 className={item.CustomerLinkItemImageData===""?'QuickNotFimg2':'img-fluid-pr'} alt="" /></Link>
                                            <div className='ProductContent'>
                                                <div className='ProductTitle'>
                                                    <div>{item.CustomerLinkItemName}</div>
                                                    <div>Product&nbsp;Code:&nbsp;{item.CustomerLinkItemCode}</div>
                                                    <div className="pb-2"><strike><span className="RuppeFont">₹</span>{formatIndianNumber(item.CustomerLinkItemMRP)}</strike>&nbsp;{formatIndianNumber(item.CustomerLinkItemDP)}</div>
                                                </div>
                                                <div className='ProductBtn'>
                                                    <button type='button'>Add&nbsp;
                                                        <svg width="16" height="16" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M7.29102 9.91663H27.4014C27.8089 9.91664 28.2119 9.9996 28.5843 10.1602C28.9568 10.3207 29.2905 10.5553 29.5639 10.8488C29.8373 11.1423 30.0444 11.4882 30.1718 11.8642C30.2992 12.2402 30.3441 12.638 30.3035 13.0319L29.4285 21.5319C29.3565 22.231 29.0197 22.8792 28.4835 23.3507C27.9472 23.8222 27.2497 24.0833 26.5264 24.0833H12.5993C11.9248 24.0836 11.2711 23.8567 10.7494 23.4413C10.2277 23.026 9.8703 22.4478 9.7381 21.8053L7.29102 9.91663Z" stroke="white" strokeWidth="1.5" strokeLinejoin="round" />
                                                            <path d="M7.29102 9.91667L6.10977 5.32242C6.03077 5.01607 5.84865 4.74415 5.59236 4.54985C5.33607 4.35554 5.02029 4.25001 4.69518 4.25H2.91602M11.666 29.75H14.5827M23.3327 29.75H26.2493" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div></Link>
                                </div>
                            ))}
                        </div>
                    </div>}
                    {offProd.length != 0 && <div className='container-fluid mt-4 mb-2 FeatureClass' style={{ backgroundColor: 'rgba(244,244,244,1)' }}>
                        <div className='row'>
                            <div className='d-flex justify-content-between align-items-center pb-2'>
                                <h3 className='mt-2'>Special Offers</h3>
                                <Link style={{ color: 'black', cursor: 'pointer' }} to={'/specialOffer'}><p>View All</p></Link>
                            </div>
                            {offProd.map((item, index) => (
                                <div className='col-lg-3 p-0' key={index}>
                                    <div className='ProductCardOne'>
                                        <Link to={`/productDetails/${item.CustomerLinkItemCode}`} style={{ cursor: 'pointer' }}>
                                            <img src={item.CustomerLinkItemImageData != "" ? `data:image/png;base64,${item.CustomerLinkItemImageData}` : notfound}
                                                className={item.CustomerLinkItemImageData===""?'QuickNotFimg2':'img-fluid-pr'} alt="" /></Link>
                                        <div className='ProductContent'>
                                            <div className="ProductOffer">
                                                <span><i class="fa-solid fa-tags"></i> OFFER*</span>
                                            </div>
                                            <div className='ProductTitle'>
                                                <div>{item.CustomerLinkItemName}</div>
                                                <div>Product&nbsp;Code:&nbsp;{item.CustomerLinkItemCode}</div>
                                                <div className="pb-2"><strike><span className="RuppeFont">₹</span>{formatIndianNumber(item.CustomerLinkItemMRP)}</strike>&nbsp;{formatIndianNumber(item.CustomerLinkItemDP)}</div>
                                            </div>
                                            <div className='ProductBtn'>
                                                <button type='button'>Add&nbsp;
                                                    <svg width="16" height="16" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7.29102 9.91663H27.4014C27.8089 9.91664 28.2119 9.9996 28.5843 10.1602C28.9568 10.3207 29.2905 10.5553 29.5639 10.8488C29.8373 11.1423 30.0444 11.4882 30.1718 11.8642C30.2992 12.2402 30.3441 12.638 30.3035 13.0319L29.4285 21.5319C29.3565 22.231 29.0197 22.8792 28.4835 23.3507C27.9472 23.8222 27.2497 24.0833 26.5264 24.0833H12.5993C11.9248 24.0836 11.2711 23.8567 10.7494 23.4413C10.2277 23.026 9.8703 22.4478 9.7381 21.8053L7.29102 9.91663Z" stroke="white" strokeWidth="1.5" strokeLinejoin="round" />
                                                        <path d="M7.29102 9.91667L6.10977 5.32242C6.03077 5.01607 5.84865 4.74415 5.59236 4.54985C5.33607 4.35554 5.02029 4.25001 4.69518 4.25H2.91602M11.666 29.75H14.5827M23.3327 29.75H26.2493" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>}
                    {/*<div className='container-fluid mt-4 mb-4'>*/}
                    {/*    <div className='row'>*/}
                    {/*        <div className='col-md-6 position-relative'>*/}
                    {/*            <button className="btn-quote-container1" onClick={() => handleQuoteClick("", {bannerImgName1})}>Get Quote</button>*/}
                    {/*            */}{/*<img src={h1img} className='img-fluid responsive-img' alt="" />*/}
                    {/*            <img src={`data:image/png;base64,${bannerImg1}`} className='img-fluid responsive-img' alt="" />*/}
                    {/*        </div>*/}
                    {/*        <div className='col-md-6 position-relative'>*/}
                    {/*            <button className="btn-quote-container2" onClick={() => handleQuoteClick("", { bannerImgName2 })}>Get Quote</button>*/}
                    {/*            */}{/*<img src={h2img} className='img-fluid responsive-img' alt="" />*/}
                    {/*            <img src={`data:image/png;base64,${bannerImg2}`} className='img-fluid responsive-img' alt="" />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className='container-fluid mt-4 mb-4'>
                        <div className='row'>
                            <div className='col-md-6' style={{ width: '49%' }}>
                                <button className="btn-quote-container1" onClick={() => handleQuoteClick("", { bannerImgName1 })}>Get Quote</button>
                                {/*<img src={h1img} className='img-fluid responsive-img' alt="" />*/}
                                <img src={`data:image/png;base64,${bannerImg1}`} className='img-fluid responsive-img' alt="" />
                            </div>
                            <div className='col-md-6' style={{ width: '49%' }}>
                                <button className="btn-quote-container2" onClick={() => handleQuoteClick("", { bannerImgName2 })}>Get Quote</button>
                                {/*<img src={h2img} className='img-fluid responsive-img' alt="" />*/}
                                <img src={`data:image/png;base64,${bannerImg2}`} className='img-fluid responsive-img' alt="" />
                            </div>
                        </div>
                    </div>
                    <Modal size={'sm'} backdrop={'static'} style={{ marginTop: '3rem' }} open={open} onClose={handleClose}>
                        <Modal.Header>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="container-fluid">
                                <h5>Quotation Request</h5>
                                <div className='row mt-3'>
                                    <div className='col-md-12 mb-3'>
                                        <input
                                            value={usName}
                                            onInput={() => setError(false)}
                                            style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                            onChange={(event) => setUsName(event.target.value)}
                                            type="text" placeholder="Enter Name" className='form-control' />
                                    </div>
                                    <div className='col-md-12 mb-3'>
                                        <input
                                            type="text"
                                            onInput={() => setError(false)}
                                            style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                            value={usPhone}
                                            onChange={(event) => setUsPhone(event.target.value)}
                                            placeholder="Enter Phone" className='form-control' />
                                    </div>
                                    <div className='col-md-12 mb-3'>
                                        <input
                                            type="text"
                                            onInput={() => setError(false)}
                                            style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                            value={usEmail}
                                            onChange={(event) => setUsEmail(event.target.value)}
                                            placeholder="Enter Email"
                                            className='form-control' />
                                    </div>
                                    <div className='col-md-12 mb-3'>
                                        <textarea
                                            type="number"
                                            onInput={() => setError(false)}
                                            style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                            value={usRemark}
                                            onChange={(event) => setUsRemark(event.target.value)}
                                            placeholder="Enter Remarks"
                                            className='form-control' ></textarea>
                                    </div>
                                    <div className='col-md-12 text-center'>
                                        <AmsButton
                                            onClick={handleGetQuote}
                                            marginBottom={20}
                                            icon={''}
                                            textcolor={'#fff'}
                                            buttonwid={'75%'}
                                            color={'#3D97EA'}
                                            label={'Submit'} />
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Footer />
                </div>
            )}
        </div>
    )
}