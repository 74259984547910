import logo from '../../assets/logo.png'
import homeIcon from '../../assets/Icons/home_icon.png'
import noti from '../../assets/Icons/noti.png'
import user from '../../assets/Icons/user.png'
import cart from '../../assets/Icons/cart.png'
import notFound from "../../assets/notfound.png"
import './UserHeader.css'
import { useEffect, useState } from 'react'
import { Badge, Dropdown, Popover, Whisper, Modal } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { Link, useLocation } from 'react-router-dom';
import { GetSessions, RemoveSesssions } from '../../services/SessionService';
import { AmsButton } from '../../core/Button/Button';
import QuickOrderService from '../../services/QuickOrderService'
import HomeService from '../../services/HomeService'
import ProductService from '../../services/ProductService'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { CartAtom, LinkedItem } from '../../rec-state/atoms'
import { LoaderCheck } from '../../core/Loader/Loader'
import { useMutation } from '@tanstack/react-query'
import UserService from '../../services/UserService'
import { AMToasterService } from '../../services/ToasterSerivce'
import { saveToIndexedDB, getFromIndexedDB }  from '../../constants/IndexedDb.jsx'


export const UserHeader = () => {
    const [notifcat, setNotifcat] = useState([])
    const [notLoc, setNotLoc] = useState([])
    const [notVal, setNotVal] = useState("today")
    const [activeItem, setActiveItem] = useState("today");
    const [foundArray, setFoundArray] = useState([{
        CustomerLinkDetCode: "",
        CustomerLinkDetId: 0,
        CustomerLinkItemAvgSalesQty: 0,
        CustomerLinkItemBoxQty: 0,
        CustomerLinkItemCode: "",
        CustomerLinkItemDP: 0,
        CustomerLinkItemImageData: "",
        CustomerLinkItemMRP: 0,
        CustomerLinkItemName: "",
        CustomerLinkItemNameAndCode: "",
        CustomerLinkItemPrice: 0,
        CustomerLinkItemStPrice: 0,
        CustomerLinkItemTaxPer: 0,
        ItemBrand: "",
        ItemColor: "",
        ItemLength: "",
        ItemMaterialDescription: "",
        ItemMaterialType: "",
        ItemPartnerOffer: "",
        ItemProductCode: "",
        ItemProductDetails: "",
        ItemProductType: "",
        ItemSize: "",
        ItemSpeciality: "",
        ItemWidth: "",
        ItemAttachedLeggings: "",
        ItemAbsorbentLayer: "",
        ItemFenestration: "",
        ItemIncise: "",
    }])
    const [menuData, setMenuData] = useState([])
    const [mainGRP, setMainGRP] = useState([])
    const [open, setOpen] = useState(false);
    const [openQuote, setOpenQuote] = useState(false);
    const [changPas, setChangPas] = useState(false);
    const [valueV, setValueV] = useState(0)
    const [myOdSearch, setMyOdSearch] = useState("")
    const handleOpen = () => { setOpen(true); getNotification() }
    const handleClose = () => setOpen(false);
    const handleChOpen = () => setChangPas(true);
    const handleChClose = () => setChangPas(false);
    const [amuser, setAMUser] = useState(JSON.parse(GetSessions('AM_USER_RE')));
    const [routeNme, setRouteName] = useState(useLocation().pathname.split('/')[1])
    const cartCount = useRecoilValue(CartAtom)
    const setLinkedProds = useSetRecoilState(LinkedItem)
    const [fItem, setFItem] = useState([])
    const [totalBox, setTotalBox] = useState(0)
    const [onScllShw, setOnScllShw] = useState(false)
    const notList = ['today', 'yesterday', '7days', '30days']
    const renderMenu = ({ onClose, left, top, className }, ref) => {
        const handleSelect = eventKey => {
            onClose();
        };
        return (
            <Popover ref={ref} visible={onScllShw} className={className} style={{ left, top }} full>
                <Dropdown.Menu onSelect={handleSelect}>
                    <Link to={'/paymentDetails'}>
                        <Dropdown.Item eventKey={1} >Payment Details</Dropdown.Item>
                    </Link>
                    {/*<Link to={'/kycDetails'}>*/}
                    {/*    <Dropdown.Item eventKey={2}>KYC Details</Dropdown.Item>*/}
                    {/*</Link>*/}
                    <Link to={'/feedBacks'}>
                        <Dropdown.Item eventKey={2}>Feedback</Dropdown.Item>
                    </Link>
                    <div>
                        <Dropdown.Item eventKey={2} onClick={handleChOpen}>Change Password</Dropdown.Item>
                    </div>
                    <Link to={'/'}>
                        <Dropdown.Item onClick={() => RemoveSesssions("AM_USER_RE")} eventKey={3}>Logout</Dropdown.Item>
                    </Link>
                </Dropdown.Menu>
            </Popover>
        );
    };
    const [stickTop, setStickTop] = useState(false)
    const stickNav = () => { window.scrollY > 50 ? setStickTop(true) : setStickTop(false); }
    const [menuOpen, setMenuOpen] = useState(false);
    const [banTrue, setBanTrue] = useState(false)
    const handleBanOff = () => { setBanTrue(false); localStorage.setItem('AM_POP_FLAG', false) }
    const [isLoad, setISLoad] = useState(false)
    const [baImg, setBanImg] = useState("")
    const [baImgName, setBanImgName] = useState("")
    const checkValLinkVal = useRecoilValue(LinkedItem)
    const handleCloseQuote = () => {setOpenQuote(false); setUsProduct("")}
    const handleOpenQuote = () => setOpenQuote(true);
    const [usProduct, setUsProduct] = useState("")
    const [usName, setUsName] = useState("")
    const [usPhone, setUsPhone] = useState("")
    const [usEmail, setUsEmail] = useState("")
    const [usRemark, setUsRemark] = useState("")
    const [error, setError] = useState(false)
    let productCount = 0;

    useEffect(() => {
        getLinkedProds()
        setAmUserData()
        getMainGroups()
        getMenuData()
    }, []);

    useEffect(() => {
        let productLoadingTimer;
        const checkDataCompletion = async () => {
            const data = await getFromIndexedDB('customerLinkedProducts');
            //console.log('Using data from IndexedDB:', data);

            if (Array.isArray(data)) {
                setFoundArray(data);
                setLinkedProds(data);
                setFItem(data);

                // Check if data is fully loaded
                if (productCount < data.length) {
                    // Data is not fully loaded, schedule another check
                    productLoadingTimer = setTimeout(checkDataCompletion, 3000);
                } else {
                    // Data is fully loaded, clear the timer
                    clearTimeout(productLoadingTimer);
                    //console.log('Data loading completed!');
                }
                productCount = data.length;
            } else {
                //console.error('Invalid data structure:', data);
                clearTimeout(productLoadingTimer); // Stop if there's an issue with data
            }
        };

        checkDataCompletion(); // Initial check

        return () => clearTimeout(productLoadingTimer); // Cleanup on component unmount
    }, []);

    useEffect(() => {
        // Add scroll event listener
        window.addEventListener('scroll', setOnScllShw(false));

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', setOnScllShw(false));
        };
    }, []); // Re-run effect if 'open' changes

    useEffect(() => {
        window.addEventListener('scroll', stickNav)
        return () => {
            window.removeEventListener('scroll', stickNav);
        };
    }, []);

    //useEffect(() => {
    //    getLinkedProds()
    //    setAmUserData()
    //    getCartBoxes()
    //    getMainGroups()
    //    getNotification()
    //    setRouteName(routeNme)
    //    getMenuData()
    //    setHoveredItemId("");
    //}, [routeNme]);
    useEffect(() => {
        setRouteName(routeNme)
        getCartBoxes()
        getNotification()
        setHoveredItemId("");
    }, [routeNme]);

    useEffect(() => {
        const bannerTimer = setTimeout(() => {
            getBannerImage()
        }, 7000);

        return () => clearTimeout(bannerTimer)
    }, [])

    const getLinkedProds = async () => {
        const data = await getFromIndexedDB('customerLinkedProducts');
        console.log('Using data from IndexedDB:', data);
        setFoundArray(data);
        setLinkedProds(data);
        setFItem(data);
    }

    // Get today's date
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set time to midnight for today

    // Get yesterday's date
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0); // Set time to midnight for yesterday

    // Get the date 7 days ago
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);
    sevenDaysAgo.setHours(0, 0, 0, 0); // Set time to midnight for 7 days ago

    // Get the date 30 days ago
    const thirtyDaysAgo = new Date(today);
    thirtyDaysAgo.setDate(today.getDate() - 30);
    thirtyDaysAgo.setHours(0, 0, 0, 0); // Set time to midnight for 30 days ago

    // Helper function to check if a date is within a specific range
    const isWithinRange = (date, startDate, endDate) => {
        return date >= startDate && date <= endDate;
    }

    // Function to filter notifications based on date range
    const filterNotifications = (range, data) => {
        let startDate, endDate;
        switch (range) {
            case 'today':
                startDate = today;
                endDate = today;
                break;
            case 'yesterday':
                startDate = yesterday;
                endDate = yesterday;
                break;
            case '7days':
                startDate = sevenDaysAgo;
                endDate = today;
                break;
            case '30days':
                startDate = thirtyDaysAgo;
                endDate = today;
                break;
            default:
                return [];
        }

        // Filter notifications based on the calculated range
        const filteredData = data.filter(notification => {
            const notificationDate = new Date(notification.NotiDate);
            return isWithinRange(notificationDate, startDate, endDate);
        });

        setNotifcat(prev => { return filteredData });
    }

    const handleChartChng = (event) => {
        setActiveItem(event)
        setNotVal(event)
        getNotification(event)
    }

    const getBannerImage = async () => {
        let toShowBanner = localStorage.getItem('AM_POP_FLAG')
        if (toShowBanner === 'true') {
            try {
                let payload = {
                    custcode: amuser.CustomerCode,
                    imagetype: 2
                }
                let data = await HomeService.getBannerImage(payload)
                if (data.Data[0].length !== 0) {
                    setBanTrue(true)
                    setISLoad(true)
                    setBanImg(data.Data[0][0].CampaignImage)  
                    setBanImgName(data.Data[0][0].CampaignName)
                } else {
                    setBanTrue(false)
                }
            } catch (error) {

            } finally {
                setISLoad(false)
            }
        } else {
            console.log("banner disabled")
        }
    }

    const setAmUserData = () => {
        let cicode = JSON.parse(GetSessions('AM_USER_RE'))
        setAMUser(cicode)
    }
    //debounce delay
    const debounceDelay = 300;

    useEffect(() => {
        // Debounced search logic
        const timeoutId = setTimeout(() => {
            if (myOdSearch.trim() !== "") {
                const term = myOdSearch.toLowerCase();
                const filtered = fItem.filter(item =>
                    item.CustomerLinkItemName.toLowerCase().includes(term) ||
                    item.CustomerLinkItemCode.toLowerCase().includes(term)
                );
                setFoundArray(filtered);
            } else {
                setFoundArray(fItem);
            }
        }, debounceDelay);

        // Cleanup function to cancel the previous timeout on new input
        return () => clearTimeout(timeoutId);

    }, [myOdSearch, fItem]);

    const handleSearchClick = async (event) => {
        setMyOdSearch(event.target.value);
    }
    
    //useEffect(() => {
    //    const fetchData = async () => {
    //        try {
    //            const data = await getFromIndexedDB('customerLinkedProducts'); // Retrieve data from IndexedDB
    //            console.log('Data fetched from IndexedDB:', data);

    //            if (!data || !data.Data || data.Data.length === 0) {
    //                console.log('Data not found in IndexedDB. Fetching from API...');
    //                await linkMutation.mutateAsync({ custcode: amuser.CustomerCode, itemgroupid: 0 });
    //            } else {
    //                console.log('Using data from IndexedDB:', data);
    //                setFoundArray(data.Data);
    //                setLinkedProds(data.Data);
    //                setFItem(data.Data);
    //            }
    //        } catch (error) {
    //            console.error('Error fetching data from IndexedDB:', error);
    //        }
    //    };

    //    fetchData();
    //}, []);

    //const linkMutation = useMutation({
    //    mutationFn: QuickOrderService.getCustomerLinkedProd,
    //    onSuccess: async (data) => {
    //        setFoundArray(data.Data);
    //        setLinkedProds(data.Data);
    //        setFItem(data.Data);
    //        await saveToIndexedDB('customerLinkedProducts', data.Data); // Wait for this to complete
    //        /*console.log('filter data - ', JSON.stringify(data.Data));*/
    //    },
    //    onError: (error) => {
    //        console.error('Error posting data:', error);
    //    },
    //});

    const getNotification = async (param) => {
        let payload = {
            NotiCustomerCode: amuser.CustomerCode
        }
        let data = await HomeService.getNotifcations(payload)
        setNotifcat(data.Data[0])
        setNotLoc(data.Data[0])
        filterNotifications(param === undefined ? notVal : param, data.Data[0])
    }

    const getMainGroups = async () => {
        let data = await ProductService.getProductsGroups()
        if (data.Data !== undefined) {
            setMainGRP(data.Data)
        }
    }

    const getCartBoxes = async () => {
        let payload = {
            KartDetCustomerCode: amuser.CustomerCode
        }
        let data = await QuickOrderService.getCartItems(payload)
        setTotalBox(data.Data.length)
    }

    const getMenuData = async () => {
        let payload = {
            CustomerCode: amuser.CustomerCode
        }
        let data = await ProductService.getMenuData(payload)
        setMenuData([])
        if (data.Data !== undefined) {
            setMenuData(data.Data)
            //console.log(JSON.stringify(data.Data))
        }
    }

    const [hoveredItemId, setHoveredItemId] = useState("");

    const renderMenus = () => {
        // Recursive function to render nested menu items
        const renderMenuItems = (items, level) => {
            const handleSelect = eventKey => {
                //onClose();
            };
            
                //console.log(isHovered)
            return items.map(item => {
                // Set the title and keys based on the level
                const title = level === 'main' ? item.ItemMainGroupName :
                    level === 'catg' ? item.ItemCatgGroupName :
                        level === 'group' ? item.ItemGroupName :
                            item.ItemSubGroupName;

                const id = level === 'main' ? item.ItemMainGroupId :
                    level === 'catg' ? item.ItemCatgGroupId :
                        level === 'group' ? item.ItemGroupId :
                            item.ItemSubGroupId;

                //const selId = `${level}-${item.ItemMainGroupId || item.ItemCatgGroupId || item.ItemGroupId || item.ItemSubGroupId}`;
                const isHovered = hoveredItemId === id;
                //console.log("hoveredItemId-", hoveredItemId)
                //console.log("selId-", id)

                // Check if the current item has children to determine whether to render Dropdown.Menu or Dropdown.Item
                if (item.children && item.children.length > 0) {
                    return (
                        <Dropdown.Menu title={title} key={id}
                            onMouseEnter={() => setHoveredItemId(id)}
                            onMouseLeave={() => setHoveredItemId("")}
                        >
                            {renderMenuItems(item.children, nextLevel(level))} {/* Recursively render children */}
                        </Dropdown.Menu>
                    );
                } else {
                    // If there are no more children (subgroup level), render a link as Dropdown.Item
                    if (level === 'main') {
                        return (
                            <Dropdown.Item key={id} onSelect={handleSelect}
                                onMouseEnter={() => setHoveredItemId(id)}
                                onMouseLeave={() => setHoveredItemId("")}
                                style={{ color: isHovered ? '#3d97ea' : '#222222' }}
                            >{title}
                            </Dropdown.Item>
                        );
                    } else {
                        return (
                            <Dropdown.Item key={id} onSelect={handleSelect}
                                onMouseEnter={() => setHoveredItemId(id)}
                                onMouseLeave={() => setHoveredItemId("")}
                            >
                                <Link style={{ color: isHovered ? '#3d97ea' : '#222222' }} to={`/categories?${title}?${id}`}>{title}</Link>
                            </Dropdown.Item>
                        );
                    }
                }
            });
        };

        // Function to determine the next level of nesting
        const nextLevel = (level) => {
            if (level === 'main') return 'catg';
            if (level === 'catg') return 'group';
            if (level === 'group') return 'subgroup';
            return 'subgroup';
        };

        return (
            <Popover full>
                <Dropdown.Menu open={menuOpen}
                    onClick={() => setMenuOpen(!menuOpen)} >
                    {renderMenuItems(menuData, 'main')} {/* Start with main group */}
                </Dropdown.Menu>
            </Popover>
        );
    };

    useEffect(() => {
        if (usProduct) {
            handleOpenQuote();
        }
    }, [usProduct]);

    const handleQuoteClick = (itemCode, linkitem) => {
        setUsProduct(itemCode + " ------ " + linkitem);
    };

    const resetQuotes = () => {
        setUsEmail("")
        setUsName("")
        setUsRemark("")
        setUsPhone("")
        setUsProduct("")
    }

    const handleGetQuote = async () => {
        if (usEmail != "" && usName != "" && usPhone != "" && usProduct != "") {
            let payload = {
                customer: JSON.parse(GetSessions('AM_USER_RE')).CustomerCode + ' - ' + JSON.parse(GetSessions('AM_USER_RE')).CustomerName,
                email: usEmail,
                name: usName,
                message: usRemark,
                phone: usPhone,
                product: usProduct
            }
            let data = await UserService.insertQuoteDetails(payload)
            if (data.Status) {
                AMToasterService('S', 'Successfull')
                resetQuotes()
            }
            handleClose()
        } else {
            setError(true)
            AMToasterService('E', 'Fill required fields')
        }
    }

    return (
        <div className={`container-fluid UserHomeShadow bg-white ${stickTop ? 'UserHomeShadowScroll' : ''}`}>
            <div className="row d-flex justify-content-end align-items-center" style={{ padding: '0 1rem 0 0.5rem' }}>
                <div className="col-md-3 p-0">
                    <div>
                        <img src={logo} height={'60px'} alt="" />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="input-group mb-3 mt-2 Searchbox">
                        <li className="CustomDropList">
                            <div className="custom-dropdown-toggle">
                                All Products
                                <Whisper placement="bottomEnd" trigger={['click']} speaker={renderMenus()}>
                                    <i className="fa-solid fa-chevron-down" style={{ marginLeft: '8px', color: routeNme === 'categories' ? '#3d97ea' : 'black' }}></i>
                                </Whisper>
                            </div>
                        </li>
                        <i className="fa-solid fa-magnifying-glass"></i>
                        <input type="text" value={myOdSearch} onChange={handleSearchClick} className="form-control" placeholder="search products..." aria-label="Example text with button addon" aria-describedby="button-addon1" />
                        {myOdSearch !== '' && <div className='SearchResult' onMouseLeave={() => setMyOdSearch("")}>{foundArray.length !== 0 ? (
                            foundArray.map((item, index) => (
                                <>
                                    <Link style={{ textDecoration: 'none', color: '#222222' }} to={`/productDetails/${item.CustomerLinkItemCode}`} onClick={() => { setFoundArray([]); setMyOdSearch("") }}>
                                        <div className="itemResult" key={index}>
                                            <div className='itemReCont'>
                                                <img src={item.CustomerLinkItemImageData != "" ? `data:image/png;base64,${item.CustomerLinkItemImageData}` : notFound} alt="" />
                                                <span>{item.CustomerLinkItemName}</span>
                                            </div>
                                            <div>
                                                <i className="fa-solid fa-arrow-right"></i>
                                            </div>
                                            <div className="itemTooltip">
                                                <div className='p-1'>
                                                    <img src={item.CustomerLinkItemImageData != "" ? `data:image/png;base64,${item.CustomerLinkItemImageData}` : notFound} alt="" />
                                                </div>
                                                <div className='itemToolContent'>
                                                    <p>{item.CustomerLinkItemName}</p>
                                                    <p>Product Code &nbsp;:&nbsp;{item.CustomerLinkItemCode}</p>
                                                    {item.ItemPartnerOffer!=="" && <div className='itemOffer'><i class="fa-solid fa-tag fa-beat"></i>&nbsp;offer</div>}
                                                </div>
                                            </div>
                                        </div></Link>
                                </>

                            ))
                        ) : (
                            <div className='text-center notFoundClas'>
                                <img src={require('../../assets/search/s1.jpg')} className='img-fluid' alt="" />
                                <p className='diplsay-6'>Search result not found!</p>
                            </div>
                        )}</div>}
                    </div>
                </div>
                {/*<div className="col-md-3 UserPageIcon">*/}
                {/*    <div className='row RowPad d-flex justify-content-end align-items-center' style={{ padding: '0 1rem 0 0.5rem' }}>*/}
                {/*        <div className='col' style={{ marginLeft: "42%" }}>*/}
                {/*            <Link to="/cart" style={{ cursor: 'pointer' }}>*/}
                {/*                <Badge color='blue' content={cartCount === 0 ? totalBox : cartCount}>*/}
                {/*                    <img src={cart} alt="" />*/}
                {/*                </Badge>*/}
                {/*            </Link>*/}
                {/*        </div>*/}
                {/*        <div className='col' style={{ marginLeft: "0%" }}>*/}
                {/*            <Whisper placement="bottomStart" trigger="click" speaker={renderMenu}>*/}
                {/*                <img src={user} alt="" style={{ cursor: 'pointer' }} />*/}
                {/*            </Whisper>*/}
                {/*        </div>*/}
                {/*        <div className='col' style={{ marginLeft: "0%" }}>*/}
                {/*            <Badge color='blue' content={notLoc.length} style={{ cursor: 'pointer' }} onClick={handleOpen} src={noti} alt="">*/}
                {/*                <img onClick={handleOpen} src={noti} alt="" />*/}
                {/*            </Badge>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="col-md-3 UserPageIcon">
                    <div className="RowPad">
                        {/* Cart Icon */}
                        <div className="col">
                            <Link to="/cart" style={{ cursor: 'pointer' }}>
                                <Badge color="blue" content={cartCount === 0 ? totalBox : cartCount}>
                                    <img src={cart} alt="Cart Icon" />
                                </Badge>
                            </Link>
                        </div>

                        {/* User Icon */}
                        <div className="col">
                            <Whisper placement="bottomStart" trigger="click" speaker={renderMenu}>
                                <img src={user} alt="User Icon" style={{ cursor: 'pointer' }} />
                            </Whisper>
                        </div>

                        {/* Notification Icon */}
                        <div className="col">
                            <Badge
                                color="blue"
                                content={notLoc.length}
                                style={{ cursor: 'pointer' }}
                                onClick={handleOpen}
                            >
                                <img onClick={handleOpen} src={noti} alt="Notification Icon" />
                            </Badge>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row d-flex justify-content-between m-0 ">
                <ul className="UserHomeList">
                    <li className=''>
                        <a aria-current="page" href="/userHome">
                            <img src={homeIcon} style={{ marginTop: -8, height: '25px' }} alt="" />
                        </a>
                    </li>
                    <div className='borderRightClass'></div>
                    <li className="CustomDrop">
                        <span style={{ cursor: 'pointer', color: routeNme === 'categories' ? '#3d97ea' : 'black' }}>Categories</span>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Whisper placement="bottomEnd" trigger={['click']} speaker={renderMenus()}>
                            <i className="fa-solid fa-chevron-down" style={{ position: 'absolute', right: 0, color: routeNme === 'categories' ? '#3d97ea' : 'black' }}></i>
                        </Whisper>
                    </li>
                    <div className='borderRightClass'></div>
                    <li className=''>
                        <a href="/feaProduct" style={{ color: routeNme === 'feaProduct' ? '#3d97ea' : 'black' }}>Featured Products</a>
                    </li>
                    <div className='borderRightClass'></div>
                    <li className=''>
                        <a href="/dashBoard" style={{ color: routeNme === 'dashBoard' ? '#3d97ea' : 'black' }}>Dashboard</a>
                    </li>
                    <div className='borderRightClass'></div>
                    <li className=''>
                        <a href="/myOrders" style={{ color: routeNme === 'myOrders' ? '#3d97ea' : 'black' }}>My Order</a>
                    </li>
                    <div className='borderRightClass'></div>
                    <li>
                        <a href="/quickOrder">
                            <button type='button' className='btn btn-primary' style={{ fontSize: 12, marginLeft: "18%" }}>QUICK ORDER</button>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="UserHeadName">
                <h6>{amuser.CustomerName}</h6>
            </div>
            <Modal className='UserHeadNotModal' size={'xs'} overflow={true} backdrop={'static'} style={{ position: 'absolute', height: '100vh', right: 0, top: 0, zIndex: '10002' }} open={open}
                onClose={handleClose}>
                <Modal.Header>
                    <Modal.Title className='CustomNotList'>
                        <div className='d-flex justify-content-between align-items-center mt-3 CustBorderNot'>
                            <h5>Notifications</h5>
                            <h6 onClick={() => handleChartChng('All')}>view All</h6>
                        </div>
                        <div className='CustomNotBar mt-2'>
                            {notList.map(item => (
                                <button
                                    key={item}
                                    onClick={() => handleChartChng(item)}
                                    className={`${activeItem === item ? 'button-active' : 'button-inactive'}`}
                                >
                                    {item.toUpperCase()}
                                </button>
                            ))}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {notifcat.map((item) => (
                        <div key={item.NotiId} className='CustomNotifcations'>
                            <div className='CustomNotCont pb-1 pt-1'>
                                <div><i class="fa-regular fa-bell"></i></div>
                                <h6>{item.NotiMessage}</h6>
                            </div>
                        </div>
                    ))}
                </Modal.Body>
            </Modal>

            <Modal size={'sm'} backdrop={'static'} style={{ marginTop: '3rem' }} open={changPas} onClose={handleChClose}>
                <Modal.Header>
                    {/* <Modal.Title>Change Password</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <h5>Change Password</h5>
                        <div className='row mt-3'>
                            <div className='col-md-12 mb-3'>
                                <label>Current Password</label>
                                <input type="text" className='form-control' />
                            </div>
                            <div className='col-md-12 mb-3'>
                                <label>New Password</label>
                                <input type="text" className='form-control' />
                            </div>
                            <div className='col-md-12 mb-3'>
                                <label>Confirm Password</label>
                                <input type="text" className='form-control' />
                            </div>
                            <div className='col-md-12 text-center'>
                                <AmsButton
                                    marginBottom={20}
                                    textcolor={'#fff'}
                                    icon={''}
                                    buttonwid={'75%'}
                                    color={'#3D97EA'}
                                    label={'Submit'} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size={'lg'} style={{ zIndex: '9999' }} backdrop={'static'} open={banTrue} onClose={handleBanOff}>
                <Modal.Header>
                </Modal.Header>
                <Modal.Body>
                    {baImg !== undefined &&
                        <><img src={`data:image/png;base64,${baImg}`} className='BannerImg' alt="" /><div className='position-relative"'>
                        <button className="btn-quote-container-header" onClick={() => handleQuoteClick("", {baImgName})}>Get Quote</button>
                        </div></>
                    }
                    {isLoad && <LoaderCheck text={'Loading...'} isLoad={isLoad} />}
                </Modal.Body>
            </Modal>

            <Modal size={'sm'} backdrop={'static'} style={{ marginTop: '3rem', zIndex: 10000 }} open={openQuote} onClose={handleCloseQuote}>
                <Modal.Header>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <h5>Quotation Request</h5>
                        <div className='row mt-3'>
                            <div className='col-md-12 mb-3'>
                                <input
                                    value={usName}
                                    onInput={() => setError(false)}
                                    style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                    onChange={(event) => setUsName(event.target.value)}
                                    type="text" placeholder="Enter Name" className='form-control' />
                            </div>
                            <div className='col-md-12 mb-3'>
                                <input
                                    type="text"
                                    onInput={() => setError(false)}
                                    style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                    value={usPhone}
                                    onChange={(event) => setUsPhone(event.target.value)}
                                    placeholder="Enter Phone" className='form-control' />
                            </div>
                            <div className='col-md-12 mb-3'>
                                <input
                                    type="text"
                                    onInput={() => setError(false)}
                                    style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                    value={usEmail}
                                    onChange={(event) => setUsEmail(event.target.value)}
                                    placeholder="Enter Email"
                                    className='form-control' />
                            </div>
                            <div className='col-md-12 mb-3'>
                                <textarea
                                    type="number"
                                    onInput={() => setError(false)}
                                    style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                    value={usRemark}
                                    onChange={(event) => setUsRemark(event.target.value)}
                                    placeholder="Enter Remarks"
                                    className='form-control' ></textarea>
                            </div>
                            <div className='col-md-12 text-center'>
                                <AmsButton
                                    onClick={handleGetQuote}
                                    marginBottom={20}
                                    icon={''}
                                    textcolor={'#fff'}
                                    buttonwid={'75%'}
                                    color={'#3D97EA'}
                                    label={'Submit'} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}