import { Banner } from "../../components/Banner/Banner"
import { Footer } from "../../components/Footer/Footer"
import { UserHeader } from "../../components/UserHeader/UserHeader"
import './SuggestedProducts.css'
import notfound from '../../assets/notfound.png'
import { BreadCmp } from "../../core/BreadCrump/BreadCmp"
import { Link, useLocation } from "react-router-dom"
import { useState, useEffect } from "react"
import { useRecoilValue } from "recoil"
import { FeaOrderAtom, SuggestedAtom } from "../../rec-state/atoms"
import { GetSessions } from "../../services/SessionService"
import { Modal, Pagination } from "rsuite"
import UserService from "../../services/UserService"
import { AmsButton } from "../../core/Button/Button"
import { AMToasterService } from "../../services/ToasterSerivce"
import QuickOrderService from "../../services/QuickOrderService"
import { LoaderCheck } from "../../core/Loader/Loader"


export const SuggestedProducts = () => {
    const locationPath = useLocation()
    const routeData = useLocation()
    const [odState, setODState] = useState('first')
    const [open, setOpen] = useState(false)
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);
    const [activePage, setActivePage] = useState(1);
    const [usName, setUsName] = useState("")
    const [usPhone, setUsPhone] = useState("")
    const [usEmail, setUsEmail] = useState("")
    const [usRemark, setUsRemark] = useState("")
    const [error, setError] = useState(false)
    const [amuser, setAMUser] = useState(JSON.parse(GetSessions('AM_USER_RE')))
    const [cuSuggested, setCuSuggested] = useState(useRecoilValue(SuggestedAtom))
    const [usProduct, setUsProduct] = useState("")

    useEffect(() => {
        getSuggestedProd()
    }, []);

    useEffect(() => {
        if (usProduct) {
            handleOpen();
        }
    }, [usProduct]);

    const handleQuoteClick = (itemCode) => {
        setUsProduct(itemCode);
    };

    const getSuggestedProd = async () => {
        const itemCode = routeData.pathname.split('/')[2];
        let payload = {
            custcode: amuser.CustomerCode,
            itemcode: itemCode
        }
        let data = await QuickOrderService.getCustomerSuggestedPrd(payload)
        setCuSuggested(data.Data)
    }
    const resetQuotes = () => {
        setUsEmail("")
        setUsName("")
        setUsRemark("")
        setUsPhone("")
        setUsProduct("")
    }
    const handleGetQuote = async () => {
        if (usEmail != "" && usName != "" && usPhone != "" && usProduct != "") {
            let payload = {
                customer: JSON.parse(GetSessions('AM_USER_RE')).CustomerCode + ' - ' + JSON.parse(GetSessions('AM_USER_RE')).CustomerName,
                email: usEmail,
                name: usName,
                message: usRemark,
                phone: usPhone,
                product: usProduct
            }
            let data = await UserService.insertQuoteDetails(payload)
            if (data.Status) {
                AMToasterService('S', 'Successfull')
                resetQuotes()
            }
            handleClose()
        } else {
            setError(true)
            AMToasterService('E', 'Fill required fields')
        }
    }
    const itemsPerPage = 12;
    const startIndex = (activePage - 1) * itemsPerPage;
    const currentItems = cuSuggested.slice(startIndex, startIndex + itemsPerPage);

    if (currentItems.length === 0) {
        return (
            <LoaderCheck isLoad={true}></LoaderCheck>
        )
    }
    return (
        <div>
            <Banner
                text={""}
            />
            <UserHeader />
            <BreadCmp link={locationPath.pathname} />
            <div className="container-fluid mt-2 mb-4 fade-image-class">
                <div className="row mt-2 mb-2">
                    <div className='col-md-12'>
                        <h3>Suggested Products</h3>
                    </div>
                </div>
                <div className="row">
                    {currentItems.map((item, index) => (
                        <div className='col-lg-3' key={index}>
                            <div className='ProductCardOne'>
                                {item.ItemDp ? (
                                    <Link to={`/productDetails/${item.CustomerLinkItemCode}`} style={{ cursor: 'pointer',color:'#222222' }}>
                                        <img src={item.CustomerLinkItemImageData != "" ? `data:image/png;base64,${item.CustomerLinkItemImageData}` : notfound}
                                         className={item.CustomerLinkItemImageData===""?'QuickNotFimg2':'img-fluid-pr'} alt="" />
                                    </Link>
                                ) : (
                                    <span style={{ cursor: 'not-allowed', color: 'gray' }}>
                                        <img src={item.CustomerLinkItemImageData != "" ? `data:image/png;base64,${item.CustomerLinkItemImageData}` : notfound}
                                         className={item.CustomerLinkItemImageData===""?'QuickNotFimg2':'img-fluid-pr'} alt="" />
                                    </span>
                                )}
                                <div className='ProductContent'>
                                    <div className='ProductTitle'>
                                        <div>{item.CustomerLinkItemName}</div>
                                        <div>Product&nbsp;Code:&nbsp;{item.CustomerLinkItemCode}</div>
                                        {item.ItemDp !== 0 && <div className="pb-2">MRP:&nbsp;<strike><span className="RuppeFont">₹</span>{item.CustomerLinkItemMRP}</strike>&nbsp;DP:&nbsp;<span className="RuppeFont">₹</span>&nbsp;{item.CustomerLinkItemDP}</div>}
                                    </div>
                                    <div className='ProductBtn'>
                                        {item.ItemDp > 0 ? (
                                            <Link to={`/productDetails/${item.CustomerLinkItemCode}`} style={{ cursor: 'pointer',color:'#222222' }}>
                                              <button type='button'>Add&nbsp;
                                                <svg width="16" height="16" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.29102 9.91663H27.4014C27.8089 9.91664 28.2119 9.9996 28.5843 10.1602C28.9568 10.3207 29.2905 10.5553 29.5639 10.8488C29.8373 11.1423 30.0444 11.4882 30.1718 11.8642C30.2992 12.2402 30.3441 12.638 30.3035 13.0319L29.4285 21.5319C29.3565 22.231 29.0197 22.8792 28.4835 23.3507C27.9472 23.8222 27.2497 24.0833 26.5264 24.0833H12.5993C11.9248 24.0836 11.2711 23.8567 10.7494 23.4413C10.2277 23.026 9.8703 22.4478 9.7381 21.8053L7.29102 9.91663Z" stroke="white" strokeWidth="1.5" strokeLinejoin="round" />
                                                    <path d="M7.29102 9.91667L6.10977 5.32242C6.03077 5.01607 5.84865 4.74415 5.59236 4.54985C5.33607 4.35554 5.02029 4.25001 4.69518 4.25H2.91602M11.666 29.75H14.5827M23.3327 29.75H26.2493" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </button>
                                            </Link>
                                        ) :
                                            (<div>
                                                 <button onClick={() => handleQuoteClick(item.CustomerLinkItemCode)}>Get Quote</button>
                                            </div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <Pagination size="md" total={cuSuggested.length} limit={itemsPerPage} activePage={activePage} onChangePage={setActivePage} />
                    {cuSuggested.length == 0 && <div className="text-center p-5">No data found</div>}
                </div>
            </div>
            <Footer />
            <Modal size={'sm'} backdrop={'static'} style={{ marginTop: '3rem' }} open={open} onClose={handleClose}>
                <Modal.Header>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <h5>Quotation Request</h5>
                        <div className='row mt-3'>
                            <div className='col-md-12 mb-3'>
                                <input
                                    value={usName}
                                    onInput={() => setError(false)}
                                    style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                    onChange={(event) => setUsName(event.target.value)}
                                    type="text" placeholder="Enter Name" className='form-control' />
                            </div>
                            <div className='col-md-12 mb-3'>
                                <input
                                    type="text"
                                    onInput={() => setError(false)}
                                    style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                    value={usPhone}
                                    onChange={(event) => setUsPhone(event.target.value)}
                                    placeholder="Enter Phone" className='form-control' />
                            </div>
                            <div className='col-md-12 mb-3'>
                                <input
                                    type="text"
                                    onInput={() => setError(false)}
                                    style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                    value={usEmail}
                                    onChange={(event) => setUsEmail(event.target.value)}
                                    placeholder="Enter Email"
                                    className='form-control' />
                            </div>
                            <div className='col-md-12 mb-3'>
                                <textarea
                                    type="number"
                                    onInput={() => setError(false)}
                                    style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                    value={usRemark}
                                    onChange={(event) => setUsRemark(event.target.value)}
                                    placeholder="Enter Remarks"
                                    className='form-control' ></textarea>
                            </div>
                            <div className='col-md-12 text-center'>
                                <AmsButton
                                    onClick={handleGetQuote}
                                    marginBottom={20}
                                    textcolor={'#fff'}
                                    buttonwid={'75%'}
                                    color={'#3D97EA'}
                                    label={'Submit'} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}