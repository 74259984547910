import { Link, useLocation } from "react-router-dom"
import { UserHeader } from "../../components/UserHeader/UserHeader"
import { BreadCmp } from "../../core/BreadCrump/BreadCmp"
import './Myorder.css'
import { useState, useEffect } from "react"
import { Banner } from "../../components/Banner/Banner"
import { Footer } from "../../components/Footer/Footer"
import { Modal } from 'rsuite'
import OrderService from "../../services/OrderService"
import { GetSessions } from "../../services/SessionService"
import { useSetRecoilState } from "recoil"
import { BatchAtom, InvoiceAtom } from "../../rec-state/atoms"
import HomeService from "../../services/HomeService"
import { LoaderCheck } from "../../core/Loader/Loader"
import formatIndianNumber from "../../services/FormatNumber"
import notfound from "../../assets/notfound.png"
import { AmsButton } from "../../core/Button/Button"
import { AMToasterService } from "../../services/ToasterSerivce"
import UserService from "../../services/UserService"
import { getFromIndexedDB } from '../../constants/IndexedDb.jsx'


export const Myorder = () => {
    const [orderMaster, setOrderMaster] = useState([])
    const [orderMasterTemp, setOrderMasterTemp] = useState([])
    const [orderPending, setOrderPending] = useState([])
    const [orderPendingTemp, setOrderPendingTemp] = useState([])
    const [myReturnMaster, setMyReturnMaster] = useState([])
    const [odState, setODState] = useState('first')
    const [checkInvoice, setCheckInvoice] = useState(false)
    const [orderSt, setOrderSt] = useState(null)
    const [reStatus,setReStatus] = useState([])
    const [details, setDetails] = useState([{
        "OrderDetItemCode": "",
        "ItemName": "",
        "ItemStatus": "",
        "ItemBoxQuantity": 0,
        "ItemUnitPrice": 0,
        "OrderDetLrNo": "",
        "OrderDetTransportation": "",
        "OrderDetLrDate": "",
        "OrderDetDelDate": "",
        "OrderDetQty": 0,
        "OrderDocNo": "",
        "OrderRemarks": "",
        "OrderDispatchedQty": 0,
        "OrderDetOpenQty": 0,
        "OrderDetStatusInvoiceNo": "",
        "KartNetAmount": 0,
        "OrderDetStatusInvoiceDate": "",
        "StatusDate": "",
        "ItemDP": 0,
        "KartDetOrderVal": 0,
        "KartDetTaxAmount": 0,
        "KartDetTotalAmount": 0,
        "OrderDocDate": "",
        "Delivery": [],
        "ReturnStatus": []
    }])
    const [detailsTemp, setDetailsTemp] = useState([{
        "OrderDetItemCode": "",
        "ItemName": "",
        "ItemStatus": "",
        "ItemBoxQuantity": 0,
        "ItemUnitPrice": 0,
        "OrderDetLrNo": "",
        "OrderDetTransportation": "",
        "OrderDetLrDate": "",
        "OrderDetDelDate": "",
        "OrderDetQty": 0,
        "OrderDocNo": "",
        "OrderRemarks": "",
        "OrderDispatchedQty": 0,
        "OrderDetOpenQty": 0,
        "OrderDetStatusInvoiceNo": "",
        "KartNetAmount": 0,
        "OrderDetStatusInvoiceDate": "",
        "StatusDate": "",
        "ItemDP": 0,
        "KartDetOrderVal": 0,
        "KartDetTaxAmount": 0,
        "KartDetTotalAmount": 0,
        "OrderDocDate": "",
        "Delivery": [],
        "ReturnStatus": []
    }])
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [openTrack, setOpenTrack] = useState(false)
    const handleOpenTrack = () => setOpenTrack(true);
    const handleCloseTrack = () => setOpenTrack(false);
    const setInvoiceValue = useSetRecoilState(InvoiceAtom)
    const setBathcValues = useSetRecoilState(BatchAtom)
    const [amuser, setAMUser] = useState(JSON.parse(GetSessions('AM_USER_RE')))
    const monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    const getMonthName = (month) => { return monthName[parseInt(month) - 1] }
    const [feaData, setFeaDat] = useState([])
    const { pathname } = useLocation()
    const [usProduct, setUsProduct] = useState("")
    const [usName, setUsName] = useState("")
    const [usPhone, setUsPhone] = useState("")
    const [usEmail, setUsEmail] = useState("")
    const [usRemark, setUsRemark] = useState("")
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true);
    const [openQuote, setOpenQuote] = useState(false);
    const handleOpenQuote = () => setOpenQuote(true);
    const handleCloseQuote = () => setOpenQuote(false);
    const handleQuoteClick = (itemCode,linkitem) => {
        setUsProduct(itemCode+" ------ "+linkitem);
    };
    
    const handleChange = (e) => {
        const value = e.target.value.toString().toLowerCase();
        const getSuggestion = orderMaster.filter((item) =>
            item.OrderDocNo.toLowerCase().includes(value) ||
            item.KartInvoiceNo.toLowerCase().includes(value)
        );
        const getSuggestionPending = orderPending.filter((item) =>
            item.OrderDocNo.toLowerCase().includes(value) ||
            item.KartInvoiceNo.toLowerCase().includes(value)
        );
        if (value != "") {
            setOrderMasterTemp(getSuggestion)
        } else {
            setOrderMasterTemp(orderMaster)
        }
        
        if (value != "") {
            setOrderPending(getSuggestionPending)
        } else {
            setOrderPending(orderPendingTemp)
        }
    }

    useEffect(() => {
        if (usProduct) {
            handleOpenQuote();
        }
    }, [usProduct]);

    const handleGetQuote = async () => {
        if (usEmail != "" && usName != "" && usPhone != "" && usProduct != "") {
            let payload = {
                customer: JSON.parse(GetSessions('AM_USER_RE')).CustomerCode + ' - ' + JSON.parse(GetSessions('AM_USER_RE')).CustomerName,
                email: usEmail,
                name: usName,
                message: usRemark,
                phone: usPhone,
                product: usProduct
            }
            let data = await UserService.insertQuoteDetails(payload)
            if (data.Status) {
                AMToasterService('S', 'Successfull')
                resetQuotes()
            }
            handleCloseQuote()
        } else {
            setError(true)
            AMToasterService('E', 'Fill required fields')
        }
    }

    const resetQuotes = () => {
        setUsEmail("")
        setUsName("")
        setUsRemark("")
        setUsPhone("")
        setUsProduct("")
    }

    //useEffect(() => {
    //    getOrderDetails("ORDERS", { OrderDocNo: "0" })
    //    setAmUserData()
    //    getReturnedOrder()
    //    getFeaturedData()
    //}, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true); // Show loader while fetching data
                //console.log("loader: ", loading)
                // Trigger all the functions and the mutation in parallel
                await Promise.all([
                    getOrderDetails("ORDERS", { OrderDocNo: "0" }),
                    setAmUserData(),
                    getReturnedOrder(),
                    getFeaturedData()
                ]);
            } catch (error) {
                console.error("Error in fetching data: ", error);
            } finally {
                setLoading(false); // Hide loader after fetching is done
            }
            //console.log("loader: ", loading)
        };

        fetchData(); // Call the async function
    }, []); // Empty dependency array to run only once when the component mounts
    const setAmUserData = async () => {
        let cicode = JSON.parse(GetSessions('AM_USER_RE'))
        setAMUser(cicode)
    }

    const getFeaturedData = async () => {
        let payload = {
            custcode: amuser.CustomerCode
        }
        //let data = await HomeService.getCustomerStandProd(payload)
        const data = await getFromIndexedDB('customerFeaturedProducts');
        //setFeaDat(data.Data[1].slice(0, 10))
        setFeaDat(data.slice(0, 10))
    }

    const downloadInvoice = async (invoiceNo) => {
        try {
            handleCloseTrack()
            setCheckInvoice(true)
            let payload = {
                invoiceno: invoiceNo
            }
            let data = await OrderService.getInvoice(payload)
            const blob = new Blob([data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'invoice.pdf');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error downloading the invoice:', error);
        } finally {
            setCheckInvoice(false)
        }
    };

    const getOrderDetails = async (type, orderid) => {
        let payload = {
            OrderCustCode: amuser.CustomerCode,
            OrderDocNo: orderid.OrderDocNo !== "" ? orderid.OrderDocNo : orderid.KartInvoiceNo,
            ShowOrderStatusOnly: "0"
        }
        let data = await OrderService.getOrderMasterDetails(payload)
        switch (type) {
            case 'VIEW_TRACK':
                setDetailsTemp(data.Data[0])
                setDetails(data.Data[0])
                //console.log("details - ", JSON.stringify(data.Data[0]))
                setOrderSt(orderid)
                let filterMap = data.Data[0].map(item => item.OrderDetStatusInvoiceNo).filter(item => item !== "")
                const uniqAr = [...new Set(filterMap)].join(",")
                setInvoiceValue({ innumbers: uniqAr, orderval: data.Data[0][0].KartNetAmount, orderdate: data.Data[0][0].OrderDocDate })
                setBathcValues(data.Data[1])
                break;
            case 'ORDERS':
                if (data.Data !== undefined) {
                    setOrderMaster(data.Data[0].filter((item) => item.Delivery.length === 3))
                    setOrderMasterTemp(data.Data[0].filter((item) => item.Delivery.length === 3))
                    //console.log("setOrderMasterTemp - ", JSON.stringify(data.Data[0].filter((item) => item.Delivery.length === 3)))
                    setOrderPending(data.Data[0].filter((item) => item.Delivery.length < 3))
                    setOrderPendingTemp(data.Data[0].filter((item) => item.Delivery.length < 3))
                    setBathcValues(data.Data[1])
                }
                break;
            default:
                break ;
        }
    }

    const getReturnedOrder = async (orderDoc) => {
        let payload = {
            OrderCustCode: amuser.CustomerCode,
            OrderDocNo: '0',
            ShowOrderStatusOnly: "0"
        }
        let data = await OrderService.getReturnedItems(payload)
        setMyReturnMaster(data.Data[0])
    }

    const chngOrderState = (value) => {
        switch (value) {
            case 'first':
                setODState('first');
                break;
            case 'second':
                setODState('second');
                break;
            case 'third':
                setODState('third');
                break;
            default:
                break;
        }   
    }   
    const [myOdSearch, setMyOdSearch] = useState("")    
    const handleMyorderSearch = (event) => {
        const term = event.target.value.toString()
        setMyOdSearch(term) 
        if (event.target.value !== "") {
            const filtered = details.filter((item) => item.ItemName.toLowerCase().includes(term.toLowerCase()) || item.OrderDetStatusInvoiceNo.toLowerCase().includes(term.toLowerCase()));
            setDetails(filtered);
        } else {
            setDetails(detailsTemp)
        }
    }

    if (checkInvoice) { return <LoaderCheck text={'Downloading...'} isLoad={checkInvoice}></LoaderCheck> }
    return (
        <div>
            {loading && <LoaderCheck text={'Loading...'} isLoad={loading}></LoaderCheck>}
            {!loading && (
                <div>
                    <Banner
                        text={""}
                    />
                    <UserHeader />
                    <BreadCmp link={pathname} />
                    <div className="container-fluid mb-5 fade-image-className">
                        <div className="row">
                            <div className="col-lg-10">
                                <div className="MyOrderSearchbar">
                                    <i className="fa-solid fa-magnifying-glass"></i>
                                    <input type="text" onChange={handleChange} className="form-control" placeholder="search..." />
                                </div>
                                <div className="MyorderTabs">
                                    <h6 className="text-center" onClick={(item) => chngOrderState('first')} style={{ color: odState === 'first' ? '#3D97EA' : '#222222' }}>My Completed Orders</h6>
                                    <div className="MyorderBorder"></div>
                                    <h6 className="ps-4" onClick={(item) => chngOrderState('second')} style={{ color: odState === 'second' ? '#3D97EA' : '#222222' }}>My Pending Orders</h6>
                                    <div className="MyorderBorder"></div>
                                    <h6 onClick={(item) => { chngOrderState('third'); }} style={{ color: odState === 'third' ? '#3D97EA' : '#222222' }} >My Returns</h6>
                                </div>

                                <div className="MyorderDivsec">
                                    {odState === 'first' && orderMasterTemp.map((item) => (
                                        <div className="MyorderCard" key={item}>
                                            <div className="MyorderHead">
                                                <h6>Order ID:&nbsp;{item.OrderDocNo}</h6>
                                                {/*<div className="d-flex">*/}
                                                {/*    <h6 className="me-5">Order Date:&nbsp;{item.OrderDocDate.split('/')[0] + " " + getMonthName(item.OrderDocDate.split('/')[1]) + " " + item.OrderDocDate.split('/')[2]}</h6>*/}
                                                {/*    <h6>EKart Order ID.:&nbsp;{item.KartInvoiceNo}</h6>*/}
                                                {/*</div>*/}
                                                <h6 className="me-5">Order Date:&nbsp;{item.OrderDocDate.split('/')[0] + " " + getMonthName(item.OrderDocDate.split('/')[1]) + " " + item.OrderDocDate.split('/')[2]}</h6>
                                                <h6>EKart Order ID.:&nbsp;{item.KartInvoiceNo}</h6>
                                                <h6>Expected Delivery Date: {item.OrderExpDelDate}</h6>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-md-3 mt-1">
                                                    <p className="paraData">Total No. of Boxes:&nbsp;{item.BoxQty}</p>
                                                    <p className="paraData">Total No. of Items:&nbsp;{item.OrderQty}</p>
                                                    <p className="paraData">Order Value:&nbsp;<span className="RuppeFont">₹</span>{formatIndianNumber(item.KartNetAmount)}</p>
                                                    <p className="paraData">Last Updated On:&nbsp;</p>
                                                </div>
                                                <div className="col-md-1">
                                                    <button className="Orderbutton mb-2" onClick={() => { handleOpenTrack(); getOrderDetails('VIEW_TRACK', item) }}>
                                                        Track Order
                                                    </button>
                                                    <button className="Orderbutton" onClick={() => { handleOpen(); getOrderDetails('VIEW_TRACK', item) }}>
                                                        View Order
                                                    </button>
                                                    <div className="text-center" style={{ width: 110, backgroundColor: 'transparent' }}>
                                                        {item.Delivery.length > 2 ? (
                                                            <>
                                                                {item.Delivery[2].OrderStatus === ('Partially Delivered') || ('Delivered') ? (
                                                                    <Link
                                                                        onClick={() =>{
                                                                             getOrderDetails('VIEW_TRACK', item);
                                                                             setBathcValues(item.BatchList)
                                                                            }}
                                                                        state={item.KartNetAmount + "-" + item.OrderQty + "-" + item.OrderDocDate} to={`/myReturns?${item.OrderDocNo}?${`editReturn`}`}
                                                                        style={{ fontSize: '0.7rem', textAlign: 'center', }}>
                                                                        <button className="bg-white">
                                                                            Return&nbsp;&nbsp;<i className="fa-solid fa-rotate-left"></i>
                                                                        </button>
                                                                    </Link>
                                                                ) : (
                                                                    <Link
                                                                        style={{ fontSize: '0.7rem', textAlign: 'center', }}>
                                                                        <button className="bg-white">
                                                                            Return&nbsp;&nbsp;<i className="fa-solid fa-rotate-left"></i>
                                                                        </button>
                                                                    </Link>
                                                                )
                                                                }
                                                            </>
                                                        ) : (<>
                                                            <div
                                                                style={{ color: '#222222', fontSize: '0.7rem', textAlign: 'center', cursor: 'none' }}>
                                                                <button className="bg-white">
                                                                    Return&nbsp;&nbsp;
                                                                </button>
                                                            </div>
                                                        </>)}
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="MyOrderTimeLine2" style={{ marginLeft: '3rem' }}>
                                                        <div className="line">
                                                            <div className="desc">
                                                                <p>
                                                                    Order Placed
                                                                </p>
                                                                <p>
                                                                    {item.KartDate.split('/')[0] + " " + getMonthName(item.KartDate.split('/')[1]) + " " + item.KartDate.split('/')[2]}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {item.Delivery.length !== 0 ? (
                                                            <>
                                                                {item.Delivery[0].OrderStatus === 'Order Confirmed' ? (
                                                                    <div className="line">
                                                                        <div className="desc">
                                                                            <p>
                                                                                {item.Delivery[0].OrderStatus}
                                                                            </p>
                                                                            <p>
                                                                                {item.Delivery[0].StatusDate.split('/')[0] + " " + getMonthName(item.Delivery[0].StatusDate.split('/')[1]) + " " + item.Delivery[0].StatusDate.split('/')[2]}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="line2">
                                                                        <div className="desc2">
                                                                            <p>
                                                                                {item.Delivery[0].OrderStatus}
                                                                            </p>
                                                                            <p>
                                                                                pending....
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {item.Delivery.length > 1 ? (
                                                                    <div className="line">
                                                                        <div className="desc">
                                                                            <p>
                                                                                {item.Delivery[1].OrderStatus}
                                                                            </p>
                                                                            <p>
                                                                                {item.Delivery[1].StatusDate.split('/')[0] + " " + getMonthName(item.Delivery[1].StatusDate.split('/')[1]) + " " + item.Delivery[1].StatusDate.split('/')[2]}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="line2">
                                                                        <div className="desc2">
                                                                            <p>
                                                                                Partially Dispatched
                                                                            </p>
                                                                            <p>
                                                                                pending....
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {item.Delivery.length > 2 ? (
                                                                    <div className="line" style={{ border: 'none' }}>
                                                                        <div className="desc">
                                                                            <p>
                                                                                {item.Delivery[2].OrderStatus}
                                                                            </p>
                                                                            <p>
                                                                                {item.Delivery[2].StatusDate.split('/')[0] + " " + getMonthName(item.Delivery[2].StatusDate.split('/')[1]) + " " + item.Delivery[2].StatusDate.split('/')[2]}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="line2">
                                                                        <div className="desc">
                                                                            <p>
                                                                                {item.Delivery.length > 1 ? 'Delivered' : 'Partially Delivered'}
                                                                            </p>
                                                                            <p>
                                                                                pending....
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="line2">
                                                                    <div className="desc2">
                                                                        <p>
                                                                            Order Confirmed
                                                                        </p>
                                                                        <p>
                                                                            pending....
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="line2">
                                                                    <div className="desc2">
                                                                        <p>
                                                                            Partially Dispatched
                                                                        </p>
                                                                        <p>
                                                                            pending....
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="line2">
                                                                    <div className="desc">
                                                                        <p>Partially Delivered</p>
                                                                        <p>
                                                                            pending....
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {odState === 'second' && orderPending.map((item) => (
                                        <div className="MyorderCard" key={item}>
                                            <div className="MyorderHead">
                                                <h6>Order ID:&nbsp;{item.OrderDocNo}</h6>
                                                {/*<div className="d-flex">*/}
                                                {/*    <h6 className="me-5">Order Date:&nbsp;{item.OrderDocDate.split('/')[0] + " " + getMonthName(item.OrderDocDate.split('/')[1]) + " " + item.OrderDocDate.split('/')[2]}</h6>*/}
                                                {/*    <h6>EKart Order ID.:&nbsp;{item.KartInvoiceNo}</h6>*/}
                                                {/*</div>*/}
                                                <h6 className="me-5">Order Date:&nbsp;{item.OrderDocDate.split('/')[0] + " " + getMonthName(item.OrderDocDate.split('/')[1]) + " " + item.OrderDocDate.split('/')[2]}</h6>
                                                <h6>EKart Order ID.:&nbsp;{item.KartInvoiceNo}</h6>
                                                <h6>Expected Delivery Date: {item.OrderExpDelDate}</h6>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-md-3 mt-1">
                                                    <p className="paraData">Total No. of Boxes:&nbsp;{item.BoxQty}</p>
                                                    <p className="paraData">Total No. of Items:&nbsp;{item.OrderQty}</p>
                                                    <p className="paraData">Order Value:&nbsp;<span className="RuppeFont">₹</span>{formatIndianNumber(item.KartNetAmount)}</p>
                                                    <p className="paraData">Last Updated On:&nbsp;</p>
                                                </div>
                                                <div className="col-md-1">
                                                    <button className="Orderbutton mb-2" onClick={() => { handleOpenTrack(); getOrderDetails('VIEW_TRACK', item) }}>
                                                        Track Order
                                                    </button>
                                                    <button className="Orderbutton" onClick={() => { handleOpen(); getOrderDetails('VIEW_TRACK', item) }}>
                                                        View Order
                                                    </button>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="MyOrderTimeLine2" style={{ marginLeft: '3rem' }}>
                                                        <div className="line">
                                                            <div className="desc">
                                                                <p>
                                                                    Order Placed
                                                                </p>
                                                                <p>
                                                                    {item.KartDate.split('/')[0] + " " + getMonthName(item.KartDate.split('/')[1]) + " " + item.KartDate.split('/')[2]}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {item.Delivery.length !== 0 ? (
                                                            <>
                                                                {item.Delivery[0].OrderStatus === 'Order Confirmed' ? (
                                                                    <div className="line">
                                                                        <div className="desc" style={{ right: "48%" }}>
                                                                            <p>
                                                                                {item.Delivery[0].OrderStatus}
                                                                            </p>
                                                                            <p>
                                                                                {item.Delivery[0].StatusDate.split('/')[0] + " " + getMonthName(item.Delivery[0].StatusDate.split('/')[1]) + " " + item.Delivery[0].StatusDate.split('/')[2]}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="line2">
                                                                        <div className="desc">
                                                                            <p>
                                                                                {item.Delivery[0].OrderStatus}
                                                                            </p>
                                                                            <p>
                                                                                pending....
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {item.Delivery.length > 1 ? (
                                                                    <div className="line">
                                                                        <div className="desc" style={{ width: "100%", right: "25%" }}>
                                                                            <p>
                                                                                {item.Delivery[1].OrderStatus}
                                                                            </p>
                                                                            <p>
                                                                                {item.Delivery[1].StatusDate.split('/')[0] + " " + getMonthName(item.Delivery[1].StatusDate.split('/')[1]) + " " + item.Delivery[1].StatusDate.split('/')[2]}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="line2">
                                                                            <div className="desc" style={{ width: "100%", right: "25%" }}>
                                                                            <p>
                                                                                Partially Dispatched
                                                                            </p>
                                                                            <p>
                                                                                pending....
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {item.Delivery.length > 2 ? (
                                                                    <div className="line" style={{ border: 'none' }}>
                                                                        <div className="desc" style={{ right: "37%" }}>
                                                                            <p>
                                                                                {item.Delivery[2].OrderStatus}
                                                                            </p>
                                                                            <p>
                                                                                {item.Delivery[2].StatusDate.split('/')[0] + " " + getMonthName(item.Delivery[2].StatusDate.split('/')[1]) + " " + item.Delivery[2].StatusDate.split('/')[2]}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="line2">
                                                                        <div className="desc" style={{ right: "37%" }}>
                                                                            <p>
                                                                                Partially Delivered
                                                                            </p>
                                                                            <p>
                                                                                pending....
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="line2">
                                                                        <div className="desc" style={{ right: "55%" }}>
                                                                        <p>
                                                                            Order Confirmed
                                                                        </p>
                                                                        <p>
                                                                            pending....
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="line2">
                                                                        <div className="desc" style={{ width: "100%", right: "25%" }}>
                                                                        <p>
                                                                            Partially Dispatched
                                                                        </p>
                                                                        <p>
                                                                            pending....
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="line2">
                                                                        <div className="desc" style={{ right: "37%" }}>
                                                                        <p>Partially Delivered</p>
                                                                        <p>
                                                                            pending....
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    ))}
                                    {odState === 'third' && myReturnMaster.map((item) => (
                                        <div className="MyorderCard" key={item}>
                                            <div className="MyorderHead">
                                                <h6>Order ID:&nbsp;{item.OrderDocNo}</h6>
                                                <h6 className="me-5">Order Date:&nbsp;{item.OrderDocDate.split('/')[0] + " " + getMonthName(item.OrderDocDate.split('/')[1]) + " " + item.OrderDocDate.split('/')[2]}</h6>
                                                <h6>EKart Order ID.:&nbsp;{item.KartInvoiceNo}</h6>
                                                {/*<div className="d-flex">*/}
                                                {/*    <h6 className="me-5">Order Date:&nbsp;{item.OrderDocDate.split('/')[0] + " " + getMonthName(item.OrderDocDate.split('/')[1]) + " " + item.OrderDocDate.split('/')[2]}</h6>*/}
                                                {/*    <h6>EKart Order ID.:&nbsp;{item.KartInvoiceNo}</h6>*/}
                                                {/*</div>*/}
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-md-2 mt-3">
                                                    <p className="paraData">Total No. of Boxes:&nbsp;{item.BoxOrderQty}</p>
                                                    <p className="paraData">Total No. of Items:&nbsp;{item.KartDetReturnQty}</p>
                                                    <p className="paraData">Order Value:&nbsp;<span className="RuppeFont">₹</span>{formatIndianNumber(item.OrderDetVal)}</p>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="MyOrderTimeLine3">
                                                        <div className="line">
                                                            <div className="desc">
                                                                <p>
                                                                    {item.ReturnRGAStatus}
                                                                </p>
                                                                <p>
                                                                    {item.ReturnRGADate.split('/')[0] + " " + getMonthName(item.ReturnRGADate.split('/')[1]) + " " + item.ReturnRGADate.split('/')[2]}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {item.ReturnStatus.length !== 0 ? (
                                                            <>
                                                                {item.ReturnStatus[0].ReturnStatus === 'RGA Form Submission' ? (
                                                                    <div className="line">
                                                                        <div className="desc">
                                                                            <p>
                                                                                RGA Form <br /> Submission
                                                                            </p>
                                                                            <p>
                                                                                {item.ReturnStatus[0].ReturnStatusDate.split('/')[0] + " " + getMonthName(item.ReturnStatus[0].ReturnStatusDate.split('/')[1]) + " " + item.ReturnStatus[0].ReturnStatusDate.split('/')[2]}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="line3">
                                                                        <div className="desc3">
                                                                            <p>
                                                                                RGA Form <br /> Submission
                                                                            </p>
                                                                            <p>
                                                                                pending....
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {item.ReturnStatus[1] && item.ReturnStatus[1].ReturnStatus === 'RGA Approvals' ? (
                                                                    <div className="line">
                                                                        <div className="desc">
                                                                            <p>
                                                                                RGA <br /> Approvals
                                                                            </p>
                                                                            <p>
                                                                                {item.ReturnStatus[1].ReturnStatusDate.split('/')[0] + " " + getMonthName(item.ReturnStatus[1].ReturnStatusDate.split('/')[1]) + " " + item.ReturnStatus[1].ReturnStatusDate.split('/')[2]}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="line3">
                                                                        <div className="desc3">
                                                                            <p>
                                                                                RGA <br /> Approvals
                                                                            </p>
                                                                            <p>
                                                                                pending....
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {item.ReturnStatus[2] && item.ReturnStatus[2].ReturnStatus === 'Item Recieved' ? (
                                                                    <div className="line">
                                                                        <div className="desc">
                                                                            <p>
                                                                                Item <br />Recieved
                                                                            </p>
                                                                            <p>
                                                                                {item.ReturnStatus[2].ReturnStatusDate.split('/')[0] + " " + getMonthName(item.ReturnStatus[2].ReturnStatusDate.split('/')[1]) + " " + item.ReturnStatus[2].ReturnStatusDate.split('/')[2]}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="line3">
                                                                        <div className="desc3">
                                                                            <p>
                                                                                Item <br />Recieved
                                                                            </p>
                                                                            <p>
                                                                                pending....
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {item.ReturnStatus[3] && item.ReturnStatus[3].ReturnStatus === 'Physical Verificatio' ? (
                                                                    <div className="line">
                                                                        <div className="desc">
                                                                            <p>
                                                                                Physical <br />Verification
                                                                            </p>
                                                                            <p>
                                                                                {item.ReturnStatus[3].ReturnStatusDate.split('/')[0] + " " + getMonthName(item.ReturnStatus[3].ReturnStatusDate.split('/')[1]) + " " + item.ReturnStatus[3].ReturnStatusDate.split('/')[2]}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="line3">
                                                                        <div className="desc3">
                                                                            <p>
                                                                                Physical <br />Verification
                                                                            </p>
                                                                            <p>
                                                                                pending....
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {item.ReturnStatus[4] && item.ReturnStatus[4].ReturnStatus === 'Rectification Proces' ? (
                                                                    <div className="line">
                                                                        <div className="desc">
                                                                            <p>
                                                                                Rectification <br />Process
                                                                            </p>
                                                                            <p>
                                                                                {item.ReturnStatus[4].ReturnStatusDate.split('/')[0] + " " + getMonthName(item.ReturnStatus[4].ReturnStatusDate.split('/')[1]) + " " + item.ReturnStatus[4].ReturnStatusDate.split('/')[2]}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="line3">
                                                                        <div className="desc3">
                                                                            <p>
                                                                                Rectification <br />Process
                                                                            </p>
                                                                            <p>
                                                                                pending....
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {item.ReturnStatus[5] && item.ReturnStatus[5].ReturnStatus === 'Return Credit/Return Rectified' ? (
                                                                    <div className="line">
                                                                        <div className="desc">
                                                                            <p>
                                                                                Return Credit/Return <br />Rectified
                                                                            </p>
                                                                            <p>
                                                                                {item.ReturnStatus[5].ReturnStatusDate.split('/')[0] + " " + getMonthName(item.ReturnStatus[5].ReturnStatusDate.split('/')[1]) + " " + item.ReturnStatus[5].ReturnStatusDate.split('/')[2]}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="line3">
                                                                        <div className="desc" style={{marginTop:'-0.8rem'}}>   
                                                                            <p>
                                                                                Return Credit/Return <br />Rectified
                                                                            </p>
                                                                            <p>
                                                                                pending....
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </>
                                                        ) : (
                                                        <>
                                                            <div className="line3">
                                                                <div className="desc3">
                                                                    <p>RGA Form <br /> Submission</p>
                                                                    <p>pending...</p>
                                                                </div>
                                                            </div>
                                                            <div className="line3">
                                                                <div className="desc3">
                                                                    <p>RGA <br /> Approvals</p>
                                                                    <p>pending...</p>
                                                                </div>
                                                            </div>
                                                            <div className="line3">
                                                                <div className="desc3">
                                                                    <p>Item <br /> Recieved</p>
                                                                    <p>pending...</p>
                                                                </div>
                                                            </div>
                                                            <div className="line3">
                                                                <div className="desc3">
                                                                    <p>Physical <br /> Verification</p>
                                                                    <p>pending...</p>
                                                                </div>
                                                            </div>
                                                            <div className="line3">
                                                                <div className="desc3">
                                                                    <p>Rectification <br /> Process</p>
                                                                    <p>pending...</p>
                                                                </div>
                                                            </div>
                                                            <div className="line3">
                                                                <div className="desc3">
                                                                    <p>Return Credit/ <br />Return Rectified</p>
                                                                    <p>pending...</p>
                                                                </div>
                                                            </div>
                                                        </>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-1 pb-4">
                                                    <div className="MyretuHJdh">
                                                        <div>
                                                            <Link
                                                                onClick={() => getOrderDetails('VIEW_TRACK', item)}
                                                                state={item.KartNetAmount + "-" + item.KartDetReturnQty + "-" + item.OrderDocDate} to={`/myReturns?${item.OrderDocNo}?${`viewReturn`}`}
                                                                style={{ color: '#222222', fontSize: '0.7rem', textAlign: 'center' }}>
                                                                <button className="Orderbutton">
                                                                    View Returns
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    ))}
                                    {(odState === 'third' && myReturnMaster.length === 0) &&
                                        <div className="text-center p-4">No order returns found</div>}
                                </div>
                            </div>
                            <div className="col-lg-2" style={{ marginLeft: '-1.2%'}}>
                                <button className="QuickFeaBtn2 mt-2 mb-3">Featured Products</button>
                                <div className="QuickRo2">
                                    {feaData.map((item, index) => (
                                        <div className='ProductCardOne' style={{height:'250px',marginBottom:'1.3rem'}}>
                                        {item.ItemDp ? (
                                            <Link to={`/productDetails/${item.CustomerLinkItemCode}`} style={{ cursor: 'pointer', color: '#222222' }}>
                                                <img src={item.CustomerLinkItemImageData != "" ? `data:image/png;base64,${item.CustomerLinkItemImageData}` : notfound} 
                                                className={item.CustomerLinkItemImageData===""?'QuickNotFimg2':'img-fluid-pr'} style={{height:'100px'}} alt="" />
                                            </Link>
                                        ) : (
                                            <span style={{ cursor: 'not-allowed', color: 'gray' }}>
                                                <img src={item.CustomerLinkItemImageData != "" ? `data:image/png;base64,${item.CustomerLinkItemImageData}` : notfound}
                                                 className={item.CustomerLinkItemImageData===""?'QuickNotFimg2':'img-fluid-pr'} alt="" />
                                            </span>
                                        )}
                                        <div className='ProductContent'>
                                            <div className='ProductTitle'>
                                                <div style={{fontSize:'0.6rem'}}>{item.CustomerLinkItemName}</div>
                                                <div>Product&nbsp;Code:&nbsp;{item.CustomerLinkItemCode}</div>
                                                {item.ItemDp !== 0 && <div className="pb-2" style={{fontSize:'0.6rem'}}>MRP:&nbsp;<strike><span className="RuppeFont">₹</span>{formatIndianNumber(item.CustomerLinkItemMRP)}</strike>&nbsp;DP:&nbsp;<span className="RuppeFont">₹</span>&nbsp;{formatIndianNumber(item.CustomerLinkItemDP)}</div>}
                                            </div>
                                            <div className='ProductBtn'>
                                                {item.ItemDp > 0 ? (
                                                    <Link to={`/productDetails/${item.CustomerLinkItemCode}`} style={{ cursor: 'pointer', color: '#222222' }}>
                                                        <button type='button'>Add&nbsp;
                                                            <svg width="16" height="16" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7.29102 9.91663H27.4014C27.8089 9.91664 28.2119 9.9996 28.5843 10.1602C28.9568 10.3207 29.2905 10.5553 29.5639 10.8488C29.8373 11.1423 30.0444 11.4882 30.1718 11.8642C30.2992 12.2402 30.3441 12.638 30.3035 13.0319L29.4285 21.5319C29.3565 22.231 29.0197 22.8792 28.4835 23.3507C27.9472 23.8222 27.2497 24.0833 26.5264 24.0833H12.5993C11.9248 24.0836 11.2711 23.8567 10.7494 23.4413C10.2277 23.026 9.8703 22.4478 9.7381 21.8053L7.29102 9.91663Z" stroke="white" strokeWidth="1.5" strokeLinejoin="round" />
                                                                <path d="M7.29102 9.91667L6.10977 5.32242C6.03077 5.01607 5.84865 4.74415 5.59236 4.54985C5.33607 4.35554 5.02029 4.25001 4.69518 4.25H2.91602M11.666 29.75H14.5827M23.3327 29.75H26.2493" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </button>
                                                    </Link>
                                                ) :
                                                    (<div>
                                                        <button onClick={() => handleQuoteClick(item.CustomerLinkItemCode,item.CustomerLinkItemName)}>Get Quote</button>
                                                    </div>)}
                                            </div>
                                        </div>
                                    </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />

                    <Modal size={'lg'} style={{ marginTop: 40 }} open={open} onClose={handleClose}>
                        <Modal.Body>
                            <div className="MyorderModal fixed-top">
                                {details.length !== 0 &&
                                    <>
                                        <h6>Order ID: {details[0].OrderDocNo} | {details[0].OrderDocDate.replaceAll("/", "-")}</h6>
                                        <h6>Expected Delivery Date: {details[0].OrderDetDelDate}</h6>
                                        <h6>Order Value: <span className="RuppeFont">₹</span> {formatIndianNumber(details[0].KartNetAmount)}</h6></>}
                                <div style={{ cursor: 'pointer' }}><i className="fa-solid fa-xmark" onClick={handleClose}></i></div>
                            </div>
                            <div className="row MyOrderModalSearch">
                                <div className="col-md-4">
                                    <p>Total No. of Items:&nbsp;
                                        {(() => {
                                            const distinctItemCodes = new Set();

                                            details.forEach(item => {
                                                const itemsWithSameCode = details.filter(i => i.OrderDetItemCode === item.OrderDetItemCode);

                                                // Check if "Order Placed" is the only status for this OrderDetItemCode
                                                const hasOnlyOrderPlaced = itemsWithSameCode.every(i => i.ItemStatus === "Order Placed");

                                                // Retain unique OrderDetItemCode based on your condition
                                                if (hasOnlyOrderPlaced || item.ItemStatus !== "Order Placed") {
                                                    distinctItemCodes.add(item.OrderDetItemCode);
                                                }
                                            });

                                            return distinctItemCodes.size; // Return the count of distinct OrderDetItemCodes
                                        })()}</p>
                                </div>
                                <div className="col-md-8">
                                    <input type="text" value={myOdSearch} onChange={handleMyorderSearch} className="form-control" placeholder="search..." />
                                </div>
                            </div>
                            <div className="MyModalContSec">
                                {details.filter(item => {
                                    const itemsWithSameCode = details.filter(i => i.OrderDetItemCode === item.OrderDetItemCode);
                                    const hasOnlyOrderPlaced = itemsWithSameCode.every(i => i.ItemStatus === "Order Placed");
                                    if (hasOnlyOrderPlaced) {
                                        return true;
                                    } else {
                                        return item.ItemStatus === "Order Confirmed";
                                    }
                                }).map((item, index) => (
                                    <>
                                        <div className="MyModalContent">
                                            <div style={{ width: '2rem' }}>
                                                <p className="ModalIndexPar">{index + 1}</p>
                                            </div>
                                            <div className="modalParaOne3" style={{ width: '20rem', textAlign: 'left' }}>
                                                <p>{item.ItemName}</p>
                                                <p>Product Code&nbsp;:&nbsp;{item.OrderDetItemCode}</p>
                                                <div>{item.ItemBoxQuantity} pc/Box | Unit Price: <span className="RuppeFont">₹</span><strike>{formatIndianNumber(item.ItemUnitPrice)}</strike> &nbsp;<span className="RuppeFont">₹</span>{item.ItemDP}</div>
                                            </div>
                                            <div className="modalParaOne" style={{ width: '20rem', textAlign: 'left' }}>
                                                <p>Order Qty: {(item.OrderDetQty / item.ItemBoxQuantity)} Boxes / {item.OrderDetQty} Pcs.</p>
                                                {/*<p>Remarks: {item.OrderDetLrNo} / {item.OrderDetLrDate} / {item.OrderDetTransportation}</p>*/}
                                                <p>Remarks: {item.OrderRemarks}</p>
                                            </div>
                                            <div className="modalParaOne2">
                                                <p>Amount: <span className="RuppeFont">₹</span> {formatIndianNumber(item.KartDetOrderVal.toFixed(2))}</p>
                                                <p>Tax Amount: <span className="RuppeFont">₹</span> {formatIndianNumber(item.KartDetTaxAmount.toFixed(2))}</p>
                                                <div>Net Amount: <span className="RuppeFont">₹</span> {formatIndianNumber(item.KartDetTotalAmount.toFixed(2))}</div>
                                            </div>
                                        </div>
                                        <div className="MyModalLine"></div></>
                                ))}
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal size={'lg'} style={{ marginTop: 40 }} open={openTrack} onClose={handleCloseTrack}>
                        <Modal.Body>
                            <div className="MyorderModal fixed-top">
                                {details.length !== 0 &&
                                    <>
                                        <h6>Order ID:&nbsp;{details[0].OrderDocNo} | {details[0].OrderDocDate.replaceAll("/", "-")}</h6>
                                        <h6>Expected Delivery Date:&nbsp;{details[0].OrderDetDelDate}</h6>
                                        <h6>Order Value:&nbsp;<span className="RuppeFont">₹</span> {formatIndianNumber(details[0].KartNetAmount)}</h6>
                                        <h6>
                                            Total No. of Items:&nbsp;
                                            {(() => {
                                                const distinctItemCodes = new Set();

                                                details.forEach(item => {
                                                    const itemsWithSameCode = details.filter(i => i.OrderDetItemCode === item.OrderDetItemCode);

                                                    // Check if "Order Placed" is the only status for this OrderDetItemCode
                                                    const hasOnlyOrderPlaced = itemsWithSameCode.every(i => i.ItemStatus === "Order Placed");

                                                    // Retain unique OrderDetItemCode based on your condition
                                                    if (hasOnlyOrderPlaced || item.ItemStatus !== "Order Placed") {
                                                        distinctItemCodes.add(item.OrderDetItemCode);
                                                    }
                                                });

                                                return distinctItemCodes.size; // Return the count of distinct OrderDetItemCodes
                                            })()}
                                        </h6>

                                        </>}
                                <div style={{ cursor: 'pointer' }}><i className="fa-solid fa-xmark" onClick={handleCloseTrack}></i></div>
                            </div>
                            <div className="MyOrderModalSearch pb-5">
                                <div className="col-md-9">
                                    {details.map((item, index) => (
                                        index === 0 &&
                                        <div className="MyOrderTimeLine2" key={item}>
                                            <div className="line">
                                                <div className="desc">
                                                    <p>
                                                        Order Placed
                                                    </p>
                                                    {orderSt ? (
                                                        <p>
                                                            {orderSt.KartDate.split('/')[0] + " " + getMonthName(orderSt.KartDate.split('/')[1]) + " " + orderSt.KartDate.split('/')[2]}
                                                        </p>
                                                    ) : (<p></p>)}
                                                </div>
                                            </div>
                                            {item.Delivery.length !== 0 ? (
                                                <>
                                                    {item.Delivery[0].OrderStatus === 'Order Confirmed' ? (
                                                        <div className="line">
                                                            <div className="desc" style={{ right: "50%" }}>
                                                                <p>
                                                                    {item.Delivery[0].OrderStatus}
                                                                </p>
                                                                <p>
                                                                    {item.Delivery[0].StatusDate.split('/')[0] + " " + getMonthName(item.Delivery[0].StatusDate.split('/')[1]) + " " + item.Delivery[0].StatusDate.split('/')[2]}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="line2">
                                                            <div className="desc" style={{ right: "57%" }}>
                                                                <p>
                                                                    {item.Delivery[0].OrderStatus}
                                                                </p>
                                                                <p>
                                                                    pending....
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {item.Delivery.length > 1 ? (
                                                        <div className="line">
                                                            <div className="desc">
                                                                <p>
                                                                    {item.Delivery[1].OrderStatus}
                                                                </p>
                                                                <p>
                                                                    {item.Delivery[1].StatusDate.split('/')[0] + " " + getMonthName(item.Delivery[1].StatusDate.split('/')[1]) + " " + item.Delivery[1].StatusDate.split('/')[2]}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="line2">
                                                            <div className="desc" style={{ width: "100%", right: "25%" }}>
                                                                <p>
                                                                    Partially Dispatched
                                                                </p>
                                                                <p>
                                                                    pending....
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {item.Delivery.length > 2 ? (
                                                        <div className="line" style={{ border: 'none' }}>
                                                            <div className="desc">
                                                                <p>
                                                                    {item.Delivery[2].OrderStatus}
                                                                </p>
                                                                <p>
                                                                    {item.Delivery[2].StatusDate.split('/')[0] + " " + getMonthName(item.Delivery[2].StatusDate.split('/')[1]) + " " + item.Delivery[2].StatusDate.split('/')[2]}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="line2">
                                                            <div className="desc" style={{ width: "100%", right: "25%" }}>
                                                                <p>
                                                                    {item.Delivery.length > 1 ? 'Delivered' : 'Partially Delivered'}
                                                                </p>
                                                                <p>
                                                                    pending....
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <div className="line2">
                                                        <div className="desc" style={{ right: "57%" }}>
                                                            <p>
                                                                Order Confirmed
                                                            </p>
                                                            <p>
                                                                pending....
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="line2">
                                                        <div className="desc" style={{ width: "100%", right: "25%" }}>
                                                            <p>
                                                                Partially Dispatched
                                                            </p>
                                                            <p>
                                                                pending....
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="line2">
                                                        <div className="desc" style={{ right: "37%" }}>
                                                            <p>Partially Delivered</p>
                                                            <p>
                                                                pending....
                                                            </p>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div className="col-md-3 d-flex flex-column justify-content-between align-items-end">
                                    <div>
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                        <input type="text" value={myOdSearch} onChange={handleMyorderSearch} className="form-control" placeholder="search..." />
                                    </div>
                                </div>
                            </div>
                            <div className="IndexTableCls">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S.No.</th>
                                            <th>Item</th>
                                            <th>Order Qty.</th>
                                            <th>Delivery Qty.</th>
                                            <th>Pending Qty.</th>
                                            <th>Invoice No./Date</th>
                                            <th>Transport Details</th>
                                            <th>Status</th>
                                            <th>Status Date</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {Array.from(
                                            details.reduce((map, item) => {
                                                // Update the map to store the last occurrence of each OrderDetItemCode
                                                map.set(item.OrderDetItemCode, item);
                                                return map;
                                            }, new Map()).values() // Extract the last rows from the map
                                        ).map((item, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <p className="ModalIndexPar">{index + 1}</p>
                                                </td>
                                                <td>{item.ItemName}</td>
                                                <td>{item.OrderDetQty}</td>
                                                <td>{item.OrderDispatchedQty}</td>
                                                <td>{item.OrderDetOpenQty}</td>
                                                <td>
                                                    {item.OrderDetStatusInvoiceNo ? (
                                                        <>
                                                            <a
                                                                href="#"
                                                                className="invoice-link"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    downloadInvoice(item.OrderDetStatusInvoiceNo);
                                                                }}
                                                            >
                                                                {item.OrderDetStatusInvoiceNo}
                                                            </a>
                                                            {" / "}
                                                            {item.OrderDetStatusInvoiceDate}
                                                        </>
                                                    ) : (
                                                        item.OrderDetStatusInvoiceDate
                                                    )}
                                                </td>
                                                <td>{item.OrderDetLrNo} / {item.OrderDetLrDate} / {item.OrderDetTransportation}</td>
                                                <td>{item.ItemStatus}</td>
                                                <td>{item.StatusDate || "N/A"}</td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal size={'sm'} backdrop={'static'} style={{ marginTop: '3rem' }} open={openQuote} onClose={handleCloseQuote}>
                        <Modal.Header>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="container-fluid">
                                <h5>Quotation Request</h5>
                                <div className='row mt-3'>
                                    <div className='col-md-12 mb-3'>
                                        <input
                                            value={usName}
                                            onInput={() => setError(false)}
                                            style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                            onChange={(event) => setUsName(event.target.value)}
                                            type="text" placeholder="Enter Name" className='form-control' />
                                    </div>
                                    <div className='col-md-12 mb-3'>
                                        <input
                                            type="text"
                                            onInput={() => setError(false)}
                                            style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                            value={usPhone}
                                            onChange={(event) => setUsPhone(event.target.value)}
                                            placeholder="Enter Phone" className='form-control' />
                                    </div>
                                    <div className='col-md-12 mb-3'>
                                        <input
                                            type="text"
                                            onInput={() => setError(false)}
                                            style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                            value={usEmail}
                                            onChange={(event) => setUsEmail(event.target.value)}
                                            placeholder="Enter Email"
                                            className='form-control' />
                                    </div>
                                    <div className='col-md-12 mb-3'>
                                        <textarea
                                            type="number"
                                            onInput={() => setError(false)}
                                            style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                            value={usRemark}
                                            onChange={(event) => setUsRemark(event.target.value)}
                                            placeholder="Enter Remarks"
                                            className='form-control' ></textarea>
                                    </div>
                                    <div className='col-md-12 text-center'>
                                        <AmsButton
                                            onClick={handleGetQuote}
                                            marginBottom={20}
                                            icon={''}
                                            textcolor={'#fff'}
                                            buttonwid={'75%'}
                                            color={'#3D97EA'}
                                            label={'Submit'} />
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            )}
        </div>
    )
}