import axios from "axios"
import { ApiEndPoints } from "../constants/ApiEndpoints"
import { ApiUrl } from "../constants/Server"

export default {
        getAuthorized: async function(payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.AUTH_API,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error login",error)
        }
    },
    getLogin: async function(payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.LOGIN_API,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error login",error)
            throw error
        }
    },

    sendOtpToMail: async function(payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.SEND_OTP_API,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
            throw error
        }
    },

    getForgotPassword: async function(payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.FORGOT_PASSWORD,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
            throw error
        }
    },
    getCustomerDetails: async function(payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.GET_CUSTOMER_DETAILS,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log(error);
            throw error
        }
    },
    insertQuoteDetails: async function(payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.INSERT_QUOTE_DETAILS,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log(error);
            throw error
        }
    },
    getContactEmail: async function(payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.GET_CONTACT_US_EMAIL,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log(error);
            throw error
        }
    }
}

