import { useEffect, useState } from 'react'
import './Banner.css'
import { useRecoilValue } from 'recoil'
import { BannerValAtom } from '../../rec-state/atoms'
import  { useBannerService } from '../../services/StateService'
import { GetSessions } from '../../services/SessionService'

export const Banner = ({text})=>{
    const defaultText ="15+ Medical specialties catered to" + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 " + "300+ Active channel partners globally" + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 " + "14+ Years of experience" + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 " + "50K+ Reaching healthcare professionals daily"
    const bannerText = useRecoilValue(BannerValAtom)
    const [amuser,setAMUser] = useState(JSON.parse(GetSessions('AM_USER_RE')))
    const { getBannerValue } = useBannerService()
    useEffect(() => {
        getBannerValue({custcode: amuser.CustomerCode})
    }, [getBannerValue]);

    return(
        <div className="Banner">
            <marquee scrollamount='5'>
                {text!==""?(
                   <div>{bannerText}</div>
                ):(
                    <div>{defaultText}</div>
                )}
            </marquee>
        </div>
    )
}