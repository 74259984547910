import axios from "axios"
import { ApiEndPoints } from "../constants/ApiEndpoints"
import { ApiUrl } from "../constants/Server"

export default {
    getPaymentDetails: async function (payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.GET_AGING_DETAILS,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
            throw error
        }
    },
    getPendingCollections: async function (payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.GET_COLLECTION_DETAILS,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
            throw error
        }
    },
    insertPaymentDetails: async function (payload) {
        try {
            const response = await axios({
                url: ApiUrl.SERVER_URL + ApiEndPoints.INSERT_PAYMENT_DETAILS,
                method: 'post',
                data: payload
            })
            return response.data
        } catch (error) {
            console.log("error ", error)
            throw error
        }
    },
}