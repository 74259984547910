import erro404 from "../../assets/error404.png"
import { LandingNav } from "../../components/LandingNav/LandingNav"
import './Notfound.css'
export const NotfoundPage = () =>{
    return(
        <div>
            <LandingNav/>
            <div className="container-fluid admNotFound">
            <div className="row">
                <div className="col-md-6">
                    <img src={erro404} className="img-fluid" alt="" />
                </div>
                <div className="col-md-6 m-auto">
                <h4 className="display-6">
                        404 page not found!
                    </h4>
                    <p>Page is not working, due connectivity</p>
                </div>
            </div>
        </div>
        </div>
    )
}