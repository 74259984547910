import { useLocation } from "react-router-dom";
import { Banner } from "../../components/Banner/Banner";
import { Footer } from "../../components/Footer/Footer";
import { UserHeader } from "../../components/UserHeader/UserHeader";
import { BreadCmp } from "../../core/BreadCrump/BreadCmp";
import './Dashboard.css';
import { AMBarChart } from "../../core/BarChart/BarChart";
import HomeService from "../../services/HomeService";
import { useState, useEffect } from "react";
import DashboardService from "../../services/DashboardService";
import { GetSessions } from "../../services/SessionService";
import { AMPiechart } from "../../core/PieChart/PieChart";
import formatIndianNumber from "../../services/FormatNumber";

export const Dashboard = () => {
    const [SlotOne, setSlotOne] = useState([])
    const [SlotTwo, setSlotTwo] = useState([])
    const [SlotThree, setSlotThree] = useState([])
    const [SlotFour, setSlotFour] = useState([{ name: '', data: [] }])
    const [dataOne, setData] = useState()
    const [poSeries, setPoSeries] = useState([])
    const [poLabels, setPoLabels] = useState([])
    const [pySeries, setPySeries] = useState([])
    const [pyLabels, setPyLabels] = useState([])
    const [chartValue, setChartValue] = useState("1")
    const handleChartChng = (event) => {
    setChartValue(event.target.value)
    getProductPurchase(event.target.value)
    }
    const [totalPur, setTotalPur] = useState([])
    const [amuser, setAMUser] = useState(JSON.parse(GetSessions('AM_USER_RE')))
    const [bgColor, setBgColor] = useState([])
    const [pendPo, setPendPo] = useState("")
    const [payment, setPayment] = useState("")
    const [totalPval, setTotalPVal] = useState("")

    useEffect(() => {
    getProductPurchase()
    getTargetOne()
    getTotalPurchaseTwo()
    getPendingPO()
    getPaymentOut()
    getPurchaseHistory()
  }, []);

    //useEffect(() => {
    //    console.log(pySeries)
    //}, []);
    const options = {
    series: [{
      data: dataOne
    }],
    options: {
      chart: {
        toolbar: {
          show: false
        },
        type: 'bar',
        events: {
          click: function (chart, w, e) {

          }
        },
      },
      colors: ['#3D97EA', '#71BBFF', '#A9D6FF', '#CBE6FF'],
      plotOptions: {
        bar: {
          columnWidth: '70%',
          distributed: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: true,
        horizontalAlign: 'center',
      },
      xaxis: {
        categories: [
          ['', 'Cur. Qtr. Target'],
          ['', 'Cur. Qtr. Achieved'],
          ['', 'YTD. Target'],
          ['', 'YTD. Achieved']
        ],
        labels: {
          style: {
            colors: ['#000000', '#000000', '#000000', '#000000'],
            fontSize: '8px'
          },
          show: false
        }
      },
      yaxis: {
        labels: {
          formatter: (val) => {
            return (val / 100000).toFixed(2) + 'L'
          }
        }
      }
    },
  }

    const options2 = {
    series: [{
      data: SlotOne
    }],
    options: {
      chart: {
        toolbar: {
          show: false
        },
        type: 'bar',
        events: {
          click: function (chart, w, e) {

          }
        },
      },
      colors: ['#3D97EA', '#71BBFF', '#A9D6FF', '#CBE6FF'],
      plotOptions: {
        bar: {
          columnWidth: '30%',
          distributed: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false,
        horizontalAlign: 'right'
      },
      xaxis: {
        categories: SlotTwo,
        labels: {
          style: {
            fontSize: '10px'
          },
          show: true
        }
      },
      yaxis: {
        labels: {
          formatter: (val) => {
            return (val / 100000).toFixed(2) + 'L'
          },
        }
      }
    },
  }

    const options3 = {
    series: SlotFour,
    options: {
      chart: {
        toolbar: {
          show: false
        },
        type: 'bar',
      },
      colors: ['#3D97EA', '#71BBFF', '#A9D6FF', '#CBE6FF'],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        itemMargin: {
          vertical: 8
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: SlotThree,
        labels: {
          show: true,
          rotate: -45,
          trim: true,
          formatter: function (value) {
            const stringValue = typeof value === 'string' ? value : String(value);
            return stringValue.split(" ")[0] + "...";
          },
          style: {
            colors: [],
            fontSize: '10px',
            fontWeight: 400,
            cssClass: 'graphClass'
          },
        }
      },
      yaxis: {
        labels: {
          formatter: (val) => {
            return (chartValue === "1" ? (val / 100000).toFixed(2) + 'L' : val.toFixed(2)) 
          },
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return (chartValue === "1" ? (val / 100000).toFixed(2) + 'L' : val.toFixed(2))
          }
        },
        x: {
          formatter: function (val) {
            return val
          }
        }
      }
    },
  }

    const options4 = {
    series: [{
      data: totalPur
    }],
    options: {
      chart: {
        toolbar: {
          show: false
        },
        type: 'bar',
        events: {
          click: function (chart, w, e) {

          }
        },
      },
      colors: bgColor,
      plotOptions: {
        bar: {
          columnWidth: '70%',
          distributed: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: true,
        horizontalAlign: 'center',
      },
      xaxis: {
        categories: [
          ['', 'Current Month'],
          ['', 'Last Month'],
          ['', 'Last Qtr Avg.'],
          ['', 'YTD Avg.']
        ],
        labels: {
          style: {
            colors: ['#000000', '#000000', '#000000', '#000000'],
            fontSize: '8px'
          },
          show: false
        }
      },
      yaxis: {
        labels: {
          formatter: (val) => {
            return (val / 100000).toFixed(2) + 'L'
          }
        }
      }
    },
  }

    const getTargetOne = async () => {
    let payload = {
      customercode: amuser.CustomerCode
    } 
    let data = await HomeService.getAnalysisData(payload)
    setData(data.Data[0][0].data.split(",").map((item) => { return Number(item) }))
  }

    const getTotalPurchaseTwoOld = async () => {
        let payload = {
          customercode: amuser.CustomerCode
        }
        let data = await DashboardService.getTotalPurchaseChart(payload)
            let result = new Set(data.Data[0].map(item => item.data.length))
        if(result===1){
          setTotalPVal(parseInt(data.Data[0].map(item => item.Total).join("")))
          setBgColor(data.Data[0].map(item => item.backgroundColor))
          setTotalPur(data.Data[0].map(item => Number(item.data)))
        }else{
          setTotalPur([])
        }
    }

    const getTotalPurchaseTwo = async () => {
        let payload = {
            customercode: amuser.CustomerCode
        };
        try {
            let data = await DashboardService.getTotalPurchaseChart(payload);
            const chartData = data.Data[0]; // Array of chart objects
            if (!chartData || chartData.length === 0) {
                // If no data exists, reset states
                setTotalPur([]);
                setTotalPVal(0);
                setBgColor([]);
                return;
            }
            // Check if all 'data' values can be converted to valid numbers
            const isValid = chartData.every((item) => !isNaN(Number(item.data)));

            if (isValid) {
                // Set TotalPVal as the sum of the 'Total' values
                const totalPVal = chartData.reduce((sum, item) => sum + Number(item.Total), 0);
                setTotalPVal(totalPVal);

                // Extract background colors
                const backgroundColors = chartData.map((item) => item.backgroundColor);
                setBgColor(backgroundColors);

                // Convert 'data' values to numbers
                const totalPurValues = chartData.map((item) => Number(item.data));
                setTotalPur(totalPurValues);
            } else {
                // Reset states if data is invalid
                setTotalPur([]);
                setTotalPVal(0);
                setBgColor([]);
            }
        } catch (error) {
            console.error("Error fetching total purchase chart:", error);
            setTotalPur([]);
            setTotalPVal(0);
            setBgColor([]);
        }
    };

    const getPendingPO = async () => {
    let payload = {
      customercode: amuser.CustomerCode
    }
    let data = await DashboardService.getPendingPoChart(payload)
    if(data.Data[0][0].TotalOrderVal!==null){
        //setPoSeries(data.Data[0][0].TotalOrderVal.split(',').map(item => Number(item)))
        const totalPO = data.Data[0][0].TotalOrderVal;
        setPoSeries(totalPO.split(',').map(item => parseFloat((Number(item.trim()) / 100000).toFixed(2))));
        setPayment(data.Data[0][0].TotalOrderVal.split(',').map(item => Number(item)).reduce((sum, item) => { return sum + item }, 0));
        setPoLabels(data.Data[0][0].DelayCategory.split(','));
    }else{
        setPoSeries([])
    }
  }

    const getPaymentOut = async () => {
    let payload = {
      customercode: amuser.CustomerCode
    }
    let data = await DashboardService.getPedningPaymentChart(payload)
    if(data.Data[0][0].TotalPendingPayment!==null){
        setPyLabels(data.Data[0][0].DelayCategory.split(','))
        const totalPendingPayment = data.Data[0][0].TotalPendingPayment;
        setPySeries(totalPendingPayment.split(',').map(item => parseFloat((Number(item.trim()) / 100000).toFixed(2))))
        setPendPo(data.Data[0][0].TotalPendingPayment.split(',').map(item => Number(item)).reduce((sum, item) => { return sum + item }, 0))  
    }else{
        setPySeries([])
    }
  }

    const getPurchaseHistory = async () => {
        let payload = {
          customercode: amuser.CustomerCode
        }
        let data = await DashboardService.getPurchaseChart(payload)
        let proMap = new Set(data.Data[0].map(item => item.ChartData.length))
        if(proMap.size!==1){
          setSlotOne([])
          setSlotTwo([])
        }else{
          setSlotOne(data.Data[0][0].ChartData.split(",").map((item) => { return Number(item) }))
          setSlotTwo(data.Data[0][0].ChartLabel.split(",").map((item) => [item]))
        }
    }

    const getProductPurchase = async (value) => {
        let payload = {
          customercode: amuser.CustomerCode,
          charttype: value === undefined ? chartValue : value}
        let data = await DashboardService.getProdcutPurchaseChart(payload)
        let proMap = new Set(data.Data[0].map(item => item.data.split(",").length));
        if(proMap.size!==1){
          setSlotFour([])
          setSlotThree([])
        }else{
          if (data != undefined) {
            const processedRes = data.Data[0].map(item => ({ data: item.data.split(",").map(val => +val), name: item.label }));
            setSlotFour(processedRes)
            const prodProcee = data.Data[0].map(item => item.names)[0].split(",")
            setSlotThree(prodProcee)
          }
        }
    }

    const { pathname } = useLocation();

    return (
        <div>
      <Banner
        text={""}
      />
      <UserHeader />
      <BreadCmp link={pathname} />
      <div className="container-fluid mt-2 mb-4 fade-image-className">
        <h3 className="mb-2">Dashboard</h3>
        <div className="row">
          <div className="col-md-3">
            <div className="DashChartCont">
              <h6 className="DashChartTitle">Target-Achievement Analysis</h6>
              <div className=" ps-1 pe-1" style={{ marginTop: '1.9rem' }}>
                <AMBarChart options={options}></AMBarChart>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="DashChartCont">
              <h6 className="DashChartTitle">Total Purchase</h6>
              <div className="ps-2 pe-2">
                {totalPur.length!==0? 
                                    (<h5 className="text-center" style={{ marginTop: '0.4rem' }}>Total Rs. {formatIndianNumber((totalPval / 100000).toFixed(2) + 'L')}</h5>):(
                  <h5 className="text-center" style={{ marginTop:'6.3rem'}}></h5>
                )}
                 {((totalPur.length) === 0)?(
                  <div className="DasPosCLasSec mt-2">
                    <p>No data available</p>
                  </div>
                ):(
                  <AMBarChart options={options4}></AMBarChart>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="DashChartCont">
              <h6 className="DashChartTitle">Pending PO</h6>
                {poSeries.length !== 0 && <h5 className="text-center" style={{ marginTop: '0.4rem' }}>Total Rs. {formatIndianNumber((payment / 100000).toFixed(2) + 'L')}</h5>}
              <div style={{ paddingTop:'6rem' }}>
              {((poSeries.length) === 0)?(
                  <div className="DasPosCLasSec mt-2">
                    <p>No data available</p>
                  </div>
                ):(
                  <AMPiechart dataSeries={poSeries} labels={poLabels}></AMPiechart>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="DashChartCont">
              <h6 className="DashChartTitle">Payment Outstanding</h6>
                <h5 className="text-center" style={{ marginTop: '0.4rem' }}>Total Rs. {formatIndianNumber((pendPo / 100000).toFixed(2) + 'L')}</h5>
              <div style={{ paddingTop: pySeries.length===0? '3rem':'6rem' }}>
              {((pySeries.length) === 0)?(
                  <div className="DasPosCLasSec mt-2">
                    <p>No data available</p>
                  </div>
                ):(
                  <AMPiechart dataSeries={pySeries} labels={pyLabels}></AMPiechart>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="DashChartCont">
              <h6 className="DashChartTitle">Purchase History</h6>
              <div className="p-3">
              {((SlotOne.length && SlotTwo.length) === 0)?(
                  <div className="DasPosCLas mt-5">
                    <p>No data available</p>
                  </div>
                ):(
                  <AMBarChart options={options2}></AMBarChart>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="DashChartCont">
              <div className="DashChartTitle d-flex justify-content-between ps-4 pe-4">
                <h5 className="">Product Wise Purchase (Top 5 selling)</h5>
                <div class="DashCustomSelect">
                  <select value={chartValue} onChange={handleChartChng}>
                    <option value="1">Value</option>
                    <option value="2">Quantity&nbsp;(In Boxes)</option>
                  </select>
                </div>
              </div>
              <div className="ps-3 pe-3 pb-3" style={{ paddingTop: '3.2rem' }}>
                {((SlotThree.length && SlotThree.length) === 0)?(
                  <div className="DasPosCLas mt-2">
                    <p>No data available</p>
                  </div>
                ):(
                  <AMBarChart options={options3}></AMBarChart>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
    )
}