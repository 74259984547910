const dbName = 'EKart';
const storeName = 'apiDataStore';

// Initialize IndexedDB
const initDB = () => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(dbName, 1);

        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains(storeName)) {
                db.createObjectStore(storeName);
            }
        };

        request.onsuccess = () => resolve(request.result);
        request.onerror = (event) => reject(event.target.error);
    });
};

// Save Data to IndexedDB
export const saveToIndexedDB = async (key, data) => {
    try {
        const dbAppend = await initDB();
        const transactionAppend = dbAppend.transaction(storeName, 'readonly');
        const storeAppend = transactionAppend.objectStore(storeName);
        const existingData = await new Promise((resolve, reject) => {
            const request = storeAppend.get(key);
            request.onsuccess = () => resolve(request.result);
            request.onerror = (event) => reject(event.target.error);
        });

        const db = await initDB();
        const transaction = db.transaction(storeName, 'readwrite');
        const store = transaction.objectStore(storeName);
        
        const mergedData = Array.isArray(existingData) ? [...existingData, ...data] : [...data];
        //console.log('Merged Data :', JSON.stringify(mergedData));

        return new Promise((resolve, reject) => {
            const request = store.put(mergedData, key);
            request.onsuccess = () => resolve();
            request.onerror = (event) => reject(event.target.error);
        });
    } catch (error) {
        console.error('Error saving data to IndexedDB:', error);
    }
};

// Retrieve Data from IndexedDB
export const getFromIndexedDB = async (key) => {
    const db = await initDB();
    const transaction = db.transaction(storeName, 'readonly');
    const store = transaction.objectStore(storeName);

    return new Promise((resolve, reject) => {
        const request = store.get(key);
        request.onsuccess = () => resolve(request.result);
        request.onerror = (event) => reject(event.target.error);
    });
};

export const removeFromIndexedDB = async (key) => {
    try {
        const db = await initDB(); // Initialize the DB
        const transaction = db.transaction(storeName, 'readwrite'); // Start a readwrite transaction
        const store = transaction.objectStore(storeName); // Get the object store

        // Use the `delete` method to remove data by key
        const request = store.delete(key);

        return new Promise((resolve, reject) => {
            request.onsuccess = () => {
                //console.log(`Data with key ${key} deleted successfully.`);
                resolve();
            };
            request.onerror = (event) => {
                console.error("Error deleting data from IndexedDB:", event.target.error);
                reject(event.target.error);
            };
        });
    } catch (error) {
        console.error('Error removing data from IndexedDB:', error);
    }
};
