import logo from '../../assets/logo.png'
import f1 from '../../assets/footer/1.png'
import f2 from '../../assets/footer/2.png'
import f3 from '../../assets/footer/3.png'
import './Footer.css'
export const Footer = () => {
    return (
        <footer className="FooterClass">
            <div className="container-fluid" style={{ backgroundColor: '#CBE6FF'}} >
                <div className="row d-flex justify-content-between pt-2 pb-2">
                    <div className="col-md-6">
                        <img src={logo} alt="" className='mb-2 mt-2' />
                        <p>

                            At Amaryllis Healthcare, we place the greatest value on customer and <br />
                            end-user satisfaction. We understand the absolute importance of <br />
                            providing uncompromised medical treatment and take measures to <br />
                            ensure that our products can be relied upon to enhance patient care.
                        </p>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-4 ">
                        <div className='row'>
                            <div className='col-md-4'></div>
                            <div className='col-md-8'>
                            <div className='ContactDiv'>
                        <p style={{paddingTop:30,fontWeight:'500' }}>Contact Us</p>
                        {/*<p><img src={f3} alt="" />&nbsp;&nbsp;080-2783 1600</p>*/}
                        <p style={{fontWeight:'500' }}><img src={f1} alt="" />&nbsp;+91 9169 338 338</p>
                        <p style={{fontWeight:'500' }}><img src={f2} alt="" />&nbsp;info@amaryllishealthcare.com</p>
                        </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12 text-center'>
                        {/*<p style={{fontSize:10,textAlign:'center'}}>@2024 Amaryllis Healthcare Pvt. Ltd. All Rights Reserved</p>*/}
                        <p style={{ fontSize: 10, textAlign: 'center' }}>
                            &copy; {new Date().getFullYear()} Amaryllis Healthcare Pvt. Ltd. All Rights Reserved
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}