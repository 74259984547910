import { Banner } from "../../components/Banner/Banner"
import { UserHome } from "../../components/UserHome/UserHome"

export const UserPage = () =>{

    return(
        <div>
            <Banner 
                text={""}
            />
            <UserHome/>
        </div>
    )
}