import { Link, useLocation } from "react-router-dom"
import { Banner } from "../../components/Banner/Banner"
import { UserHeader } from "../../components/UserHeader/UserHeader"
import { BreadCmp } from "../../core/BreadCrump/BreadCmp"
import { useState, useEffect } from "react"
import { Footer } from "../../components/Footer/Footer"
import { Modal, Radio, RadioGroup, Checkbox } from 'rsuite';
import './Cart.css';
import notfound from "../../assets/notfound.png";
import AMAutocomplete from "../../core/AmAutoComplete/AMAutocomplete";
import QuickOrderService from "../../services/QuickOrderService";
import { GetSessions, StoreSessions } from "../../services/SessionService";
import HomeService from "../../services/HomeService";
import { ProductLastCard } from "../../core/ProductCard/ProductLast";
import { AMToasterService } from "../../services/ToasterSerivce";
import { ProdcutCardSearch } from "../../core/ProductCard/ProductSearch";
import { LoaderCheck } from "../../core/Loader/Loader";
import OrderService from "../../services/OrderService";
import formatIndianNumber from "../../services/FormatNumber";
import { AmsButton } from "../../core/Button/Button";
import UserService from "../../services/UserService";
import { useSetRecoilState, useResetRecoilState } from "recoil";
import { CartAtom } from "../../rec-state/atoms";
import { getFromIndexedDB } from '../../constants/IndexedDb.jsx'

export const Cart = () => {
    const [amuser, setAMUser] = useState(JSON.parse(GetSessions('AM_USER_RE')))
    const [offProd, setOffProd] = useState([])
    const [cuMissed, setCuMissed] = useState([])
    const [cuSuggested, setCuSuggested] = useState([])
    const [linkProd, setLinkProd] = useState([])
    const [lastOrder, setLastOrder] = useState([])
    const [searchItem, setSearchItem] = useState()
    const [selected, setSelected] = useState("");
    const [countQty, setCountQty] = useState(0)
    const [shipVal, setShipVal] = useState('S')
    const [ttlAmnt, setTTLAmnt] = useState(0)
    const [trasnChg, setTransChg] = useState(0)
    const [tranTtl, setTranTttl] = useState(0)
    const [roundOff, setRoundOff] = useState(0)
    const [totalBox, setTotalBox] = useState(0)
    const [cusAdd, setCusAdd] = useState([])
    const [totalTrans, setTotalTrans] = useState(0)
    const [showTran, setShowTran] = useState(false)
    const [disCount, setDisCount] = useState(0)
    const [load, setLoad] = useState(false)
    const [orderPlace, setOrderplce] = useState(false)
    const [suggestedItem, setSuggestedItem] = useState("")
    const [usProduct, setUsProduct] = useState("")
    const [open, setOpen] = useState(false)
    const handleClose = () => { setOpen(false); setUsProduct("") }
    const handleOpen = () => setOpen(true);
    const [usName, setUsName] = useState("")
    const [usPhone, setUsPhone] = useState("")
    const [usEmail, setUsEmail] = useState("")
    const [usRemark, setUsRemark] = useState("")
    const [error, setError] = useState(false)
    const confSetCountVal = useSetRecoilState(CartAtom)
    const [selectedShipping, setSelectedShipping] = useState(null);
    const [selectedBilling, setSelectedBilling] = useState(null);
    const confResetCountVal = useResetRecoilState(CartAtom)

    useEffect(() => {
        getCartBoxes()
        getSearchProds()
        getOfferProds()
        getMissedProd()
        getSuggestedProd(0)
        getCustomerShipAddress()
        calculateAmount()
    }, []);

    //updating the cart count
    useEffect(() => {
        confSetCountVal(lastOrder.length)
    }, [lastOrder])

    useEffect(() => {
        if (usProduct) {
            handleOpen();
        }
    }, [usProduct]);

    const handleQuoteClick = (itemCode,linkitem) => {
        setUsProduct(itemCode+" ------ "+linkitem);
    };

    const getLastOrdered = async () => {
        if (lastOrder.length == 0) {
            setLoad(true)
            let payload = {
                custcode: amuser.CustomerCode,
                itemgroupid: 0
            }
            let data = await HomeService.getReorderedProducts(payload)
            if (data.Data.length !== 0) {
                setTotalBox(data.Data.reduce((sum, item) => { return sum + item.KartDetQtyInBoxes }, 0))
                setLoad(false)
            }
            setLastOrder(data.Data)
            inserCartBox(data.Data, 'INIT')
            setLoad(false)
        }
    }

    /**
  * two types of activity coming here
  * first one add items ,add or sub quantity ,remove
  * if type add =>find item and using index ,if not found push the item
  * if type remove => remove using the index
  */
    const CartCrud = async (value) => {
        switch (value.param) {
            case 'R':
                if (value) {
                    removeCarBYIdItem(value.Data)
                } else {
                    AMToasterService('E', 'Item not in cart')
                }
                break;
            case 'A':
                let addIndex = lastOrder.findIndex((item) => item.KartDetItemCode === value.Data.KartDetItemCode)
                await getMissedProd()
                if (addIndex !== -1) {
                    const updateItem = [...lastOrder]
                    updateItem[addIndex] = {
                        ...updateItem[addIndex],
                        KartDetCustomerCode: amuser.CustomerCode,
                        KartDetItemCode: value.Data.KartDetItemCode,
                        KartDetItemName: value.Data.KartDetItemName,
                        KartDetItemDP: value.Data.KartDetItemDP,
                        KartDetTaxPer: value.Data.KartDetTaxPer,
                        KartDetBoxQty: value.Data.KartDetBoxQty,
                        KartDetQtyInBoxes: value.Data.KartDetQtyInBoxes,
                        KartDetOrderQty: (value.Data.KartDetQtyInBoxes + 1) * value.Data.KartDetBoxQty,
                        KartDetOrderVal: (value.Data.KartDetQtyInBoxes * value.Data.KartDetBoxQty) * value.Data.KartDetItemDP,
                        KartDetTaxAmount: ((value.Data.KartDetQtyInBoxes * value.Data.KartDetBoxQty) * value.Data.KartDetItemDP) * (value.Data.KartDetTaxPer / 100),
                        KartDetTotalAmount: ((value.Data.KartDetQtyInBoxes * value.Data.KartDetBoxQty) * value.Data.KartDetItemDP) + ((value.Data.KartDetQtyInBoxes * value.Data.KartDetBoxQty) * (value.Data.KartDetTaxPer / 100)),
                        KartDetRemarks: value.Data.KartDetRemarks,
                        KartDetTenderOrder: value.Data.KartDetTenderOrder,
                        KartDetFirstOrder: value.Data.KartDetFirstOrder
                    }
                    inserCartBox(updateItem)
                    AMToasterService('S', "Updated Successfully")
                } else {
                    const updateItem = [...lastOrder, value.Data]
                    inserCartBox(updateItem)
                    AMToasterService('S', "Updated Successfully")
                }
                break;
            default:
                break;
        }
    }
    const inserCartBox = async (itemData, type) => {
        if (type == 'INIT') {
            itemData = itemData.map((item) => ({
                KartDetCustomerCode: amuser.CustomerCode,
                KartDetItemCode: item.CustomerLinkItemCode,
                KartDetItemName: item.CustomerLinkItemName,
                KartDetItemDP: item.CustomerLinkItemDP,
                KartDetTaxPer: item.CustomerLinkItemTaxPer,
                KartDetBoxQty: item.CustomerLinkItemBoxQty,
                KartDetQtyInBoxes: item.BoxOrderQty,
                KartDetOrderQty: item.CustomerLinkItemBoxQty * item.BoxOrderQty,
                KartDetOrderVal: (item.BoxOrderQty * item.CustomerLinkItemBoxQty) * item.CustomerLinkItemDP,
                KartDetTaxAmount: ((item.BoxOrderQty * item.CustomerLinkItemBoxQty) * item.CustomerLinkItemDP) * item.CustomerLinkItemTaxPer / 100,
                KartDetTotalAmount: ((item.KartDetQtyInBoxes * item.KartDetBoxQty) * item.KartDetItemDP) + (((item.KartDetQtyInBoxes * item.KartDetBoxQty) * item.KartDetItemDP) * (item.KartDetTaxPer / 100)),
                //KartDetTotalAmount: ((item.BoxOrderQty * item.CustomerLinkItemBoxQty) * item.CustomerLinkItemDP) + ((item.BoxOrderQty * item.CustomerLinkItemBoxQty) * item.CustomerLinkItemTaxPer / 100),
                KartDetRemarks: item.KartDetRemarks,
                KartDetTenderOrder: item.KartDetTenderOrder,
                KartDetFirstOrder: item.KartDetFirstOrder
            }))
        }
        let payload = {
            custcode: amuser.CustomerCode,
            items: itemData
        }
        let data = await QuickOrderService.insertCartItems(payload)
        getCartBoxes()
    }
    const getCartBoxes = async () => {
        let payload = {
            KartDetCustomerCode: amuser.CustomerCode
        }
        let data = await QuickOrderService.getCartItems(payload)
        setTotalBox(data.Data.reduce((sum, item) => { return sum + item.KartDetQtyInBoxes }, 0))
        setLastOrder(data.Data)
        calculateAmount(data.Data)
        //console.log(data.Data.length)
        confSetCountVal(data.Data.length)
        confResetCountVal(data.Data.length)
    }

    const removeCartItmes = async () => {
        let payload = {
            KartDetCustomerCode: amuser.CustomerCode,
            KartDetItemCode: "",
            KartDetId: ""
        }

        let data = await QuickOrderService.removeCartItemById(payload)
        AMToasterService('I', "Cart is empty")
        getCartBoxes()
    }

    const removeCarBYIdItem = async (item) => {
        let payload = {
            KartDetCustomerCode: amuser.CustomerCode,
            KartDetItemCode: item.KartDetItemCode,
            KartDetId: item.KartDetId
        }
        
        let data = await QuickOrderService.removeCartItemById(payload)
        AMToasterService('I', "Removed from Cart")
        getCartBoxes()
    }

    const getOfferProds = async () => {
        let payload = {
            custcode: amuser.CustomerCode
        }
        let data = await HomeService.getCustomerOfferProd(payload)
        setOffProd(data.Data[1].filter((item, index) => { return index < 4 }))
    }

    const handleGetQuote = async () => {
        if (usEmail != "" && usName != "" && usPhone != "" && usProduct != "") {
            let payload = {
                customer: JSON.parse(GetSessions('AM_USER_RE')).CustomerCode + ' - ' + JSON.parse(GetSessions('AM_USER_RE')).CustomerName,
                email: usEmail,
                name: usName,
                message: usRemark,
                phone: usPhone,
                product: usProduct
            }
            let data = await UserService.insertQuoteDetails(payload)
            if (data.Status) {
                AMToasterService('S', 'Successfull')
                resetQuotes()
            }
            handleClose()
        } else {
            setError(true)
            AMToasterService('E', 'Fill required fields')
        }
    }

    const resetQuotes = () => {
        setUsEmail("")
        setUsName("")
        setUsRemark("")
        setUsPhone("")
        setUsProduct("")
    }

    const getMissedProd = async () => {
        let payload = {
            custcode: amuser.CustomerCode
        }
        let data = await QuickOrderService.getCustomerMissedPrd(payload)
        setCuMissed(data.Data[0].length > 4 ? data.Data[0].slice(0, 4) : data.Data[0]);
    }

    const getSuggestedProd = async (itemcode) => {
        setSuggestedItem(itemcode);
        let payload = {
            custcode: amuser.CustomerCode,
            itemcode: itemcode
        }
        let data = await QuickOrderService.getCustomerSuggestedPrd(payload)
        setCuSuggested(data.Data.filter((item, index) => { return index < 4 }))
    }

    const getSearchProds = async () => {
        let payload = {
            custcode: amuser.CustomerCode,
            itemgroupid: 0
        }
        //let data = await QuickOrderService.getCustomerLinkedProd(payload)
        const data = await getFromIndexedDB('customerLinkedProducts');
        //console.log("indexdb data: ", JSON.stringify(data))
        /*setLinkProd(data.Data)*/
        setLinkProd(data)
    }

    const getCustomerAddress = async () => {
        let payload = {
            custcode: amuser.CustomerCode
        }
        let data = await QuickOrderService.getCustomerAddress(payload)
        sessionStorage.setItem("CUST_ADDRES", JSON.stringify(data.Data[0]))
        let custData = JSON.parse(sessionStorage.getItem('CUST_ADDRES'))
        getTransPortChrg(custData[0].AddressIdB)
    }

    const calculateAmount = async (item) => {
        let totalOrder = 0
        if (item) {
            totalOrder = item.reduce((sum, item) => { return sum + item.KartDetOrderVal }, 0)
            setTTLAmnt(item.reduce((sum, item) => { return sum + item.KartDetOrderVal }, 0))
            setTranTttl(item.reduce((sum, item) => { return sum + item.KartDetTaxAmount }, 0))
            setTotalTrans((item.reduce((sum, item) => { return sum + item.KartDetQtyInBoxes }, 0)))
            var totalValue = totalOrder + item.reduce((sum, item) => { return sum + item.KartDetTaxAmount }, 0) + (trasnChg * item.reduce((sum, item) => { return sum + item.KartDetQtyInBoxes }, 0));
            var totalorderValueRounded = Math.round(totalValue);
            var roundOff = totalorderValueRounded - totalValue;
            setRoundOff(roundOff);
            const totlQty = parseInt(item.reduce((sum, item) => { return sum + ((item.KartDetItemStPrice) * (item.KartDetBoxQty * item.KartDetQtyInBoxes)) }, 0))
            setDisCount(parseInt(totlQty))
        }
        if (totalOrder > amuser.CustomerMOV) {
            setShowTran(true)
            setTransChg(0)
        } else {
            setShowTran(false)
            getCustomerAddress()
        }
    }

    const getTransPortChrg = async (custIdb) => {
        let payload = {
            CustAddressId: [custIdb]
        }
        let data = await QuickOrderService.getTransportCharge(payload)
        if (data.Status) {
            setTransChg(data.Data[0][0].TcPricePerBox)
        }
    }

    const getCustomerShipAddress = async () => {
        let payload = {
            custcode: amuser.CustomerCode
        }
        let data = await QuickOrderService.getCustomerAddress(payload)
        setCusAdd(data.Data[0])
    }

    useEffect(() => {
        const firstShippingAddress = cusAdd.find((item) => item.AddressType === "S");
        const firstBillingAddress = cusAdd.find((item) => item.AddressType === "B");

        // Set default shipping and billing addresses if available
        if (firstShippingAddress) setSelectedShipping(firstShippingAddress.AddressId);
        if (firstBillingAddress) setSelectedBilling(firstBillingAddress.AddressId);
    }, [cusAdd]);

    const handleCheckboxChange = (type, addressId) => {
        if (type === "S") {
            setSelectedShipping((prev) => (prev === addressId ? null : addressId)); // Toggle Shipping
        } else if (type === "B") {
            setSelectedBilling((prev) => (prev === addressId ? null : addressId)); // Toggle Billing
            getTransPortChrg(addressId)
        }
    };

    const confirmOrder = async () => {
        setLoad(true)
        const inserItemMap = lastOrder.map((item) => ({
            CustomerLinkItemCode: item.KartDetItemCode,
            CustomerLinkItemDP: item.KartDetItemDP,
            CustomerLinkItemTaxPer: item.KartDetTaxPer,
            BoxOrderQty: item.KartDetQtyInBoxes,
            PcsOrderQty: item.KartDetBoxQty * item.KartDetQtyInBoxes,
            KartDetOrderVal: item.KartDetOrderVal,
            KartDetTaxAmount: item.KartDetTaxAmount,
            KartDetTotalAmount: item.KartDetOrderVal + item.KartDetTaxAmount,
            Remark: item.KartDetRemarks,
            KartDetTenderOrder: item.KartDetTenderOrder,
            KartDetFirstOrder: item.KartDetFirstOrder,
        }))
        let payload = {
            //KartAddressIdB: [cusAdd[0].AddressIdB],
            //KartAddressIdS: [cusAdd[0].AddressIdS],
            KartAddressIdB: selectedBilling,
            KartAddressIdS: selectedShipping,
            KartCustCode: amuser.CustomerCode,
            KartNetAmount: ((trasnChg * totalTrans) + (ttlAmnt + tranTtl + roundOff)).toFixed(2),
            KartPoReference: "",
            KartPoRemark: "",
            KartRoundOff: roundOff, //"0." + ((trasnChg * totalTrans) + (ttlAmnt + tranTtl)).toFixed(2).split('.')[1], 
            KartTotalAmount: ttlAmnt.toFixed(2).toString(),
            KartTotalDisc: disCount.toFixed(2).toString(),
            KartTotalShipping: (trasnChg * totalTrans).toFixed(2).toString(),
            KartTotalTax: tranTtl.toFixed(2).toString()
        }
        if (lastOrder.length !== 0) {
            let cartResponse = await OrderService.confirmCartOrder(payload)
            if (cartResponse.Status) {
                setLoad(false )
                let kartPayload = {
                    KartInvoiceNo: cartResponse.Data.KartInvoiceNo,
                    items: inserItemMap
                }
                let insertResponse = await OrderService.insertCartMaster(kartPayload)
                if (insertResponse.Status) {
                    removeCartItmes()
                    setOrderplce(true)
                    confSetCountVal(0)
                    StoreSessions('AM_INIT_FLAG',false)
                } else {
                    AMToasterService('E', 'Order Cancelled')
                }
            }
        }
    }
    return (
        <>{load && <LoaderCheck isLoad={load}></LoaderCheck>}
            <div>
                <Banner
                text={""}
            />
                <UserHeader />
                <BreadCmp link={useLocation().pathname} />
                <div className="container-fluid fade-image-className">
                    <div className="row">
                        <div className="col-md-8 mt-2">
                            {/*<div className="row CartHeader">*/}
                            {/*    <h5>Delivery Address</h5>*/}
                            {/*    <RadioGroup className="CartContent" value={shipVal} onChange={setShipVal}>*/}
                            {/*        <div className="d-flex CartCompPara">*/}
                            {/*            {cusAdd.map((item, index) => (*/}
                            {/*                <div>*/}
                            {/*                    {index === 0 && <div>*/}
                            {/*                        <p>Shipping Address</p>*/}
                            {/*                        <Radio value='S'>*/}
                            {/*                            {item.AddressDetails}*/}
                            {/*                        </Radio>*/}
                            {/*                    </div>}*/}
                            {/*                    {index === 1 && <div>*/}
                            {/*                        <p>Billing Address</p>*/}
                            {/*                        <Radio value='B'>*/}
                            {/*                            {item.AddressDetails}*/}
                            {/*                        </Radio>*/}
                            {/*                    </div>}*/}
                            {/*                </div>*/}
                            {/*            ))}*/}
                            {/*        </div>*/}
                            {/*    </RadioGroup>*/}
                            {/*</div>*/}
                            <div className="row CartHeader">
                                <h5>Delivery Address</h5>
                                <div className="CartContent">
                                    <div className="d-flex CartCompPara">
                                        <div className="ShippingAddresses">
                                            <p>Shipping Addresses</p>
                                            {cusAdd
                                                .filter((item) => item.AddressType === 'S') // Filter Shipping Addresses
                                                .map((item) => (
                                                    <div key={item.AddressId}>
                                                        <Checkbox
                                                            checked={selectedShipping === item.AddressId}
                                                            onChange={() => handleCheckboxChange('S', item.AddressId)}
                                                        >
                                                            {item.AddressDetails}
                                                        </Checkbox>
                                                    </div>
                                                ))}
                                        </div>

                                        <div className="BillingAddresses" style={{ marginLeft: '20px' }}>
                                            <p>Billing Addresses</p>
                                            {cusAdd
                                                .filter((item) => item.AddressType === 'B') // Filter Billing Addresses
                                                .map((item) => (
                                                    <div key={item.AddressId}>
                                                        <Checkbox
                                                            checked={selectedBilling === item.AddressId}
                                                            onChange={() => handleCheckboxChange('B', item.AddressId)}
                                                        >
                                                            {item.AddressDetails}
                                                        </Checkbox>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <AMAutocomplete
                                onclickButton={getLastOrdered}
                                buttonClikColor={lastOrder}
                                options={linkProd}
                                value={selected}
                                ongetData={(val) => { setSearchItem(val); getSuggestedProd(val.CustomerLinkItemCode); }}
                                onChange={(val) => setSelected(val)}
                                placeholder={'Search...'}
                            ></AMAutocomplete>
                            {(searchItem !== undefined) ? (
                                <ProdcutCardSearch
                                    onSearchCart={CartCrud}
                                    item={searchItem} ></ProdcutCardSearch>
                            ) : (<></>)}
                            {lastOrder != null && lastOrder.length != 0 && <div className="QuickSecondStrip">
                                <h5>Products in Cart</h5>
                                <h5 className="ms-auto me-4">No. of Items: {lastOrder != null ? lastOrder.length : 0}</h5>
                                <h5 className="me-2" style={{ fontWeight: '400' }}> No. of Boxes: {totalBox}</h5>
                            </div>}
                            <div className="QuickBoxHeight">
                                {lastOrder.length !== 0 && lastOrder.map((item, index) => (
                                    <ProductLastCard
                                        onRemoveData={CartCrud}
                                        key={item.KartDetItemCode}
                                        item={item}>
                                    </ProductLastCard>
                                ))}
                            </div>
                            <div className="QuickStrip mb-3 mt-4">
                                <h3>Suggested Products</h3>
                                <Link style={{ color: 'black', cursor: 'pointer' }} to={`/suggestedProducts/${suggestedItem}`}><p>View All</p></Link>
                            </div>
                            <div className="mt-1 QuicKWidth">
                                {cuSuggested.map((item, index) => (
                                    <div className='col-md-3' key={index}>
                                        <div className='ProductCardOne'>
                                            {item.CustomerLinkItemDP ? (
                                                <Link to={`/productDetails/${item.CustomerLinkItemCode}`} style={{ cursor: 'pointer', color: '#222222' }}>
                                                    <img src={item.CustomerLinkItemImageData != "" ? `data:image/png;base64,${item.CustomerLinkItemImageData}` : notfound}
                                         className={item.CustomerLinkItemImageData===""?'QuickNotFimg2':'img-fluid-pr'} alt="" />
                                                </Link>
                                            ) : (
                                                <span style={{ cursor: 'not-allowed', color: 'gray' }}>
                                                    <img src={item.CustomerLinkItemImageData != "" ? `data:image/png;base64,${item.CustomerLinkItemImageData}` : notfound}
                                         className={item.CustomerLinkItemImageData===""?'QuickNotFimg2':'img-fluid-pr'} alt="" />
                                                </span>
                                            )}
                                            <div className='ProductContent'>
                                                <div className='ProductTitle'>
                                                    <div style={{fontSize:'0.6rem'}}>{item.CustomerLinkItemName}</div>
                                                    <div>Product Code&nbsp;:&nbsp;{item.CustomerLinkItemCode}</div>
                                                    {item.CustomerLinkItemDP > 0 ? (
                                                        <div style={{fontSize:'0.6rem'}}><span className="RuppeFont">₹</span>{formatIndianNumber(item.CustomerLinkItemDP)}&nbsp;&nbsp;<span className="RuppeFont">₹</span> <strike>{formatIndianNumber(item.CustomerLinkItemStPrice)}</strike></div>
                                                    ) :
                                                        (<div></div>)}
                                                </div>
                                                <div className='ProductBtn'>
                                                    {item.CustomerLinkItemDP > 0 ? (
                                                        <Link to={`/productDetails/${item.CustomerLinkItemCode}`} style={{ cursor: 'pointer', color: '#222222' }}>
                                                            <button type='button'>Add&nbsp;
                                                                <svg width="16" height="16" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M7.29102 9.91663H27.4014C27.8089 9.91664 28.2119 9.9996 28.5843 10.1602C28.9568 10.3207 29.2905 10.5553 29.5639 10.8488C29.8373 11.1423 30.0444 11.4882 30.1718 11.8642C30.2992 12.2402 30.3441 12.638 30.3035 13.0319L29.4285 21.5319C29.3565 22.231 29.0197 22.8792 28.4835 23.3507C27.9472 23.8222 27.2497 24.0833 26.5264 24.0833H12.5993C11.9248 24.0836 11.2711 23.8567 10.7494 23.4413C10.2277 23.026 9.8703 22.4478 9.7381 21.8053L7.29102 9.91663Z" stroke="white" strokeWidth="1.5" strokeLinejoin="round" />
                                                                    <path d="M7.29102 9.91667L6.10977 5.32242C6.03077 5.01607 5.84865 4.74415 5.59236 4.54985C5.33607 4.35554 5.02029 4.25001 4.69518 4.25H2.91602M11.666 29.75H14.5827M23.3327 29.75H26.2493" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </button>
                                                        </Link>
                                                    ) :
                                                        (<div>
                                                             <button onClick={() => handleQuoteClick(item.CustomerLinkItemCode,item.CustomerLinkItemName)}>Get Quote</button>
                                                        </div>)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {cuMissed.length!==0 && <>
                                <div className="QuickStrip mb-3 mt-4">
                                <h3>Products You Missed</h3>
                                <Link style={{ color: 'black', cursor: 'pointer' }} to={`/missedProducts`}><p className="mt-3">View All</p></Link>
                            </div>
                            <div className="mt-1 QuicKWidth">
                                {cuMissed.map((item, index) => (
                                    <div className='col-md-3' key={index}>
                                        <div className='ProductCardOne'>
                                            {item.CustomerLinkItemDP ? (
                                                <Link to={`/productDetails/${item.CustomerLinkItemCode}`} style={{ cursor: 'pointer', color: '#222222' }}>
                                                    <img src={item.CustomerLinkItemImageData != "" ? `data:image/png;base64,${item.CustomerLinkItemImageData}` : notfound}
                                         className={item.CustomerLinkItemImageData===""?'QuickNotFimg2':'img-fluid-pr'} alt="" />
                                                </Link>
                                            ) : (
                                                <span style={{ cursor: 'not-allowed', color: 'gray' }}>
                                                    <img src={item.CustomerLinkItemImageData != "" ? `data:image/png;base64,${item.CustomerLinkItemImageData}` : notfound}
                                         className={item.CustomerLinkItemImageData===""?'QuickNotFimg2':'img-fluid-pr'} alt="" />
                                                </span>
                                            )}
                                            <div className='ProductContent'>
                                                <div className='ProductTitle'>
                                                    <div style={{fontSize:'0.6rem'}}>{item.CustomerLinkItemName}</div>
                                                    <div>Product Code&nbsp;:&nbsp;{item.CustomerLinkItemCode}</div>
                                                    {item.CustomerLinkItemDP > 0 ? (
                                                        <div style={{fontSize:'0.6rem'}}><span className="RuppeFont">₹</span>{formatIndianNumber(item.CustomerLinkItemDP)}&nbsp;&nbsp;<span className="RuppeFont">₹</span> <strike>{formatIndianNumber(item.CustomerLinkItemStPrice)}</strike></div>
                                                    ) :
                                                        (<div></div>)}
                                                </div>
                                                <div className='ProductBtn'>
                                                    {item.CustomerLinkItemDP > 0 ? (
                                                        <Link to={`/productDetails/${item.CustomerLinkItemCode}`} style={{ cursor: 'pointer', color: '#222222' }}>
                                                            <button type='button'>Add&nbsp;
                                                                <svg width="16" height="16" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M7.29102 9.91663H27.4014C27.8089 9.91664 28.2119 9.9996 28.5843 10.1602C28.9568 10.3207 29.2905 10.5553 29.5639 10.8488C29.8373 11.1423 30.0444 11.4882 30.1718 11.8642C30.2992 12.2402 30.3441 12.638 30.3035 13.0319L29.4285 21.5319C29.3565 22.231 29.0197 22.8792 28.4835 23.3507C27.9472 23.8222 27.2497 24.0833 26.5264 24.0833H12.5993C11.9248 24.0836 11.2711 23.8567 10.7494 23.4413C10.2277 23.026 9.8703 22.4478 9.7381 21.8053L7.29102 9.91663Z" stroke="white" strokeWidth="1.5" strokeLinejoin="round" />
                                                                    <path d="M7.29102 9.91667L6.10977 5.32242C6.03077 5.01607 5.84865 4.74415 5.59236 4.54985C5.33607 4.35554 5.02029 4.25001 4.69518 4.25H2.91602M11.666 29.75H14.5827M23.3327 29.75H26.2493" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </button>
                                                        </Link>
                                                    ) :
                                                        (<div className="ms-3"><button onClick={() => handleQuoteClick(item.CustomerLinkItemCode,item.CustomerLinkItemName)} style={{ width: '100%' }}>Get Quote</button></div>)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div></>}
                            {offProd.length !== 0 && 
                            <>
                                <div className="QuickStrip mb-3 mt-4">
                                    <h3>Special Offers</h3>
                                    <Link style={{ color: 'black', cursor: 'pointer' }} to={'/specialOffer'}><p>View All</p></Link>
                                </div>
                                <div className="mt-1 QuicKWidth">
                                    {offProd.map((item, index) => (
                                        <div className='col-md-3' key={index}>
                                            <div className='ProductCardOne'>
                                                {item.CustomerLinkItemDP ? (
                                                    <Link to={`/productDetails/${item.CustomerLinkItemCode}`} style={{ cursor: 'pointer', color: '#222222' }}>
                                                        <img src={item.CustomerLinkItemImageData != "" ? `data:image/png;base64,${item.CustomerLinkItemImageData}` : notfound}
                                         className={item.CustomerLinkItemImageData===""?'QuickNotFimg2':'img-fluid-pr'} alt="" />
                                                    </Link>
                                                ) : (
                                                    <span style={{ cursor: 'not-allowed', color: 'gray' }}>
                                                        <img src={item.CustomerLinkItemImageData != "" ? `data:image/png;base64,${item.CustomerLinkItemImageData}` : notfound}
                                         className={item.CustomerLinkItemImageData===""?'QuickNotFimg2':'img-fluid-pr'} alt="" />
                                                    </span>
                                                )}
                                                <div className='ProductContent'>
                                                    <div className="ProductOffer">
                                                        <span><i class="fa-solid fa-tag"></i>&nbsp;OFFER*</span>
                                                    </div>
                                                    <div className='ProductTitle'>
                                                        <div>{item.CustomerLinkItemName}</div>
                                                        <div>Product Code&nbsp;:&nbsp;{item.ItemCode}</div>
                                                        {item.CustomerLinkItemDP > 0 ? (
                                                            <div><span className="RuppeFont">₹</span>{formatIndianNumber(item.CustomerLinkItemDP)}&nbsp;&nbsp;<span className="RuppeFont">₹</span> <strike>{formatIndianNumber(item.CustomerLinkItemStPrice)}</strike></div>
                                                        ) :
                                                            (<div></div>)}
                                                    </div>
                                                    <div className='ProductBtn'>
                                                        {item.CustomerLinkItemDP > 0 ? (
                                                            <Link to={`/productDetails/${item.CustomerLinkItemCode}`} style={{ cursor: 'pointer', color: '#222222' }}>
                                                                <button type='button'>Add&nbsp;
                                                                    <svg width="16" height="16" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M7.29102 9.91663H27.4014C27.8089 9.91664 28.2119 9.9996 28.5843 10.1602C28.9568 10.3207 29.2905 10.5553 29.5639 10.8488C29.8373 11.1423 30.0444 11.4882 30.1718 11.8642C30.2992 12.2402 30.3441 12.638 30.3035 13.0319L29.4285 21.5319C29.3565 22.231 29.0197 22.8792 28.4835 23.3507C27.9472 23.8222 27.2497 24.0833 26.5264 24.0833H12.5993C11.9248 24.0836 11.2711 23.8567 10.7494 23.4413C10.2277 23.026 9.8703 22.4478 9.7381 21.8053L7.29102 9.91663Z" stroke="white" strokeWidth="1.5" strokeLinejoin="round" />
                                                                        <path d="M7.29102 9.91667L6.10977 5.32242C6.03077 5.01607 5.84865 4.74415 5.59236 4.54985C5.33607 4.35554 5.02029 4.25001 4.69518 4.25H2.91602M11.666 29.75H14.5827M23.3327 29.75H26.2493" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                    </svg>
                                                                </button>
                                                            </Link>
                                                        ) :
                                                            (<div className="ms-3"><button onClick={() => handleQuoteClick(item.CustomerLinkItemCode,item.CustomerLinkItemName)} style={{ width: '100%' }}>Get Quote</button></div>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>}
                        </div>

                        <div className="col-md-4">
                            <div className="row QuickRowSecond mt-5">
                                <h3>M. O. V : <span className="RuppeFont2">₹</span> {formatIndianNumber(amuser.CustomerMOV)}</h3>
                                {showTran ? (
                                    <p className="paraClass"></p>
                                ) : (
                                    <p className="paraClass">Add <span className="RuppeFont">₹</span> {formatIndianNumber(parseInt(amuser.CustomerMOV - ttlAmnt))} more to get free shipping</p>
                                )}
                                <div className="QuickUnderline2"></div>
                                <div className="d-flex justify-content-between mt-2">
                                    <h5>Price</h5>
                                    <h5>Details</h5>
                                </div>
                                <div className="QuickUnderline2"></div>
                                <div className="d-flex align-items-center justify-content-between QuickPaie">
                                    <p>Total Amount</p>
                                    <p><span className="RuppeFont">₹</span>{formatIndianNumber(ttlAmnt.toFixed(2))}</p>
                                </div>
                                <div className="d-flex align-items-center justify-content-between QuickPaie">
                                    <p>Transportation Charge </p>
                                    {showTran ? (
                                        <p><span className="RuppeFont">₹</span>{0}</p>
                                    ) : (<p><span className="RuppeFont">₹</span> {formatIndianNumber((trasnChg * totalTrans).toFixed(2))}</p>)}

                                </div>
                                <div className="d-flex align-items-center justify-content-between QuickPaie">
                                    <p>Tax Amount (GST)</p>
                                    <p><span className="RuppeFont">₹</span> {formatIndianNumber(tranTtl.toFixed(2))}</p>
                                </div>
                                <div className="d-flex align-items-center justify-content-between QuickPaie">
                                    <p>Discount Applied</p>
                                    <p><span className="RuppeFont">₹</span>{formatIndianNumber((disCount === 0 ? ttlAmnt : disCount - ttlAmnt).toFixed(2))}</p>
                                </div>
                                <div className="d-flex align-items-center justify-content-between QuickPaie">
                                    <p>Round Off</p>
                                    <p><span className="RuppeFont">₹</span>{formatIndianNumber(roundOff.toFixed(2))}</p>
                                </div>
                                <div className="QuickUnderline2"></div>
                                <div className="d-flex justify-content-between mt-2">
                                    <h5>Order Total</h5>
                                    <h5><span className="RuppeFont">₹</span> {formatIndianNumber(((ttlAmnt + tranTtl) === 0 ? 0 : (trasnChg * totalTrans) + ttlAmnt + tranTtl + roundOff).toFixed(2))}</h5>
                                </div>
                                {lastOrder.length !== 0 &&
                                    <button onClick={confirmOrder} className="QuickFeaBtn mt-4 mb-2" type="button">
                                        Confirm Order
                                    </button>}
                                <div className="d-flex justify-content-between mt-2">
                                    <h5>Account</h5>
                                    <h5>Summary</h5>
                                </div>
                                <div className="QuickUnderline2"></div>
                                <div className="d-flex align-items-center justify-content-between QuickPaie">
                                    <p>Current Order Value</p>
                                    <p><span className="RuppeFont">₹</span> {formatIndianNumber(((ttlAmnt + tranTtl) === 0 ? 0 : (trasnChg * totalTrans) + ttlAmnt + tranTtl + roundOff))}</p>
                                </div>
                                <div className="d-flex align-items-center justify-content-between QuickPaie">
                                    <p>Previous Dues</p>
                                    <p><span className="RuppeFont">₹</span> {formatIndianNumber((amuser.CustomerDue))}</p>
                                </div>
                                <div className="QuickUnderline2"></div>
                                <div className="d-flex justify-content-between mt-2">
                                    <h5>Total Due</h5>
                                    <h5><span className="RuppeFont">₹</span> {formatIndianNumber(((ttlAmnt + tranTtl + trasnChg) + ((parseInt(amuser.CustomerDue)))))}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal open={orderPlace} size={'full'} backdrop={true} style={{ zIndex: '9999' }} onClose={() => setOrderplce(false)}>
                    <Modal.Header>

                    </Modal.Header>
                    <Modal.Body>
                        <div className="AmOrderPlaced">
                            <i class="fa-regular fa-circle-check fa-beat-fade"></i>
                            <h6 className="mt-3 mb-3">Order Confirmed</h6>
                            <p>your order has been placed successfully.</p>
                            <div className="mt-3">
                                <Link to={'/userHome'} onClick={() => setOrderplce(false)}>
                                    <button>Continue shopping &nbsp;<i class="fa-solid fa-arrow-right"></i></button>
                                </Link>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal size={'sm'} backdrop={'static'} style={{ marginTop: '3rem' }} open={open} onClose={handleClose}>
                    <Modal.Header>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container-fluid">
                            <h5>Quotation Request</h5>
                            <div className='row mt-3'>
                                <div className='col-md-12 mb-3'>
                                    <input
                                        value={usName}
                                        onInput={() => setError(false)}
                                        style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                        onChange={(event) => setUsName(event.target.value)}
                                        type="text" placeholder="Enter Name" className='form-control' />
                                </div>
                                <div className='col-md-12 mb-3'>
                                    <input
                                        type="text"
                                        onInput={() => setError(false)}
                                        style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                        value={usPhone}
                                        onChange={(event) => setUsPhone(event.target.value)}
                                        placeholder="Enter Phone" className='form-control' />
                                </div>
                                <div className='col-md-12 mb-3'>
                                    <input
                                        type="text"
                                        onInput={() => setError(false)}
                                        style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                        value={usEmail}
                                        onChange={(event) => setUsEmail(event.target.value)}
                                        placeholder="Enter Email"
                                        className='form-control' />
                                </div>
                                <div className='col-md-12 mb-3'>
                                    <textarea
                                        type="number"
                                        onInput={() => setError(false)}
                                        style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                        value={usRemark}
                                        onChange={(event) => setUsRemark(event.target.value)}
                                        placeholder="Enter Remarks"
                                        className='form-control' ></textarea>
                                </div>
                                <div className='col-md-12 text-center'>
                                    <AmsButton
                                        onClick={handleGetQuote}
                                        marginBottom={20}
                                        icon={''}
                                        textcolor={'#fff'}
                                        buttonwid={'75%'}
                                        color={'#3D97EA'}
                                        label={'Submit'} />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Footer />
            </div>
        </>
    )
}