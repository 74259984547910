export const ApiEndPoints = {
    //authentication apis
    AUTH_API: '/getauth',
    LOGIN_API:'/login',
    SEND_OTP_API:'/sendforgotpasswordotp',
    FORGOT_PASSWORD:'/forgotpassword',
    GET_CUSTOMER_DETAILS:'/getcustomerdetails',
    INSERT_QUOTE_DETAILS:'/quotationmail',
    //home page apis
    SELECT_PRODUCT_GROUP:'/selectproductgroup',
    PRODUCT:'/selectproducts',
    //userhomes apis
    SELECT_NOTIFICATIONS:'/selectnotifications',
    CUSTOMER_LINKED_PROD_BY_SALES:'/customerlinkedproductsbysales',
    CUSTOMER_LINKED_PRODCUTS:'/customerlinkedproducts',
    CUSTOMER_MISSED_PRODUCTS: '/missedproducts',
    CUSTOMER_SUGGESTED_PRODUCTS: '/suggestedproducts',
    GET_CAMPAIGN_IMAGE:'/getcampaignimage',
    CUSTOMER_DEALS:'/customerdeals',
    CUSTOMER_OFFER_PRODCUTS:'/customerofferproducts',
    CUSTOMER_STANDARD_PRODCUTS:'/customerstandardproducts',
    CUSTOMER_LAST_ORDERED:'/customerlastorder',
    CUSTOMER_REORDER_PRODUCTS: '/customerreorderproducts',
    CUSTOMER_CAMPAIGN_PRODUCTS: '/customercampaignproducts',
    //common apis  
    DISTRIBUTE_ANALYSIS_DATA:'/selectdistributortargetachievechart',
    SIMILAR_PRODUCTS:'/customersimilarproducts',
    GET_PRODUCTS_GROUP_MAIN:'/selectitemmaingroups',
    GET_CATEGORY_BY_GROUP:'/selectcustomeritemcategorygroups',
    GET_PRODUCTS_GROUP_NAMES:'/selectcustomeritemgroups',
    GET_PRODUCTS_SUB_GROUPS: '/selectitemsubgroupmaster',
    GET_MENUS: '/getmenudata',
    //dashboard apis
    TOTAL_PURCHASE_CHART:'/selecttotalpurchasechart',
    PENDING_PO_CHART:'/selectpendingpochart',
    PURCHASE_HISTORY_CHART:'/selectpurchasehistorychart',
    PENDING_PAYMENT_CHART:'/selectpendingpaymentchart',
    PRODUCT_PURCHASE_CHART:'/selectproductwisepurchasechart',
    //quick order apis
    CUSTOMER_LINKED_TOP5_PRODCUTS:'/customerlinkedtopfiveproductsbysales',
    SELECT_CUSTOMER_ADDRESS:'/selectcustomeraddress',
    //customer address idb pass
    GET_TRANSPORTATION_CHARGE:'/gettransportationcharge', 
    //my order details 
    GET_ORDER_DETAILS: '/ordermasterdetails',
    DOWNLOAD_INVOICE: '/getinvoice',
    
    //cart apis
    INSERT_CART_ITEMS:'/inserttmpkartdetails',
    SELECT_CART_ITEMS:'/selecttmpkartdetails',
    DELETE_CART_ITEM: '/deletetmpkartdetails',
    DELETE_CART_ITEMS: '/deletetmpkartdetails',

    //place order apis
    ADD_TO_CART_MASTER:'/addtokartmaster',
    INSERT_CART_MASTER:'/insertkartdetails',

    //payment details api 
    GET_AGING_DETAILS:'/selectagingdetails',
    GET_COLLECTION_DETAILS: '/collectiondetails',
    INSERT_PAYMENT_DETAILS: '/insertpaymentdetails',

    //my return apis
    GET_KART_DETAILS_RETURN:'/selectkartdetailsforreturn',
    GET_RETUREND_ITEMS:'/selectkartdetailsforreturned',
    INSERT_RETURN_ITEMS:'/insertreturnkartdetails',
    INSERT_FEED_BACK: '/insertfeedback',

    //contact us apis
    GET_CONTACT_US_EMAIL:'/contactusmail'
}