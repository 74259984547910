import './LandingNav.css'
import logo from '../../assets/logo.png'
import { Link } from "react-router-dom";

export const LandingNav = () =>{
    return(
        <div className='LandHead'>
            <div className='pt-2 pb-2'>
                <Link to="/"><img src={logo} height={'60px'} alt="" /></Link>
            </div>
            <div></div>
            <div className='d-flex align-items-center justify-content-center'>
                <h6 className='me-5 LandContact'>
                    <Link to="/contactus" style={{color:'#222222',textDecoration:'none'}}>Contact us</Link>
                </h6>
                <Link to="/login">
                <button className='LandButton'>Login</button>
                </Link>
            </div>
        </div>
    )
}