import { useEffect, useState } from 'react';
import './BreadCmp.css'
export const BreadCmp = ({link})=>{
    const [breadVal, setBreadVal]=useState("");
    useEffect(()=>{
        let val = (link.split("/")[1])
        setValForBread(val)
    },[])
    const setValForBread = (val)=>{
        switch (val) {
            case 'newProduct':
                setBreadVal(()=>"New Products")
                break;
            case 'feaProduct':
                setBreadVal(()=>"Featured Products")
                break;
            case 'dashBoard':
                setBreadVal(()=>"Dashboard")
                break;
            case 'myOrders':
                setBreadVal(()=>"My Order")
                break;
            case 'quickOrder':
                setBreadVal(()=>"Quick Order")
                break;
            case 'paymentDetails':
                setBreadVal(()=>"Payment Details")
                break;
            case 'kycDetails':
                setBreadVal(()=>"KYC Details")
                break;
            case 'cart':
                setBreadVal(()=>"Cart")
                break;
            case 'categories':
                setBreadVal(()=>"Categories")
                break;
            case 'productDetails':
                setBreadVal(()=>"Product Details")
                break;
            case 'reOrderProducts':
                setBreadVal(()=>"Reorder Products")
                break;
            case 'specialOffer':
                setBreadVal(()=>"Special Offer Products")
                break;
            case 'myReturns':
                setBreadVal(()=>"My Returns")
                break;
            case 'feedBacks':
                setBreadVal(()=>"Feedbacks")
                break;
            case 'suggestedProducts':
                setBreadVal(()=>"Suggested Products")
                break;
            case 'missedProducts':
                setBreadVal(()=>"Missed Products")
                break;
            case 'campaignProduct':
                setBreadVal(() => "Campaign Products")
                break;
            default:
                break;
        }
    }
    return(
        <div className="container-fluid mt-3">
        <div className="BreadCmp">
           <a href="/userHome">Home</a >{">"}<a href={`${link}`} aria-disabled='true' style={{pointerEvents:'none'}}>{breadVal}</a>
        </div>
        </div>
    )
}