import { Banner } from "../../components/Banner/Banner"
import { LandingNav } from "../../components/LandingNav/LandingNav"
import { AmsButton } from "../../core/Button/Button"
import './LoginPage.css'
import UserService from "../../services/UserService";
import { useState } from "react";
import { LoaderCheck } from "../../core/Loader/Loader";
import { useAuth } from "../../guard/AuthGuard";
import { AMToasterService } from "../../services/ToasterSerivce";
import { Modal } from "rsuite";


export const LoginPage = () => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState(false)
    const [isLoad, setIsLoad] = useState(false)
    const [newPass, setNewPass] = useState("")
    const [confPass, setConfPass] = useState("")
    const [otpPas, setOtpPass] = useState("")
    const [open, setOpen] = useState(false);
    const [errCatch, setErrCatch] = useState("")
    const [invalOp, setInvalOp] = useState(false)
    const handleInvClose = () =>{ setInvalOp(false) ;setUsername("");setPassword("")}

    const generateOtp = () => {
        let digits = '0123456789';
        let OTP = '';
        let len = digits.length
        for (let i = 0; i < 6; i++) {
            OTP += digits[Math.floor(Math.random() * len)];
        }
        return OTP
    }
    const handleOpen = async () => {
        if (username != '') {
            setOpen(true);
            let payload = {
                UserName: username
            }
            let data = await UserService.getCustomerDetails(payload)
            if (data.length !== 0) {
                let otp = generateOtp()
                let secondPayload = {
                    email: data[0].CustomerEmailId, //'amalsankar.ps@lyonit.com',
                    name: data[0].CustomerName,
                    message: otp,
                    phone: data[0].CustomerMobileNo
                }
                let dataTwo = await UserService.sendOtpToMail(secondPayload)
                setErrCatch(dataTwo.Error)
            }
        } else {
            setError(true)
        }
    }
    const handleClose = () => setOpen(false);
    const { login } = useAuth();
    const getAuth = () => {
        let payload = {
            UserName: username,
            Password: password
        }
        if (username !== '' && password !== "") {
            setIsLoad(true)
            UserService.getAuthorized(payload).then((response) => {
                if (response.Status) {
                    let payload = {
                        Password: response.responseData,
                        UserName: username
                    }
                    UserService.getLogin(payload).then(async (response2) => {
                        if (response2.Status) {
                            setIsLoad(false)
                            if (response2.Data.length > 0) {
                                await login(response2.Data)
                                localStorage.setItem('AM_POP_FLAG', true)
                                localStorage.setItem('AM_INIT_FLAG',false)
                                AMToasterService('S', 'SuccessFully Logged')
                            } else {
                                AMToasterService('E', 'Invalid Credentials')
                                setUsername("")
                                setPassword("")
                                setError(true)
                            }
                        } else if (!response2.Status && response2.Error === "") {
                            setIsLoad(false)
                            return AMToasterService('E', 'Invalid Credentials')
                        } else if (!response2.Status && response2.Error !== "") {
                            setIsLoad(false)
                            setInvalOp(true)
                        }
                    }).catch((error) => {
                        console.log("error", error)
                        setIsLoad(false)
                    })
                }
            }).catch((error) => {
                console.log("error login", error)
                AMToasterService('E', 'Internal server error')
                setIsLoad(false)
            })
        } else {
            AMToasterService('E', 'Fill the required Fields!')
            setError(true)
        }
    }

    const handleForgot = async () => {
        if (username != "" && confPass != "" && otpPas != "") {
            if (confPass === newPass) {
                let payload = {
                    CustomerCode: username,
                    password: confPass
                }
                let data = await UserService.getForgotPassword(payload)
                AMToasterService('S', 'Successfull')
            }
        } else {
            setError(true)
            AMToasterService('E', 'Fill the required Fields!')
        }
    }
    return (
        <div className="LoginContainer fade-image-className">
            <LandingNav />
            <section className="LoginBg">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-5 m-auto mt-5 py-5">
                            <div className="LoginBox">
                                <h5 className="mb-3">Log in</h5>
                                <div className="row mb-4 ps-2 pe-2">
                                     <input type="text"
                                        onInput={() => setError(false)}
                                        style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 2px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                        value={username}
                                        onChange={(event) => setUsername(event.target.value)}
                                        placeholder="Customer Code" className="form-control" />
                                </div>
                                <div className="row mb-3 ps-2 pe-2">
                                    <input type="password"
                                        onInput={() => setError(false)}
                                        value={password}
                                        style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                        onChange={(event) => setPassword(event.target.value)}
                                        placeholder="Password" className="form-control" />
                                </div>
                                <div className="LoginForgot"><h6 onClick={handleOpen} className="Fortext">Forgot Password?</h6></div>
                                <AmsButton icon={''} onClick={getAuth} marginBottom={20} textcolor={'#fff'} buttonwid={'100%'} color={'#0E45D4'} label={'Login'} />
                                {/* <div className="text-center LoginFont">
                                    New customer?  Register Here
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <LoaderCheck isLoad={isLoad} />

            <Modal size={'sm'} backdrop={'static'} style={{ marginTop: '3rem' }} open={open} onClose={handleClose}>
                <Modal.Header>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <h5>Forgot Password</h5>
                        <div className='row mt-3'>
                            <div className='col-md-12 mb-3'>
                                <input
                                    value={username}
                                    onInput={() => setError(false)}
                                    style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                    onChange={(event) => setUsername(event.target.value)}
                                    type="text" placeholder="User ID" className='form-control' />
                            </div>
                            <div className='col-md-12 mb-3'>
                                <input
                                    type="password"
                                    onInput={() => setError(false)}
                                    style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                    value={newPass}
                                    onChange={(event) => setNewPass(event.target.value)}
                                    placeholder="New Password" className='form-control' />
                            </div>
                            <div className='col-md-12 mb-3'>
                                <input
                                    type="password"
                                    onInput={() => setError(false)}
                                    style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                    value={confPass}
                                    onChange={(event) => setConfPass(event.target.value)}
                                    placeholder="Confirm Password"
                                    className='form-control' />
                            </div>
                            <div className='col-md-12 mb-3'>
                                <input
                                    type="number"
                                    onInput={() => setError(false)}
                                    style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                    value={otpPas}
                                    onChange={(event) => setOtpPass(event.target.value)}
                                    placeholder="Enter OTP"
                                    className='form-control' />
                            </div>
                            <div className='col-md-12 text-center'>
                                <AmsButton
                                    onClick={handleForgot}
                                    marginBottom={20}
                                    textcolor={'#fff'}
                                    icon={''}
                                    buttonwid={'75%'}
                                    color={'#3D97EA'}
                                    label={'Submit'} />
                            </div>
                            {errCatch != "" && <div>{errCatch}</div>}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal className="LoginInvalidClass" backdrop={true} open={invalOp} size={'xs'} onClose={handleInvClose}>
                <Modal.Header></Modal.Header>
                <Modal.Body>
                    <div className="LoginInact text-center p-2">
                        <i class="fa-solid fa-circle-exclamation"></i>
                        <h6 className="mt-3 mb-3">Error!</h6>
                        <p>This customer account is currently inactive. <br/>Please reach out for assistance.</p>
                        <div className="text-center mt-3">
                            <button onClick={handleInvClose}>Try again</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>

    )
}