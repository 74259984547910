import axios from "axios"
import { ApiEndPoints } from "../constants/ApiEndpoints"
import { ApiUrl } from "../constants/Server"
export default {
    getTotalPurchaseChart: async function(payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.TOTAL_PURCHASE_CHART,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
        }
    },
    getPendingPoChart: async function(payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.PENDING_PO_CHART,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
        }
    },
    getPurchaseChart: async function(payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.PURCHASE_HISTORY_CHART,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
        }
    },
    getProdcutPurchaseChart: async function(payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.PRODUCT_PURCHASE_CHART,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
        }
    },
    getPedningPaymentChart: async function(payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.PENDING_PAYMENT_CHART,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
        }
    }
}