import axios from "axios"
import { ApiEndPoints } from "../constants/ApiEndpoints"
import { ApiUrl } from "../constants/Server"

export default{
    getSimilarProdList: async function (payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.SIMILAR_PRODUCTS,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
        }
    },
    getMenuData: async function (payload) {
        try {
            const response = await axios({
                url: ApiUrl.SERVER_URL + ApiEndPoints.GET_MENUS,
                method: 'post',
                data: payload
            })
            return response.data
        } catch (error) {
            console.log("error ", error.response)
        }
    },
    getProductsGroups: async function (){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.GET_PRODUCTS_GROUP_MAIN,
                method:'post'
            })
            return response.data
        } catch (error) {
            console.log("error ",error.response)
        }
    },
    getProductsCategory: async function (payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.GET_CATEGORY_BY_GROUP,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error.response)
        }
    },
    getProductsCatsubGroup: async function (payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.GET_PRODUCTS_GROUP_NAMES,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error.response)
        }
    },
    getProductsSubMaster: async function (payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.GET_PRODUCTS_SUB_GROUPS,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error.response)
        }
    },
    insertFeedBack:async function(payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.INSERT_FEED_BACK,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error.response)
        }
    }
}