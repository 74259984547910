import { atom } from "recoil";

export const ReOrderAtom = atom({
    key:'REO_KEY',
    default:[]
})

export const FeaOrderAtom = atom({
    key:'FEA_KEY',
    default:[]
})

export const CartAtom = atom({
    key:'CART_LEY',
    default:0
})

export const SpecOfferAtom = atom({
    key:'SPEC_KEY',
    default:[]
})

export const BannerValAtom = atom({
    key:'BANNER_KEY',
    default:""
})

export const LocalStoreItems = atom({
    key:'LOCAL_KEY',
    default:[]
})

export const TargetAtom = atom({
    key:'TAR_KEY',
    default:[{data:'',labels:''}]
})

export const LinkedItem = atom({
    key:'LINK_PROD_KEY',
    default:[],
    effects:[
        
    ]
})

export const InvoiceAtom = atom({
    key:'INVOICE_ATOM',
    default:{innumbers:'',orderval:0,orderdate:''}
})

export const SuggestedAtom = atom({
    key: 'SUGGEST_KEY',
    default: []
})

export const BatchAtom = atom({
    key:'BATCH_KEY',
    default:[]
})

export const CampaignProductAtom = atom({
    key: 'CAMPAIGN_KEY',
    default: []
})