import axios from "axios"
import { ApiEndPoints } from "../constants/ApiEndpoints"
import { ApiUrl } from "../constants/Server"

export default{
    getOrderMasterDetails: async function (payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.GET_ORDER_DETAILS,
                method:'post',
                data: payload,
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
        }
    },
    getInvoice: async function (payload) {
        try {
            const response = await axios({
                url: ApiUrl.SERVER_URL + ApiEndPoints.DOWNLOAD_INVOICE,
                method: 'post',
                data: payload,
                responseType: 'arraybuffer',
            })
            return response.data
        } catch (error) {
            console.log("error ", error)
        }
    },
    
    getReturnedItems: async function (payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.GET_RETUREND_ITEMS,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
        }
    },
    confirmCartOrder: async function (payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.ADD_TO_CART_MASTER,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
        }
    },
    insertCartMaster: async function (payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.INSERT_CART_MASTER,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
        }
    },
    getReturnByOrderId: async function(payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.GET_KART_DETAILS_RETURN,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error)
        }
    },
    insertReturn:async function(payload){
        try {
            const response = await axios({
                url:ApiUrl.SERVER_URL+ApiEndPoints.INSERT_RETURN_ITEMS,
                method:'post',
                data:payload
            })
            return response.data
        } catch (error) {
            console.log("error ",error.message)
        }
    },
}
