import { useLocation } from "react-router-dom"
import { Banner } from "../../components/Banner/Banner"
import { UserHeader } from "../../components/UserHeader/UserHeader"
import { BreadCmp } from "../../core/BreadCrump/BreadCmp";
import './MyReturn.css';
import { useEffect, useState } from "react";
import OrderService from "../../services/OrderService";
import { GetSessions } from "../../services/SessionService";
import { ProductReturn } from "../../core/ProductCard/ProductReturn";
import { Footer } from "../../components/Footer/Footer";
import { AMToasterService } from "../../services/ToasterSerivce";
import { Button, Modal } from "rsuite";
import QuickOrderService from "../../services/QuickOrderService";
import { useRecoilValue } from "recoil";
import { BatchAtom, InvoiceAtom } from "../../rec-state/atoms";
import { ProductReturnView } from "../../core/ProductCard/ProductViewReturn";
import { LoaderCheck } from "../../core/Loader/Loader";


export const MyReturn = () => {
    const [dataItem, setDataItem] = useState([])
    const [viewReturns, setViewReturns] = useState([])
    const [tempMap, setTempMap] = useState([])
    const orderDocNo = useLocation()
    const [typeToShow, setTypeToShow] = useState("")
    const [orderId, setOrderId] = useState("")
    const [returnDetails, setReturnDetails] = useState({ totalAmount: 0, invData: '', totalQty: 0 })
    const [amuser, setAMUser] = useState(JSON.parse(GetSessions('AM_USER_RE')))
    const [custAdd, setCustAdd] = useState([])
    const [checkVetur, setCheckVreturn] = useState(false)
    const [batchno, setBatchno] = useState("")
    const [open, setOpen] = useState(false)
    const [netTotal, setNetTotal] = useState(0)
    const handleOpen = () => { setOpen(true); setBatchno("") }
    const handleClose = () => { setOpen(false); handleBulkReturn() }
    const [viewImg, setViewImg] = useState(false)
    const [singleImg, setSingleImg] = useState("")
    const [boxexTotal, setBoxesTotal] = useState(0)
    const getInvoiceValue = useRecoilValue(InvoiceAtom)
    const batchList = useRecoilValue(BatchAtom)
    const [rpolicy, setRpolicy] = useState(false)
    const handlepolicy = () => setRpolicy(true)
    const handleClosePolicy = () => setRpolicy(false)
    const { pathname } = useLocation()

    const handleView = (item) => {
        setViewImg(true)
        setSingleImg(item)
    }
    const handleCloseView = () => setViewImg(false)
    useEffect(() => {
        setTypeToShow(orderDocNo.search.split("?")[2])
        if (orderDocNo.search.split("?")[2] === 'editReturn') {
            let splitReturns = {
                totalAmount: orderDocNo.state.split('-')[0],
                invData: orderDocNo.state.split('-')[2],
                totalQty: orderDocNo.state.split('-')[1]
            }
            setReturnDetails(splitReturns)
            getReturn(orderDocNo.search.split('?')[1])
            setOrderId(orderDocNo.search.split('?')[1])
        } else {
            setCheckVreturn(true)
            getOrderDocById(orderDocNo.search.split('?')[1])
        }
        getCustomerAddress()
    }, [orderDocNo.search])

    const getOrderDocById = async (orderDoc) => {
        
        let payload = {
            OrderCustCode: amuser.CustomerCode,
            OrderDocNo: orderDoc,
            ShowOrderStatusOnly: "0"
        }
        let data = await OrderService.getReturnedItems(payload)
        setViewReturns(data.Data[0])
        setCheckVreturn(false)
    }

    const getReturn = async (docNo) => {
        try {
            setCheckVreturn(true)
            let payload = {
                OrderCustCode: amuser.CustomerCode,
                OrderDocNo: docNo,
            }
            let data = await OrderService.getReturnByOrderId(payload)
            setDataItem(data.Data[0])
            //console.log("return item - ", JSON.stringify(data.Data[0]))
        } catch (error) {
            console.log("Error", error)
        } finally {
            setCheckVreturn(false)
        }
    }
    const getCustomerAddress = async () => {
        let payload = {
            custcode: amuser.CustomerCode
        }
        let data = await QuickOrderService.getCustomerAddress(payload)
        setCustAdd(data.Data[0])
    }
    const returnItem = async (value) => {
        let findItem = tempMap.findIndex((item) => item.CustomerLinkItemCode === value.item.CustomerLinkItemCode);
        const newItem = {
            KartInvoiceNo: value.item.KartInvoiceNo,
            CustomerLinkItemCode: value.item.CustomerLinkItemCode,
            KartDetReturnRequest: '1',
            PcsOrderQty: value.item.PcsOrderQty,
            PcsReturnQty: value.item.PcsReturnQty,
            KartDetReturnBatchNo: value.batch,
            KartDetReturnReason: value.reason,
            KartDetReturnItemImage: value.baseimage,
            CustomerLinkItemName: value.item.CustomerLinkItemName,
            KartAmountIncludeTax: value.totalAmoutnTax,
            NoBoxes: (parseInt(value.item.PcsReturnQty) / value.item.CustomerLinkItemBoxQty) < 1
                ? 1
                : Math.ceil((parseInt(value.item.PcsReturnQty) / value.item.CustomerLinkItemBoxQty))
        };

        setTempMap((prevItem) => {
            const updateItem = [...prevItem];
            if (findItem !== -1) {
                updateItem[findItem] = newItem;
            } else {
                updateItem.push(newItem);
            }

            const boxesTotal = updateItem.reduce((sum, { NoBoxes }) => sum + NoBoxes, 0);
            //const boxesTotal = updateItem.reduce((sum, { PcsOrderQty }) => sum + PcsOrderQty, 0);
            const netTotal = updateItem.reduce((sum, { KartAmountIncludeTax }) => sum + KartAmountIncludeTax, 0);

            setBoxesTotal(boxesTotal);
            setNetTotal(netTotal);

            return updateItem;
        });

        AMToasterService('S', `Add to Return`);
    }

    const removeItemTemp = (codeRM) => {
        setTempMap(prevItem => prevItem.filter((item) => item.CustomerLinkItemCode !== codeRM))
        setTempMap((prevItem) => {
            const updateItem = [...prevItem];
            /*const boxesTotal = updateItem.reduce((sum, { NoBoxes }) => sum + NoBoxes, 0);*/
            const boxesTotal = updateItem.reduce((sum, { PcsOrderQty }) => sum + PcsOrderQty, 0);
            const netTotal = updateItem.reduce((sum, { KartAmountIncludeTax }) => sum + KartAmountIncludeTax, 0);

            // Update totals in state
            setBoxesTotal(boxesTotal);
            setNetTotal(netTotal);

            return updateItem; // Return the updated state
        });
        AMToasterService('S', `Item removed from return`)
    }
    function getImageContentType(item) {
        let extension;
        const lowerCase = item.KartDetReturnItemImage.toLowerCase();

        if (lowerCase.split(',')[0].includes("png")) {
            extension = "png";
        } else if (
            lowerCase.split(',')[0].includes("jpg") ||
            lowerCase.split(',')[0].includes("jpeg")
        ) {
            extension = "jpg";
        } else {
            extension = "pdf";
        }

        const imageContentType =
            extension === "pdf" ? `application/${extension}` : `image/${extension}`;

        return imageContentType;
    }

    const handleBulkReturn = async () => {
        let newMap = tempMap.map((item) => ({
            KartInvoiceNo: item.KartInvoiceNo,
            CustomerLinkRgaNo: dataItem[0].OrderDocNo,
            KartDetReturnDocNo: dataItem[0].OrderDocNo,
            CustomerLinkItemCode: item.CustomerLinkItemCode,
            KartDetReturnQty: item.PcsReturnQty,
            KartDetReturnBatchNo: item.KartDetReturnBatchNo,
            KartDetReturnReason: item.KartDetReturnReason,
            KartDetReturnDate: new Date().toISOString(),
            KartDetReturnStatus: 'Return Initiated',
            KartDetReturnItemImage: item.KartDetReturnItemImage,
            KartDetReturnBoxQty: item.NoBoxes,
            PcsOrderQty: item.PcsOrderQty,
            PcsReturnQty: item.PcsReturnQty,
            ReturnItemAmount: item.KartAmountIncludeTax,
            CustomerLinkItemName: item.CustomerLinkItemName,
            ImageContentType: getImageContentType(item)
        }))
        
        let payload = {
            items: newMap,
            //for email config
            CustomerName: amuser.CustomerName,
            custaddress: custAdd[0].AddressDetails,
            CustomerPhone: amuser.CustomerMobileNo,
            OrderDocNo: orderId,
            InvDate: returnDetails.invData,
            InvAmount: returnDetails.totalAmount,
            InvBoxQty: returnDetails.BoxOrderQty,
            CustomerContactName: amuser.CustContactName,
            CustomerContactDesignation: amuser.DesignationName,
            NoOfItemsReturn: tempMap.length,
            NoOfBoxesReturn: parseInt(boxexTotal),
            ReturnTotalAmount: netTotal
        }

        let data = await OrderService.insertReturn(payload)
        AMToasterService('S', `${tempMap.length} Return Successful`)
        getReturn(orderId)
        setTempMap([])
        setBoxesTotal(0)
        setNetTotal(0)
    }
    if (checkVetur) {
        return <LoaderCheck isLoad={checkVetur} text={'View Returns Loading...'}></LoaderCheck>
    }

    return (
        <div>
            <Banner
                text={""}
            />
            <UserHeader />
            <BreadCmp link={pathname} />
            <div className="container-fluid">
                <div className="rowMyeTrun BackColorGrey p-3 align-items-center">
                    <div style={{ width: 300 }}>
                        <p>Customer Details:</p>
                        <p>{custAdd.length !== 0 ? custAdd[0].AddressDetails : ""}</p>
                        <p>Phone Number: {amuser.CustomerMobileNo}</p>
                    </div>
                    <div>
                        <div className="BackBorderColor"></div>
                    </div>
                    <div>
                        <p className="">
                            Manager:&nbsp;{amuser.CustomerSalesManager}<br />
                            {viewReturns.length !== 0 ? (
                                <>
                                    RGA No.:&nbsp;{viewReturns[0].ReturnRGANo}<br /> 
                                    RGA Date:&nbsp;{viewReturns[0].ReturnRGADate.split('T')[0].split('-')[2] + "-" + viewReturns[0].ReturnRGADate.split('T')[0].split('-')[1] + "-" + viewReturns[0].ReturnRGADate.split('T')[0].split('-')[0]}<br />
                                    {/* Application Status:{JSON.stringify(viewReturns[0].ReturnStatus)}<br /> */}
                                </>
                            ) : (
                                <>
                                    RGA No.:&nbsp;<br />
                                    RGA Date:&nbsp;<br />
                                    Application Status:<br />
                                </>
                            )}
                        </p>
                    </div>
                    <div className="BackBorderColor"></div>
                    <div style={{ width: 300 }}>
                        <p className="">
                            Invoice No / Date / Value: <br />
                            {getInvoiceValue.innumbers === "" ? (
                                <>Loding...</>
                            ) : (
                                <>
                                    {getInvoiceValue.innumbers} / {getInvoiceValue.orderdate.replaceAll("/", "-")} / {getInvoiceValue.orderval.toFixed(2)} <br />
                                </>
                            )}
                            <br />
                            <p onClick={handlepolicy} style={{ color: '#3D97EA', cursor: 'pointer' }}>Return Policy</p>
                        </p>
                    </div>
                </div>
                {typeToShow === "editReturn" ? (
                    <>
                        {dataItem.length !== 0 ? (
                            <div className="row">
                                <div className="col-md-12 mt-2">
                                    <h5 className="mb-3">Items in Invoice</h5>
                                    {dataItem.map((item, index) => (
                                        <ProductReturn batchList={batchList} onEmitValue={returnItem} item={item} key={index}></ProductReturn>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="text-center p-4">No data found</div>
                        )}
                        <table className="table feedBack">
                            <thead>
                                <tr>
                                    <th>Product Name</th>
                                    <th>Return Quantity (In Pcs)</th>
                                    <th>Batch Number</th>
                                    <th>Reason For Return</th>
                                    <th>Amount (Incl. Of Taxes)</th>
                                    <th>Images</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tempMap.map((item) => (
                                    <tr key={item.CustomerLinkItemCode}>
                                        <td>{item.CustomerLinkItemName}</td>
                                        <td>{item.PcsReturnQty}</td>
                                        <td>{item.KartDetReturnBatchNo}</td>
                                        <td>{item.KartDetReturnReason}</td>
                                        <td>{item.KartAmountIncludeTax}</td>
                                        <td>
                                            <Button onClick={() => handleView(item.KartDetReturnItemImage)} appearance="default">
                                                <i class="fa-regular fa-eye text-primary"></i>
                                            </Button>
                                        </td>
                                        <td>
                                            <Button appearance="default" color="red" onClick={() => removeItemTemp(item.CustomerLinkItemCode)}>
                                                <i className="fa-solid fa-trash text-danger"></i>
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {dataItem.length !== 0 &&
                            <div className="d-flex justify-content-between align-items-center mb-3 shadow-lg p-2">
                                <div className="ReturnNotTle">
                                <h6>No. of Items : {tempMap.length}</h6>
                                    {/* <h6>No. of Boxes : {boxexTotal}</h6> */}
                                    <h6>Net Return Amount : {netTotal} </h6>
                                </div>
                                <div>
                                    <button onClick={handleOpen} className="placeReturnButton">Place Return</button>
                                </div>
                            </div>}
                    </>
                ) : (
                    <div className="row mt-5 mb-5">
                        {viewReturns.length === 0 && <div className="text-center">No data found</div>}
                        {viewReturns.map((item, index) => (
                            <div className="col-md-6">
                                <ProductReturnView item={item} key={index}></ProductReturnView>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <Footer />
            <Modal style={{ marginTop: '4rem' }} backdrop="static" role="alertdialog" open={viewImg} onClose={handleCloseView} size="xs">
                <Modal.Body className="p-2">
                    <img className="img-fluid" src={singleImg} alt="" />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCloseView} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal backdrop="static" content="center" role="alertdialog" open={open} onClose={handleClose} size="xs">
                <Modal.Body className="p-2">
                    {tempMap.length > 0 ? (
                        <span>Do you want to proceed with the return ?</span>
                    ) : (
                        <span>Select one quantity</span>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={tempMap.length > 0 ? false : true} onClick={handleClose} appearance="primary">
                        Continue
                    </Button>
                    <Button onClick={() => setOpen(false)} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal backdrop="static" style={{ marginTop: '2rem' }} content="center" open={rpolicy} onClose={handleClosePolicy} size="md">
                <Modal.Header>
                    <h5>Return Goods Policy</h5>
                </Modal.Header>
                <Modal.Body className="p-2 ReturnPolicyClass">
                    <h2>1. Authorization</h2>
                    <ol>
                        <li>All products that any dealer/customer/end user (<b>Customer</b>) wishes to return, can only be returned to Amaryllis Healthcare Pvt. Ltd (<b>Amaryllis Healthcare</b>) on an affirmative authorization by Amaryllis Healthcare and in accordance with any contract/agreement/invoice terms entered into with Amaryllis Healthcare.</li>
                        <li>A product may only be returned after a Return Goods Authorization (<b>RGA</b>) has been provided by Amaryllis Healthcare in writing for such product. Each RGA is only valid for a period of 30 days from the date of its issuance. In the event an RGA lapses, Customer will have to apply for a fresh RGA. RGA's may be obtained by writing to its customer service at <a href="mailto:info@amaryllisheathcare.com">info@amaryllisheathcare.com</a>.</li>
                        <li>Upon receipt of the RGA, subject to the inspection of the products and confirmation from Amaryllis Healthcare that such products are in salable condition and are suitable for re-stocking, non-defective products may be returned to Amaryllis.</li>
                        <li>Issuance of an RGA number does not guarantee credit. Credit issuance is dependent on confirmed receipt/review of returned products and is subject to the other terms of this policy.</li>
                        <li>In the event any of the products supplied by Amaryllis Healthcare to the Customer are ascertained to be damaged, the Customer is required to obtain an authorization from Amaryllis Healthcare for return of such damaged products.</li>
                        <li>Any products that have been returned despite not having received an RGA by Amaryllis Healthcare, or if found to be defective and suitable for re-stocking (despite the Customer having declared to the contrary) may be sent back to the Customer at their own expense or destroyed by Amaryllis Healthcare at its sole discretion. The return of the Products will be subject to additional charges without any credit being issued to Customer.</li>
                        <li>This policy is subject to any contract/agreement/invoice terms that may be entered into between the Customer and Amaryllis Healthcare.</li>
                        <li>Do not return any product before receiving confirmation of RGA from our team. In case any product is returned without such confirmation, we do not guarantee any credit or refund.</li>
                    </ol>

                    <h2>2. Policy with regard to Damaged/Defective products</h2>

                    <h3>2.1. Damaged Products</h3>
                    <ol>
                        <li>Any damages or shortages in the products delivered by Amaryllis Healthcare must be endorsed by the Customer on the carrier's freight bill or bill of lading and be countersigned by the carrier. The damaged products must remain in the original carton, in the event any subsequent inspection is required by the carrier and/or Amaryllis Healthcare.</li>
                        <li>The Customer shall notify Amaryllis Healthcare of any damage or product shortages identified within 3 months of receipt of such product. Exceeding 3 months from the date of invoice, no damaged or shorted products will be considered valid for RGA or returnable.</li>
                        <li>The Customer shall notify any damage to products and/or shortages to Amaryllis Healthcare in writing to Amaryllis Healthcare Customer Service at <a href="mailto:info@amaryllisheathcare.com">info@amaryllisheathcare.com</a>.</li>
                        <li>Amaryllis Healthcare shall not be liable for, nor be under any obligation to accept return, process credit or arrange for product replacement in the event the carrier's freight bill or bill of loading is not counter-signed by the carrier as required, or if there is any delay, over and above 3 months prescribed, in notifying Amaryllis Healthcare of any damage or shortages in products supplied.</li>
                        <li>It is clarified that Amaryllis Healthcare shall not be liable for any damage to the product in transit to the Customer or caused on account of any actions/inactions by the carrier/transportation agency.</li>
                    </ol>

                    <h3>2.2. Defective Products</h3>
                    <ol>
                        <li>The Customer shall notify Amaryllis Healthcare in writing of any manufacturing defect in any product supplied within 3 months of receipt of such product. Amaryllis Healthcare shall not be liable in the event there is any delay by the Customer in notifying any defect.</li>
                        <li>Upon Amaryllis Healthcare acknowledging the defect in the product supplied, the Customer may return such products at Amaryllis Healthcare's expenses for replacement.</li>
                    </ol>

                    <h3>2.3. Products Shipped in Error by Amaryllis Healthcare</h3>
                    <ol>
                        <li>The Customer shall notify Amaryllis Healthcare of any shipping errors or disputes within two (2) business days of receipt of the product.</li>
                        <li>Products shipped in error by Amaryllis Healthcare are freely returnable for full credit, provided that such returns are made within thirty (30) days of receipt of such products.</li>
                    </ol>

                    <h2>3. Conditions for Return</h2>
                    <ol>
                        <li>The following products are not liable to be returned to Amaryllis Healthcare by the Customers:</li>
                        <ol>
                            <li>Hazardous products, or products containing hazardous materials.</li>
                            <li>Products made to customer specifications.</li>
                            <li>Products not liable to be returned as per the contract/agreement/invoice terms.</li>
                            <li>Products returned in altered or damaged packaging, or in packaging other than original packaging.</li>
                            <li>Refrigerated products, or products requiring refrigeration.</li>
                            <li>Products in unsalable units of measure where product cannot be resold.</li>
                            <li>When return of such products is prohibited by under law.</li>
                            <li>Products with less than 3 months of shelf life remaining, based on expiration dates, can be sent back for resterilization. Transportation and repackaging/resterilization costs will be charged additionally to the customer.</li>
                            <li>Third party vendor products that require a vendor return authorization. These Products will be subject to the vendor's return policy and applicable fees.</li>
                        </ol>
                    </ol>

                    <h2>4. Return Procedure</h2>
                    <p>After obtaining an RGA, each return order must include the following information:</p>
                    <ol>
                        <li>Customer's name, address and account number.</li>
                        <li>RGA number.</li>
                        <li>Original PO number or original Amaryllis Healthcare invoice number.</li>
                        <li>Lot number and expiration dates where applicable.</li>
                        <li>Proof of Photos to be attached.</li>
                    </ol>

                    <h2>5. Limitation of Liability</h2>
                    <p>Amaryllis Healthcare shall only be liable to the extent of the price of the products supplied to the Customer.</p>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClosePolicy} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )

}