import { Routes, Route } from "react-router-dom";
import { HomePage } from "../HomePage/HomePage"
import { LoginPage } from "../LoginPage/LoginPage"
import './LayoutPage.css'
import { UserPage } from "../UserPage/UserPage";
import { Dashboard } from "../Dashboard/Dashboard";
import { Categories } from "../Categories/Categories";
import { NewProduct } from "../NewProducts/NewProducts";
import { Myorder } from "../MyOrders/Myorder";
import { FeaProducts } from "../FeaProducts/FeaProducts";
import { QuickOrder } from "../QuickOrder/QuickOrder";
import { FastMovPro } from "../FastMovProducts/FastMovPro";
import { Cart } from "../Cart/Cart";
import { PaymentDetails } from "../MyProfile/PaymentDetails";
import { KycDetails } from "../KycDetails/KycDetails";
import { AuthProvider } from "../../guard/AuthGuard";
import { ProtectedRoute } from "../../guard/ProtectRoute";
import { ProductDetailsPage } from "../ProductsDet/ProductDet";
import { MyReturn } from "../MyReturn/MyReturn";
import { ReorderProducts } from "../ReOrderProd/ReOrderProd";
import { SpecialOfferProd } from "../SpecialOffer/SpecialOffer";
import { FeedbaackPage } from "../FeedBack/FeedbackPage";
import { SuggestedProducts } from "../SuggestedProducts/SuggestedProducts";
import { Contactus } from "../../components/Contactus/Contact";
import { MissedProducts } from "../MissedPage/MissedProducts";
import { NotfoundPage } from "../Notfound/Notfound";
import { useEffect, useState } from "react";
import { CampaignProducts } from "../CampaignProducts/CampaignProducts";


export const LayoutPage = () => {

    const [netOnline, setNetOnline] = useState(navigator.onLine)

    /**
     * function used to check the network is there or not 
     * if network is offline it will show the 404 not found page otherwise current route will show
     */
    useEffect(() => {
        const handleOnline = () => setNetOnline(true);
        const handleOffline = () => setNetOnline(false);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);


    return (
        <div className="LayoutContainer">
            {
                netOnline ? (
                    <AuthProvider>
                        <Routes>
                            <Route path="/" element={<HomePage />} />
                            <Route path="login" element={<LoginPage />} />
                            <Route path="userHome" element={<ProtectedRoute><UserPage /></ProtectedRoute>} />
                            <Route path="dashBoard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                            <Route path="categories" element={<ProtectedRoute><Categories /></ProtectedRoute>} />
                            <Route path="newProduct" element={<ProtectedRoute><NewProduct /></ProtectedRoute>} />
                            <Route path="myOrders" element={<ProtectedRoute><Myorder /></ProtectedRoute>} />
                            <Route path="feaProduct" element={<ProtectedRoute><FeaProducts /></ProtectedRoute>} />
                            <Route path="specialOffer" element={<ProtectedRoute><SpecialOfferProd /></ProtectedRoute>} />
                            <Route path="quickOrder" element={<ProtectedRoute><QuickOrder /></ProtectedRoute>} />
                            <Route path="fastMovPro" element={<ProtectedRoute><FastMovPro /></ProtectedRoute>} />
                            <Route path="cart" element={<ProtectedRoute><Cart /></ProtectedRoute>} />
                            <Route path="paymentDetails" element={<ProtectedRoute><PaymentDetails /></ProtectedRoute>} />
                            <Route path="kycDetails" element={<ProtectedRoute><KycDetails /></ProtectedRoute>} />
                            <Route path="productDetails/:productid" element={<ProtectedRoute><ProductDetailsPage /></ProtectedRoute>} />
                            <Route path="myReturns" element={<ProtectedRoute><MyReturn /></ProtectedRoute>} />
                            <Route path="reOrderProducts" element={<ProtectedRoute><ReorderProducts /></ProtectedRoute>} />
                            <Route path="feedBacks" element={<ProtectedRoute><FeedbaackPage /></ProtectedRoute>} />
                            <Route path="contactus" element={<Contactus />} />
                            <Route path="missedProducts" element={<ProtectedRoute><MissedProducts /></ProtectedRoute>} />
                            <Route path="suggestedProducts/:productid" element={<ProtectedRoute><SuggestedProducts /></ProtectedRoute>} />
                            <Route path="campaignProduct" element={<ProtectedRoute><CampaignProducts /></ProtectedRoute>} />
                        </Routes>
                    </AuthProvider>
                ) : (
                    <NotfoundPage />
                )
            }
        </div>
    )
}