import { Banner } from "../../components/Banner/Banner"
import { Footer } from "../../components/Footer/Footer"
import { UserHeader } from "../../components/UserHeader/UserHeader"
import notfound from "../../assets/notfound.png"
import { BreadCmp } from "../../core/BreadCrump/BreadCmp"
import { Link, useLocation } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { ReOrderAtom } from "../../rec-state/atoms"
import { useEffect, useState } from "react"
import { GetSessions } from "../../services/SessionService"
import QuickOrderService from "../../services/QuickOrderService"
import { AMToasterService } from "../../services/ToasterSerivce"
/*import { Pagination } from "rsuite"*/
import { Pagination } from 'react-bootstrap';
import HomeService from "../../services/HomeService"


export const ReorderProducts = () => {
    const [amuser, setAMUser] = useState(JSON.parse(GetSessions('AM_USER_RE')))
    const reOrderList = useRecoilValue(ReOrderAtom)
    const [activePage, setActivePage] = useState(1);
    const [reoProd, setReoProd] = useState([])

    useEffect(() => {
        if(reOrderList.length===0){
            getReorderProd()
        }else{
            setReoProd(reOrderList)
        }
    }, []);

    const itemsPerPage = 12;
    const startIndex = (activePage - 1) * itemsPerPage;
    //const currentItems = reoProd.slice(startIndex, startIndex + itemsPerPage);
    const currentItems = Array.isArray(reoProd) && reoProd.length > 0
        ? reoProd.slice(startIndex, startIndex + itemsPerPage)
        : [];

    const getReorderProd = async () => {
        try {
            let payload = {
                custcode: amuser.CustomerCode,
                itemgroupid: 0
            }
            let data = await HomeService.getLatestReorderProd(payload)
            if (data !== undefined) {
                setReoProd(data.Data[1])
            }
        } catch (error) {
            
        }finally{
            console.log("reorder products executed")
        }
    }

    const CustomPagination = ({ totalItems, itemsPerPage, activePage, setActivePage }) => {
        const totalPages = Math.ceil(totalItems / itemsPerPage);
        const startItem = (activePage - 1) * itemsPerPage + 1;
        const endItem = Math.min(activePage * itemsPerPage, totalItems);
        const [pageRange, setPageRange] = useState([1, Math.min(20, totalPages)]);

        const handlePageChange = (pageNumber) => {
            setActivePage(pageNumber);
            // Update the page range when the active page changes
            const newStartPage = Math.floor((pageNumber - 1) / 20) * 20 + 1;
            const newEndPage = Math.min(newStartPage + 19, totalPages);

            setPageRange([newStartPage, newEndPage]);
        };

        const handlePrev = () => {
            if (pageRange[0] > 1) {
                const newStartPage = pageRange[0] - 20;
                const newEndPage = newStartPage + 19;
                setPageRange([newStartPage, Math.min(newEndPage, totalPages)]);
                setActivePage(newStartPage);
            }
        };

        const handleNext = () => {
            if (pageRange[1] < totalPages) {
                const newStartPage = pageRange[1] + 1;
                const newEndPage = newStartPage + 20;
                setPageRange([newStartPage, Math.min(newEndPage, totalPages)]);
                setActivePage(newStartPage);
            }
        };

        const handleStart = () => {
            setPageRange([1, Math.min(20, totalPages)]);
            setActivePage(1);
        };

        const handleLast = () => {
            const newStartPage = Math.floor((totalPages - 1) / 20) * 20 + 1;
            const newEndPage = totalPages;
            setPageRange([newStartPage, newEndPage]);
            setActivePage(newStartPage);
        };

        return (
            <div className="row justify-content-center">
                <div className="col-auto">
                    <div className="pagination-info text-center mb-3">
                        Showing {startItem} - {endItem} of {totalItems} items
                    </div>
                    <div className="pagination-container">
                        <Pagination>
                            <Pagination.First onClick={handleStart} />
                            <Pagination.Prev onClick={handlePrev} />
                            {Array.from({ length: pageRange[1] - pageRange[0] + 1 }, (_, i) => pageRange[0] + i).map((page) => (
                                <Pagination.Item
                                    key={page}
                                    active={page === activePage}
                                    onClick={() => handlePageChange(page)}
                                    className={`pagination-item ${page === activePage ? 'active' : ''}`}
                                >
                                    {page}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next onClick={handleNext} />
                            <Pagination.Last onClick={handleLast} />
                        </Pagination>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            <Banner
                text={""}
            />
            <UserHeader />
            <BreadCmp link={useLocation().pathname} />
            <div className="container-fluid mt-2 mb-4 fade-image-className">
                <div className="row mt-2 mb-2">
                    <div className='col-md-12'>
                        <h3>Reorder Products</h3>
                    </div>
                </div>
                <div className="row">
                    {currentItems.map((item, index) => (
                        <div className='col-lg-3 mt-2' key={index}>
                            <div className='ProductCardOne'>
                                <Link to={`/productDetails/${item.CustomerLinkItemCode}`} style={{ cursor: 'pointer' }}>
                                    <img src={item.CustomerLinkItemImageData != "" ? `data:image/png;base64,${item.CustomerLinkItemImageData}` : notfound} className='img-fluid' alt="" />
                                </Link>
                                <div className='ProductContent'>
                                    <div className='ProductTitle'>
                                        <div>{item.CustomerLinkItemName}</div>
                                        <div>Product&nbsp;Code:&nbsp;{item.CustomerLinkItemCode}</div>
                                        <div className="pb-2"><strike><span classname="RuppeFont"><span className="RuppeFont">₹</span></span>{item.CustomerLinkItemMRP}</strike>&nbsp;<span classname="RuppeFont"><span className="RuppeFont">₹</span></span>{item.CustomerLinkItemDP}</div>
                                    </div>
                                    <div className='ProductBtn'>
                                        <button type='button'>Add&nbsp;
                                            <svg width="16" height="16" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.29102 9.91663H27.4014C27.8089 9.91664 28.2119 9.9996 28.5843 10.1602C28.9568 10.3207 29.2905 10.5553 29.5639 10.8488C29.8373 11.1423 30.0444 11.4882 30.1718 11.8642C30.2992 12.2402 30.3441 12.638 30.3035 13.0319L29.4285 21.5319C29.3565 22.231 29.0197 22.8792 28.4835 23.3507C27.9472 23.8222 27.2497 24.0833 26.5264 24.0833H12.5993C11.9248 24.0836 11.2711 23.8567 10.7494 23.4413C10.2277 23.026 9.8703 22.4478 9.7381 21.8053L7.29102 9.91663Z" stroke="white" strokeWidth="1.5" strokeLinejoin="round" />
                                                <path d="M7.29102 9.91667L6.10977 5.32242C6.03077 5.01607 5.84865 4.74415 5.59236 4.54985C5.33607 4.35554 5.02029 4.25001 4.69518 4.25H2.91602M11.666 29.75H14.5827M23.3327 29.75H26.2493" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <CustomPagination
                        totalItems={reoProd.length}
                        itemsPerPage={itemsPerPage}
                        activePage={activePage}
                        setActivePage={setActivePage}
                    />
                    {/*<Pagination size="md" total={reoProd.length} limit={itemsPerPage} activePage={activePage} onChangePage={setActivePage} />*/}
                    {reoProd.length == 0 && <div className="text-center p-5">No data found</div>}
                </div>
            </div>
            <Footer />
        </div>
    )
}