import { useLocation } from "react-router-dom"
import { Banner } from "../../components/Banner/Banner"
import { UserHeader } from "../../components/UserHeader/UserHeader"
import { BreadCmp } from "../../core/BreadCrump/BreadCmp"
import { Checkbox } from "rsuite"
import './KycDetails.css'
import { AmsButton } from "../../core/Button/Button"

export const KycDetails = () => {
    return (
        <div>
            <Banner
                text={""}
            />
            <UserHeader />
            <BreadCmp link={useLocation().pathname} />
            <div className='container-fluid fade-image-class'>
                <div className='row mb-4'>
                    <h3 className='mt-3'>KYC Details</h3>
                    <div className="col-md-4">
                        <label>Name of The Proprietor / Director</label>
                        <input type="text" className="form-control" />
                    </div>
                    <div className="col-md-4">
                        <label></label>
                        <input type="text" className="form-control" />
                    </div>
                    <div className="col-md-4">
                        <label>GST No.</label>
                        <input type="text" className="form-control" />
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-4">
                        <label>Name Of The Company</label>
                        <input type="text" className="form-control" />
                    </div>
                    <div className="col-md-4">
                        <label>Nature Of Current Business</label>
                        <input type="text" className="form-control" />
                    </div>
                    <div className="col-md-4">
                        <label>PAN Card No. (Individual or Company)</label>
                        <input type="text" className="form-control" />
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-4">
                        <div className="row d-flex align-items-center">
                            <div className="col-md-8">Total Experience In This Business</div>
                            <div className="col-md-4">
                                <input type="text" placeholder="Years" className="form-control" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="row d-flex align-items-center">
                            <div className="col-md-6">Office Space Available</div>
                            <div className="col-md-3">
                                <Checkbox>Yes</Checkbox>
                            </div>
                            <div className="col-md-3">
                                <Checkbox>No</Checkbox>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <label>Udyam Aadhar / MSME</label>
                        <input type="text" className="form-control" />
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-4">
                        <div className="row d-flex align-items-center">
                            <div className="col-md-12">
                                Annual Turnover Last 3 years
                            </div>
                            <div className="col-md-4">
                                <input type="text" placeholder="In Lakhs" className="form-control" />
                            </div>
                            <div className="col-md-4">
                                <input type="text" placeholder="In Lakhs" className="form-control" />
                            </div>
                            <div className="col-md-4">
                                <input type="text" placeholder="In Lakhs" className="form-control" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <label>Focus Specialties</label>
                        <input type="text" placeholder="Ortho/Gynec/Cardiac/CSSD/General Surgery" className="form-control" />
                    </div>
                    <div className="col-md-4">
                        <label>IT Returns Of Last 3 Years</label>
                        <input type="text" className="form-control" />
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-4">
                        <label>List Of Companies And Products</label>
                        <textarea className="form-control" placeholder="Product Name"></textarea>
                    </div>
                    <div className="col-md-4">
                        <label>Top 10 Customers In Private Sector</label>
                        <textarea className="form-control" placeholder="Apollo, Narayana, Govt Hospital"></textarea>
                    </div>
                    <div className="col-md-4">
                        <label>Top 10 Customers In Govt. Sector</label>
                        <textarea className="form-control" placeholder="Sarojini Hospital, Hyderabad"></textarea>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-6">
                        <div className="kycShadowWrap">
                            <div className="MyProfileSecondStrip">
                                <h5>Billing Address</h5>
                                <p><i class="fa-solid fa-plus"></i></p>
                            </div>
                            <div className="p-2">
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Modi eius repellendus ipsum ipsam fugiat sint ullam expedita, error nulla. Consequatur provident aliquam nisi iste nobis corporis doloribus a itaque modi!</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="kycShadowWrap">
                            <div className="MyProfileSecondStrip">
                                <h5>Shipping Address</h5>
                                <p><i class="fa-solid fa-plus"></i></p>
                            </div>
                            <div className="p-2">
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Modi eius repellendus ipsum ipsam fugiat sint ullam expedita, error nulla. Consequatur provident aliquam nisi iste nobis corporis doloribus a itaque modi!</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="text-center">
                    <AmsButton 
                    buttonwid={'20%'} 
                    textcolor={'#fff'} 
                    label={'Save Changes'} 
                    color={'#3D97EA'}></AmsButton>
                    </div>
                </div>
            </div>
        </div>
    )
}