import { useSetRecoilState } from "recoil";
import { ApiEndPoints } from "../constants/ApiEndpoints";
import { BannerValAtom } from "../rec-state/atoms";
import { ApiUrl } from "../constants/Server";
import axios from "axios";

export const useBannerService = () => {
  const setBannerState = useSetRecoilState(BannerValAtom);

  const getBannerValue = async (payload) => {
    try {
      const response = await axios({
        url: ApiUrl.SERVER_URL + ApiEndPoints.CUSTOMER_OFFER_PRODCUTS,
        method: "post",
        data: payload,
      });

      let newData = response.data.Data[1]
        .slice(0, 3)
        .map((item) =>
          item.ItemPartnerOffer !== ""
            ? item.ItemPartnerOffer + " " + item.CustomerLinkItemName
            : ""
        )
        .toString()
        .replaceAll(",", "\t\t\t");

      setBannerState(newData);
    } catch (error) {
      console.log("Error fetching offer products banner text", error);
    }
  };

  return { getBannerValue };
};
