import { createContext, useContext, useMemo } from "react";
import { useLocalStorage } from "./CheckMid";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();


export const AuthProvider = ({children}) => {
    const [user,setUser] = useLocalStorage('AM_USER_RE',null)
    const navigate = useNavigate()

    const login = async (data) =>{
        setUser(data[0])
        navigate('/userHome')
    }

    const logout = () => {
        setUser(null);
        localStorage.removeItem('AM_POP_FLAG')
        navigate("/", { replace: true });
      };

      const value = useMemo(
        () => ({
          user,
          login,
          logout, 
        }),
        [user]
      );
      return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () =>{
    return useContext(AuthContext);
}