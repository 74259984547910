import { Banner } from "../../components/Banner/Banner"
import { Footer } from "../../components/Footer/Footer"
import { UserHeader } from "../../components/UserHeader/UserHeader"
import sp1 from '../../assets/special/sp1.png'
import sp2 from '../../assets/special/sp2.png'
import sp3 from '../../assets/special/sp3.png'
import sp4 from '../../assets/special/sp4.png'
import sp5 from '../../assets/special/sp5.png'
import { BreadCmp } from "../../core/BreadCrump/BreadCmp"
import { useLocation } from "react-router-dom"


export const FastMovPro = () => {
    const dataList = [
        { itemName: 'FACE MASK 3 PLY BLUE TIE - FM021', itemImage: sp1, itemCode: '11630', itemRate: '<span className="RuppeFont">₹</span>77 60% Off' },
        { itemName: 'BOUFFANT CAP 18IN BLUE - BC005', itemImage: sp2, itemCode: '11630', itemRate: '<span className="RuppeFont">₹</span>77 60% Off' },
        { itemName: 'SURGEON CAP BLUE - M', itemImage: sp3, itemCode: '11630', itemRate: '<span className="RuppeFont">₹</span>77 60% Off' },
        { itemName: 'SURGEON GOWNWRAP AROUND - 11468', itemImage: sp4, itemCode: '11630', itemRate: '<span className="RuppeFont">₹</span>77 60% Off' },
        { itemName: 'FACE MASK 3PLY BLUE LOOP - FM023', itemImage: sp5, itemCode: '11630', itemRate: '<span className="RuppeFont">₹</span>77 60% Off' },
        { itemName: 'FACE MASK 3PLY BLUE LOOP - FM023', itemImage: sp5, itemCode: '11630', itemRate: '<span className="RuppeFont">₹</span>77 60% Off' },
    ]
    return (
        <div>
            <Banner
                text={""}
            />
            <UserHeader />
            <BreadCmp link={useLocation().pathname}/>
            <div className="container-fluid mt-2 mb-4 fade-image-class">
                <div className="row mt-2">
                    <div className="col-md-9">
                        <div className="CategoriesHead">
                            <h5>All Products</h5>
                            <h5>Consumables</h5>
                            <h5>Sheet</h5>
                            <h5>Kit</h5>
                            <h5>Gowns</h5>
                            <h5>Accessories</h5>
                            <h5>Wrap Sheet</h5>
                        </div>
                    </div>
                    <div className="col-md-3 CategoriesSearch">
                        <input type="text" className="form-control" />
                        <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                    <div className='col-md-12'>
                        <h3>Fast Moving Products</h3>
                    </div>
                </div>

                <div className="row mt-5">
                    {dataList.map((item, index) => (
                        <div className='col-md-2' key={index}>
                            <div className='ProductCardOne'>
                                <img src={item.itemImage} className='img-fluid' alt="" />
                                <div className='ProductContent'>
                                    <div className='ProductTitle'>
                                        <div>{item.itemName}</div>
                                        <div>ProductCode:{item.itemCode}</div>
                                        <div className="pb-2"><strike><span className="RuppeFont">₹</span>100</strike>&nbsp;{item.itemRate}</div>
                                        <button type='button'>Add&nbsp;<i className="fa-solid fa-cart-shopping"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}