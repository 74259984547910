import addICon from '../../assets/quick/addicon.png'
import notFound from "../../assets/notfound.png"
import { useEffect, useState } from 'react'
import { Button, Checkbox, Input } from 'rsuite'
import { GetSessions } from '../../services/SessionService'
import formatIndianNumber from '../../services/FormatNumber'
import { Modal } from "rsuite"
import { AmsButton } from "../../core/Button/Button"
import UserService from "../../services/UserService"
import { AMToasterService } from "../../services/ToasterSerivce"
import "../../pages/QuickOrder/QuickOrder.css"

export const ProdcutCardSearch = ({ item, onSearchCart }) => {
    const [countQty, setCountQty] = useState(1)
    const [tenderCheck, setTenderCheck] = useState(false)
    const [firstOrderCheck, setFirstOrderCheck] = useState(false)
    const [amuser, setAMUser] = useState(JSON.parse(GetSessions('AM_USER_RE')))
    const handleQuoteClick = (itemCode, linkitem) => {
        setUsProduct(itemCode + " ------ " + linkitem);
    };
    const [usProduct, setUsProduct] = useState("")
    const [open, setOpen] = useState(false)
    const handleClose = () => { setOpen(false); setUsProduct("") }
    const handleOpen = () => setOpen(true);
    const [usName, setUsName] = useState("")
    const [usPhone, setUsPhone] = useState("")
    const [usEmail, setUsEmail] = useState("")
    const [usRemark, setUsRemark] = useState("")
    const [error, setError] = useState(false)

    useEffect(() => {
        if (usProduct) {
            handleOpen();
        }
    }, [usProduct]);

    useEffect(() => {
        setInShow(false)
    }, [])
    const onEmitItemValue = (type,typeSpecific) => {
        const updateItem = {
            KartDetId:item.KartDetId,
            KartDetCustomerCode:amuser.CustomerCode,
            KartDetItemCode:item.CustomerLinkItemCode,
            KartDetItemName:item.CustomerLinkItemName,
            KartDetItemDP:item.CustomerLinkItemDP,
            KartDetTaxPer:item.CustomerLinkItemTaxPer,
            KartDetBoxQty:item.CustomerLinkItemBoxQty,
            KartDetQtyInBoxes:countQty,
            KartDetOrderQty:countQty * item.CustomerLinkItemBoxQty,
            KartDetOrderVal:(countQty * item.CustomerLinkItemBoxQty) * item.CustomerLinkItemDP,
            KartDetTaxAmount:((countQty * item.CustomerLinkItemBoxQty) * item.CustomerLinkItemDP) * item.CustomerLinkItemTaxPer/100 ,
            KartDetTotalAmount:((countQty * item.CustomerLinkItemBoxQty) * item.CustomerLinkItemDP) + ((countQty * item.CustomerLinkItemBoxQty) * item.CustomerLinkItemTaxPer/100),
            KartDetRemarks: reMark,
            KartDetTenderOrder: tenderCheck ? 1 : 0,
            KartDetFirstOrder: firstOrderCheck ? 1 : 0
        }
        onSearchCart({Data:updateItem, param:type, spec:typeSpecific})
    };

    const countChgQty = (type) => {
        switch (type) {
            case 'ADD':
                setCountQty((e) =>   e + 1)
                // onEmitItemValue('A','ADDONE')
                break;
            case 'SUB':
                setCountQty((e) => e > 1 ? e - 1 : 1)
                // onEmitItemValue('A','SUBONE')
                break;
            default:
                break;
        }
    }
    const [inShow,setInShow] = useState(false)
    const handleReClose = ()=>setInShow(false)
    const [reMark,setRemark] = useState("")
    const handleRemark = () => setInShow(true)
    const handleTenderCheck = (e) => {
        setTenderCheck(!tenderCheck);
    };
    const handleFirstOrderCheck = (e) => {
        setFirstOrderCheck(!firstOrderCheck); 
    };
    const resetQuotes = () => {
        setUsEmail("")
        setUsName("")
        setUsRemark("")
        setUsPhone("")
        setUsProduct("")
    }

    const handleGetQuote = async () => {
        if (usEmail != "" && usName != "" && usPhone != "" && usProduct != "") {
            let payload = {
                customer: JSON.parse(GetSessions('AM_USER_RE')).CustomerCode + ' - ' + JSON.parse(GetSessions('AM_USER_RE')).CustomerName,
                email: usEmail,
                name: usName,
                message: usRemark,
                phone: usPhone,
                product: usProduct
            }
            let data = await UserService.insertQuoteDetails(payload)
            if (data.Status) {
                AMToasterService('S', 'Successfull')
                resetQuotes()
            }
            handleClose()
        } else {
            setError(true)
            AMToasterService('E', 'Fill required fields')
        }
    }

    return (
    <div>
        {item.CustomerLinkItemImageData!==undefined && <div className="row mb-3 shadow p-2">
            <div className="col-md-5">
                <div className="quickFirst">
                    <div className="quickImgBox">
                        <img
                         src={item.CustomerLinkItemImageData != "" ? `data:image/png;base64,${item.CustomerLinkItemImageData}` : notFound}
                          className="img-fluid"
                          style={{mixBlendMode:'multiply'}}
                           alt="img prodtest" />
                    </div>
                    <div className="quickImgContent">
                        <h5>{item.CustomerLinkItemName}</h5>
                        <p>Product Code:{item.CustomerLinkItemCode}</p>
                        <p>{item.CustomerLinkItemBoxQty} pc/Box</p>
                        <p>Unit Price: <strike><span className="RuppeFont">₹</span>{formatIndianNumber(item.CustomerLinkItemPrice)}</strike>&nbsp;&nbsp;<span className="RuppeFont">₹</span>{formatIndianNumber(item.CustomerLinkItemDP)}</p>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="CardProdDiv">
                    <div className="quickQty">
                        <p onClick={() => countChgQty('SUB')}>
                            <i className="fa-solid fa-minus"></i>
                        </p>
                        <div>
                        <input type="number" className="ProdCartInput"
                             onFocus={(e) => e.target.select(0,0)}
                             onChange={e => {
                                    if(e.target.value===""){
                                        setCountQty("")
                                    }else{
                                        setCountQty(e.target.value);
                                    }
                            }} value={parseInt(countQty)} />
                        </div>
                        <p onClick={() => countChgQty('ADD')}>
                            <i className="fa-solid fa-plus"></i>
                        </p>
                    </div>
                </div>
                <div className="ProCheckBox ms-3 mb-0">
                    <Checkbox checked={tenderCheck} onChange={handleTenderCheck} className="p-0">Tender Order</Checkbox>
                    <Checkbox checked={firstOrderCheck} onChange={handleFirstOrderCheck} className="ms-3">1st Time Order</Checkbox>
                </div>
            </div>
            <div className="col-md-3 QuickSecond">
                <p>Order Value: {((item.CustomerLinkItemBoxQty * countQty) * item.CustomerLinkItemDP).toFixed(2)}</p>
                <p>Avg Monthly PO Qty (Pcs) {item.CustomerLinkItemAvgSalesQty}</p>
                <p>Last Order on: {item.LastPurchasedDate} / {item.LastPurchasedQuantity} Pcs</p>
                <div className='mt-2'>
                    <button className='QuickAddRem' onClick={handleRemark}>Add Remarks</button>
                    {inShow && 
                    <div className='d-flex justify-content-between mb-2'>
                        <Input type="text" placeholder='Add remarks...'
                            onChange={e => {
                                if (e === "") {
                                    setRemark("")
                                } else {
                                    setRemark(e);
                                }
                            }}  />                           
                        <Button size='xs'  appearance="primary" onClick={handleReClose}>Close</Button>
                    </div>}
                        {item.CustomerLinkItemDP > 0 ? (
                            <button className="QuickAddbtn" onClick={() => onEmitItemValue('A')} style={{ width: '80%', padding: '0rem' }}>Add <img src={addICon} className="img-fluid" alt="add icon" /></button>
                        ) :
                            (<div>
                                <button className="QuickAddbtn" style={{ width: '80%' }} onClick={() => handleQuoteClick(item.CustomerLinkItemCode, item.CustomerLinkItemName)}>Get Quote</button>
                            </div>)}
                    {/*<button className="QuickAddbtn" onClick={()=>onEmitItemValue('A')} style={{width:'80%',padding:'0rem'}}>Add <img src={addICon} className="img-fluid" alt="add icon" /></button>*/}
                </div>
            </div>
        </div>}
        <Modal size={'sm'} backdrop={'static'} style={{ marginTop: '3rem' }} open={open} onClose={handleClose}>
            <Modal.Header>
            </Modal.Header>
            <Modal.Body>
                <div className="container-fluid">
                    <h5>Quotation Request</h5>
                    <div className='row mt-3'>
                        <div className='col-lg-12 mb-3'>
                            <input
                                value={usName}
                                onInput={() => setError(false)}
                                style={{
                                    borderColor: error ? '#e74c3c' : '',
                                    transition: '1s',
                                    boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial'
                                }}
                                onChange={(event) => setUsName(event.target.value)}
                                type="text" placeholder="Enter Name" className='form-control' />
                        </div>
                        <div className='col-lg-12 mb-3'>
                            <input
                                type="text"
                                onInput={() => setError(false)}
                                style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                value={usPhone}
                                onChange={(event) => setUsPhone(event.target.value)}
                                placeholder="Enter Phone" className='form-control' />
                        </div>
                        <div className='col-lg-12 mb-3'>
                            <input
                                type="text"
                                onInput={() => setError(false)}
                                style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                value={usEmail}
                                onChange={(event) => setUsEmail(event.target.value)}
                                placeholder="Enter Email"
                                className='form-control' />
                        </div>
                        <div className='col-lg-12 mb-3'>
                            <textarea
                                type="number"
                                onInput={() => setError(false)}
                                style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                value={usRemark}
                                onChange={(event) => setUsRemark(event.target.value)}
                                placeholder="Enter Remarks"
                                className='form-control' ></textarea>
                        </div>
                        <div className='col-lg-12 text-center'>
                            <AmsButton
                                onClick={handleGetQuote}
                                marginBottom={20}
                                textcolor={'#fff'}
                                icon={''}
                                buttonwid={'75%'}
                                color={'#3D97EA'}
                                label={'Submit'} />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    </div>
    )
}