import { Banner } from "../../components/Banner/Banner"
import { Footer } from "../../components/Footer/Footer"
import { UserHeader } from "../../components/UserHeader/UserHeader"
import './CampaignProducts.css'
import notfound from '../../assets/notfound.png'
import { BreadCmp } from "../../core/BreadCrump/BreadCmp"
import { Link, useLocation } from "react-router-dom"
import { useState, useEffect } from "react"
import { useRecoilValue } from "recoil"
//import { CampaignProductAtom } from "../../rec-state/atoms"
import { GetSessions } from "../../services/SessionService"
import HomeService from "../../services/HomeService"
import { Modal } from "rsuite"
import UserService from "../../services/UserService"
import { AmsButton } from "../../core/Button/Button"
import { AMToasterService } from "../../services/ToasterSerivce"
import { LoaderCheck } from "../../core/Loader/Loader"
import { useMutation } from "@tanstack/react-query"
import formatIndianNumber from "../../services/FormatNumber"
import { getFromIndexedDB } from '../../constants/IndexedDb.jsx'
import { Pagination } from 'react-bootstrap';
//import 'bootstrap/dist/css/bootstrap.min.css';
import { atom } from 'recoil';

const CampaignProductAtom = atom({
    key: 'CampaignProductAtom',
    default: [], // Default value
});

export const CampaignProducts = () => {
    const locationPath = useLocation()
    const [odState, setODState] = useState('first')
    const [open, setOpen] = useState(false)
    const handleClose = () => { setOpen(false); setUsProduct("") }
    const handleOpen = () => setOpen(true);
    const [activePage, setActivePage] = useState(1);
    const [usName, setUsName] = useState("")
    const [usPhone, setUsPhone] = useState("")
    const [usEmail, setUsEmail] = useState("")
    const [usRemark, setUsRemark] = useState("")
    const [error, setError] = useState(false)
    const [amuser, setAMUser] = useState(JSON.parse(GetSessions('AM_USER_RE')))
    const [campaignData, setCampaignProd] = useState(useRecoilValue(CampaignProductAtom))
    const [cart, setCart] = useState([])
    const [usProduct, setUsProduct] = useState("")
    const [pageRange, setPageRange] = useState([1, 20]);

    useEffect(() => {
        if (usProduct) {
            handleOpen();
        }
    }, [usProduct]);

    useEffect(() => {
        if (campaignData.length === 0) {
            const initialData = JSON.parse(localStorage.getItem('CampaignProductAtom')) || []; // Fetch stored value
            setCampaignProd(initialData);
        }
    }, []);

    const handleQuoteClick = (itemCode,linkitem) => {
        setUsProduct(itemCode+" ------ "+linkitem);
    };
    
    const resetQuotes = () => {
        setUsEmail("")
        setUsName("")
        setUsRemark("")
        setUsPhone("")
        setUsProduct("")
    }
    const handleGetQuote = async () => {
        if (usEmail != "" && usName != "" && usPhone != "" && usProduct != "") {
            let payload = {
                customer: JSON.parse(GetSessions('AM_USER_RE')).CustomerCode + ' - ' + JSON.parse(GetSessions('AM_USER_RE')).CustomerName,
                email: usEmail,
                name: usName,
                message: usRemark,
                phone: usPhone,
                product: usProduct
            }
            let data = await UserService.insertQuoteDetails(payload)
            if (data.Status) {
                AMToasterService('S', 'Successfull')
                resetQuotes()
            }
            handleClose()
        } else {
            setError(true)
            AMToasterService('E', 'Fill required fields')
        }
    }

    const itemsPerPage = 12;
    const startIndex = (activePage - 1) * itemsPerPage;
    const currentItems = Array.isArray(campaignData) && campaignData.length > 0
        ? campaignData.slice(startIndex, startIndex + itemsPerPage)
        : [];


    const CustomPaginationOld = ({ totalItems, itemsPerPage, activePage, setActivePage }) => {
        const totalPages = Math.ceil(totalItems / itemsPerPage);
        const startItem = (activePage - 1) * itemsPerPage + 1;
        const endItem = Math.min(activePage * itemsPerPage, totalItems);

        const handlePageChange = (pageNumber) => {
            setActivePage(pageNumber);
            // Update the page range when the active page changes
            const newStartPage = Math.floor((pageNumber - 1) / 20) * 20 + 1;
            const newEndPage = Math.min(newStartPage + 19, totalPages);

            setPageRange([newStartPage, newEndPage]);
        };

        const handlePrev = () => {
            if (pageRange[0] > 1) {
                const newStartPage = pageRange[0] - 20;
                const newEndPage = newStartPage + 19;
                setPageRange([newStartPage, Math.min(newEndPage, totalPages)]);
                setActivePage(newStartPage);
            }
        };

        const handleNext = () => {
            if (pageRange[1] < totalPages) {
                const newStartPage = pageRange[1] + 1;
                const newEndPage = newStartPage + 20;
                setPageRange([newStartPage, Math.min(newEndPage, totalPages)]);
                setActivePage(newStartPage);
            }
        };

        const handleStart = () => {
            setPageRange([1, Math.min(20, totalPages)]);
            setActivePage(1);
        };

        const handleLast = () => {
            const newStartPage = Math.floor((totalPages - 1) / 20) * 20 + 1;
            const newEndPage = totalPages;
            setPageRange([newStartPage, newEndPage]);
            setActivePage(newStartPage);
        };

        return (
            <div className="row justify-content-center">
                <div className="col-auto">
                    <div className="pagination-info text-center mb-3">
                        Showing {startItem} - {endItem} of {totalItems} items
                    </div>
                    <div className="pagination-container">
                        <Pagination>
                            <Pagination.First onClick={handleStart} />
                            <Pagination.Prev onClick={handlePrev} />
                            {Array.from({ length: pageRange[1] - pageRange[0] + 1 }, (_, i) => pageRange[0] + i).map((page) => (
                                <Pagination.Item
                                    key={page}
                                    active={page === activePage}
                                    onClick={() => handlePageChange(page)}
                                    className={`pagination-item ${page === activePage ? 'active' : ''}`}
                                >
                                    {page}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next onClick={handleNext} />
                            <Pagination.Last onClick={handleLast} />
                        </Pagination>
                    </div>
                </div>
            </div>
        );
    };

    const CustomPagination = ({ totalItems, itemsPerPage, activePage, setActivePage }) => {
        const totalPages = Math.ceil(totalItems / itemsPerPage);
        const [pageRange, setPageRange] = useState([1, Math.min(20, totalPages)]);

        const startItem = (activePage - 1) * itemsPerPage + 1;
        const endItem = Math.min(activePage * itemsPerPage, totalItems);

        const handlePageChange = (pageNumber) => {
            setActivePage(pageNumber);

            // Adjust the page range dynamically
            const newStartPage = Math.floor((pageNumber - 1) / 20) * 20 + 1;
            const newEndPage = Math.min(newStartPage + 19, totalPages);
            setPageRange([newStartPage, newEndPage]);
        };

        const handlePrev = () => {
            if (pageRange[0] > 1) {
                const newStartPage = Math.max(pageRange[0] - 20, 1);
                const newEndPage = Math.min(newStartPage + 19, totalPages);
                setPageRange([newStartPage, newEndPage]);
                setActivePage(newStartPage);
            }
        };

        const handleNext = () => {
            if (pageRange[1] < totalPages) {
                const newStartPage = pageRange[1] + 1;
                const newEndPage = Math.min(newStartPage + 19, totalPages);
                setPageRange([newStartPage, newEndPage]);
                setActivePage(newStartPage);
            }
        };

        const handleStart = () => {
            setPageRange([1, Math.min(20, totalPages)]);
            setActivePage(1);
        };

        const handleLast = () => {
            const newStartPage = Math.floor((totalPages - 1) / 20) * 20 + 1;
            setPageRange([newStartPage, totalPages]);
            setActivePage(totalPages);
        };

        return (
            <div className="row justify-content-center">
                <div className="col-auto">
                    <div className="pagination-info text-center mb-3">
                        Showing {startItem} - {endItem} of {totalItems} items
                    </div>
                    <div className="pagination-container">
                        <Pagination>
                            <Pagination.First onClick={handleStart} disabled={activePage === 1} />
                            <Pagination.Prev onClick={handlePrev} disabled={activePage === 1} />
                            {Array.from(
                                { length: pageRange[1] - pageRange[0] + 1 },
                                (_, i) => pageRange[0] + i
                            ).map((page) => (
                                <Pagination.Item
                                    key={page}
                                    active={page === activePage}
                                    onClick={() => handlePageChange(page)}
                                >
                                    {page}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next onClick={handleNext} disabled={activePage === totalPages} />
                            <Pagination.Last onClick={handleLast} disabled={activePage === totalPages} />
                        </Pagination>
                    </div>
                </div>
            </div>
        );
    };

    if (currentItems.length === 0) {
        return (
            <LoaderCheck text={'Loading...'} isLoad={true}></LoaderCheck>
        )
    }
    return (
        <div>
            <Banner
                text={""}
            />
            <UserHeader />
            <BreadCmp link={locationPath.pathname} />
            <div className="container-fluid mt-2 mb-4 fade-image-class">
                <div className="row mt-2 mb-2">
                    <div className='col-md-12'>
                        <h3>Campaigned Products</h3>
                    </div>
                </div>
                <div className="row">
                    {currentItems.map((item, index) => (
                        <div className='col-lg-3 mt-2' key={index}>
                            <div className='ProductCardOne'>
                                {item.ItemDp ? (
                                    <Link to={`/productDetails/${item.CustomerLinkItemCode}`} style={{ cursor: 'pointer', color: '#222222' }}>
                                        <img 
                                        src={item.CustomerLinkItemImageData != "" ? `data:image/png;base64,${item.CustomerLinkItemImageData}` : notfound}
                                        className={item.CustomerLinkItemImageData===""?'QuickNotFimg':'img-fluid-pr'} alt="" />
                                    </Link>
                                ) : (
                                    <span style={{ cursor: 'not-allowed', color: 'gray' }}>
                                        <img src={item.CustomerLinkItemImageData != "" ? `data:image/png;base64,${item.CustomerLinkItemImageData}` : notfound} 
                                        className={item.CustomerLinkItemImageData===""?'QuickNotFimg':'img-fluid-pr'} alt="" />
                                    </span>
                                )}
                                <div className='ProductContent'>
                                    <div className='ProductTitle'>
                                        <div>{item.CustomerLinkItemName}</div>
                                        <div>Product&nbsp;Code:&nbsp;{item.CustomerLinkItemCode}</div>
                                        {item.ItemDp !== 0 && <div className="pb-2">MRP:&nbsp;<strike><span className="RuppeFont">₹</span>{formatIndianNumber(item.CustomerLinkItemMRP)}</strike>&nbsp;DP:&nbsp;<span className="RuppeFont">₹</span>&nbsp;{formatIndianNumber(item.CustomerLinkItemDP)}</div>}
                                    </div>
                                    <div className='ProductBtn'>
                                        {item.ItemDp > 0 ? (
                                            <Link to={`/productDetails/${item.CustomerLinkItemCode}`} style={{ cursor: 'pointer', color: '#222222' }}>
                                                <button type='button'>Add&nbsp;
                                                    <svg width="16" height="16" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7.29102 9.91663H27.4014C27.8089 9.91664 28.2119 9.9996 28.5843 10.1602C28.9568 10.3207 29.2905 10.5553 29.5639 10.8488C29.8373 11.1423 30.0444 11.4882 30.1718 11.8642C30.2992 12.2402 30.3441 12.638 30.3035 13.0319L29.4285 21.5319C29.3565 22.231 29.0197 22.8792 28.4835 23.3507C27.9472 23.8222 27.2497 24.0833 26.5264 24.0833H12.5993C11.9248 24.0836 11.2711 23.8567 10.7494 23.4413C10.2277 23.026 9.8703 22.4478 9.7381 21.8053L7.29102 9.91663Z" stroke="white" strokeWidth="1.5" strokeLinejoin="round" />
                                                        <path d="M7.29102 9.91667L6.10977 5.32242C6.03077 5.01607 5.84865 4.74415 5.59236 4.54985C5.33607 4.35554 5.02029 4.25001 4.69518 4.25H2.91602M11.666 29.75H14.5827M23.3327 29.75H26.2493" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </button>
                                            </Link>
                                        ) :
                                            (<div>
                                                <button onClick={() => handleQuoteClick(item.CustomerLinkItemCode,item.CustomerLinkItemName)}>Get Quote</button>
                                            </div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <CustomPagination
                        totalItems={campaignData.length}
                        itemsPerPage={itemsPerPage}
                        activePage={activePage}
                        setActivePage={setActivePage}
                    />
                    {/*<Pagination size="md" total={campaignData.length} limit={itemsPerPage} activePage={activePage} onChangePage={(page) => setActivePage(page)} />*/}
                    {campaignData.length == 0 && <div className="text-center p-5">No data found</div>}
                </div>
            </div>
            {/*{campaignData.length <= 6 ? '' : <Footer />}*/}
            {<Footer />}
            <Modal size={'sm'} backdrop={'static'} style={{ marginTop: '3rem' }} open={open} onClose={handleClose}>
                <Modal.Header>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <h5>Quotation Request</h5>
                        <div className='row mt-3'>
                            <div className='col-md-12 mb-3'>
                                <input
                                    value={usName}
                                    onInput={() => setError(false)}
                                    style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                    onChange={(event) => setUsName(event.target.value)}
                                    type="text" placeholder="Enter Name" className='form-control' />
                            </div>
                            <div className='col-md-12 mb-3'>
                                <input
                                    type="text"
                                    onInput={() => setError(false)}
                                    style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                    value={usPhone}
                                    onChange={(event) => setUsPhone(event.target.value)}
                                    placeholder="Enter Phone" className='form-control' />
                            </div>
                            <div className='col-md-12 mb-3'>
                                <input
                                    type="text"
                                    onInput={() => setError(false)}
                                    style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                    value={usEmail}
                                    onChange={(event) => setUsEmail(event.target.value)}
                                    placeholder="Enter Email"
                                    className='form-control' />
                            </div>
                            <div className='col-md-12 mb-3'>
                                <textarea
                                    type="number"
                                    onInput={() => setError(false)}
                                    style={{ borderColor: error ? '#e74c3c' : '', transition: '1s', boxShadow: error ? "1px 1px 10px rgba(231,76,60,0.3)" : 'initial' }}
                                    value={usRemark}
                                    onChange={(event) => setUsRemark(event.target.value)}
                                    placeholder="Enter Remarks"
                                    className='form-control' ></textarea>
                            </div>
                            <div className='col-md-12 text-center'>
                                <AmsButton
                                    onClick={handleGetQuote}
                                    marginBottom={20}
                                    icon={''}
                                    textcolor={'#fff'}
                                    buttonwid={'75%'}
                                    color={'#3D97EA'}
                                    label={'Submit'} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}