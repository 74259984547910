import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import '@fortawesome/fontawesome-free/css/all.min.css';
import "./App.css";
import { LayoutPage } from "./pages/LayoutPage/LayoutPage";
import { ToastContainer } from "react-toastify";
import { RecoilRoot } from "recoil";

function App() {
  
  return (
    <div className="AppSection">
      <RecoilRoot>
        <LayoutPage />
        <ToastContainer></ToastContainer>
      </RecoilRoot>
    </div>
  );
}

export default App;
