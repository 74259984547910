import './Button.css'

export const AmsButton = ({color,label,buttonwid,textcolor,onClick,marginBottom,icon}) =>{
    return(
        <button
        type='button'
        onClick={onClick}
        className="ButtonClass"
         style={{
            backgroundColor:color,
            color:textcolor===''?'#000':textcolor,
            border:0,
            paddingTop:10,
            paddingBottom:10,
            paddingLeft:25,
            paddingRight:25,
            borderRadius:10,
            width:buttonwid,
            marginBottom:marginBottom
        }}
        >{label}&nbsp;{icon!==""?<svg width="16" height="16" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.29102 9.91663H27.4014C27.8089 9.91664 28.2119 9.9996 28.5843 10.1602C28.9568 10.3207 29.2905 10.5553 29.5639 10.8488C29.8373 11.1423 30.0444 11.4882 30.1718 11.8642C30.2992 12.2402 30.3441 12.638 30.3035 13.0319L29.4285 21.5319C29.3565 22.231 29.0197 22.8792 28.4835 23.3507C27.9472 23.8222 27.2497 24.0833 26.5264 24.0833H12.5993C11.9248 24.0836 11.2711 23.8567 10.7494 23.4413C10.2277 23.026 9.8703 22.4478 9.7381 21.8053L7.29102 9.91663Z" stroke="white" strokeWidth="1.5" strokeLinejoin="round" />
            <path d="M7.29102 9.91667L6.10977 5.32242C6.03077 5.01607 5.84865 4.74415 5.59236 4.54985C5.33607 4.35554 5.02029 4.25001 4.69518 4.25H2.91602M11.666 29.75H14.5827M23.3327 29.75H26.2493" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>:<></>}</button>
    )
} 