import { useLocation } from "react-router-dom"
import { Banner } from "../../components/Banner/Banner"
import { UserHeader } from "../../components/UserHeader/UserHeader"
import { BreadCmp } from "../../core/BreadCrump/BreadCmp"
import { Button, Input } from "rsuite"
import { useState, useEffect, useRef } from "react"
import "./FeedbackPage.css"
import { debounce } from 'lodash';
import { AMToasterService } from "../../services/ToasterSerivce"
import { useMutation } from '@tanstack/react-query';
import QuickOrderService from "../../services/QuickOrderService"
import { GetSessions } from "../../services/SessionService"
import ProductService from "../../services/ProductService"
import { getFromIndexedDB } from '../../constants/IndexedDb.jsx'
import { Footer } from "../../components/Footer/Footer"

export const FeedbaackPage = () => {
    const [useData, setUseData] = useState([])
    const [amuser, setAMUser] = useState(JSON.parse(GetSessions('AM_USER_RE')));
    const [linkProd, setLinkProd] = useState([])
    const [getLinked, setGetLinked] = useState([])
    const [reaFeed, setResFeed] = useState("")
    const [custAdd, setCustAdd] = useState([])
    const [feedType, setFeedType] = useState([
        { label: 'Service Feedback', value: 1 },
        { label: 'Ekart Feedback', value: 2 },
        { label: 'Product Quality', value: 3 }
    ])
    const [catType, setCatType] = useState([
        { label: 'GSM', value: 'GSM' },
        { label: 'Pouch', value: 'Pouch' },
        { label: 'Folding', value: 'Folding' },
        { label: 'Packing', value: 'Packing' },
        { label: 'Others', value: 'Others' }
    ])
    const [type, setType] = useState("")
    const [proType, setProType] = useState("")

    //const mutation =  useMutation({
    //    mutationFn: QuickOrderService.getCustomerLinkedProd,
    //    onSuccess: (data) => {
    //        setGetLinked(data.Data)
    //        setNamesValue(data.Data)
    //    },
    //    onError: (error) => {
    //        console.error('Error posting data:', error);
    //    },
    //});
    const mutation = useMutation({
        mutationFn: async () => {
            // Call the async function and return its result
            return await getFromIndexedDB('customerLinkedProducts');
        },
        onSuccess: (data) => {
            //console.log("customerLinkedProducts - ", JSON.stringify(data))
            setGetLinked(data);
            setNamesValue(data);
        },
        onError: (error) => {
            console.error('Error posting data:', error);
        },
    });
    useEffect(() => {
        mutation.mutate({
            custcode: amuser.CustomerCode,
            itemgroupid: 0
        })
        getCustomerAddress()
    }, [])

    const [inputValue, setInputValue] = useState('');
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);

    const handleChange = (e) => {
        const value = e.target.value;
        setInputValue(value);

        if (value) {
            const filtered = useData.filter(suggestion =>
                suggestion.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredSuggestions(filtered);
            setShowSuggestions(true);
        } else {
            setShowSuggestions(false);
        }
    };

    const handleClick = (suggestion) => {
        setInputValue(suggestion);
        setShowSuggestions(false);
    };

    const setNamesValue = async (data) => {
        let linkValue = data.map((item) => { return item.CustomerLinkItemNameAndCode })
        setUseData(linkValue)
    }

    const addFeedback = () => {
        if (getLinked.length !== 0) {
            let findProd = linkProd.findIndex((item) => item.FeedbackDetItemName === inputValue)
            let findItemCode = getLinked.find((item) => item.CustomerLinkItemNameAndCode === inputValue)
            //console.log("getLinked data ", JSON.stringify(linkProd))
            if (findProd !== -1) {
                setLinkProd(previtem => {
                    const updateItem = [...previtem]
                    updateItem[findProd] = {
                        FeedbackDetItemName: findItemCode.CustomerLinkItemName,
                        FeedbackDetCatg: proType,
                        FeedbackDetItemCode: findItemCode.CustomerLinkItemCode,
                        FeedbackDetData: reaFeed,
                    }
                    return updateItem
                })
            } else {
                if (findItemCode !== undefined && findItemCode !== null) {
                    setLinkProd(prev => [...prev, {
                        FeedbackDetItemName: findItemCode.CustomerLinkItemName,
                        FeedbackDetCatg: proType,
                        FeedbackDetItemCode: findItemCode.CustomerLinkItemCode,
                        FeedbackDetData: reaFeed,
                    }])
                } else {
                    setLinkProd(prev => [...prev, {
                        FeedbackDetItemName: inputValue,
                        FeedbackDetCatg: proType,
                        FeedbackDetItemCode: inputValue,
                        FeedbackDetData: reaFeed,
                    }])
                }
            }
        }
    }

    const handleSubmit =async () => {
        if (type === "") {
            return AMToasterService('I', `Select Feedback Type`)
        }

        if (type === 3) {
            let feedArr = linkProd.map(item => ({
                FeedbackDetCatg: item.FeedbackDetCatg,
                FeedbackDetItemCode: item.FeedbackDetItemCode,
                FeedbackDetData: item.FeedbackDetData,
                FeedbackDetItemName:item.FeedbackDetItemName
            }))
            let payload = {
                feedbacks: feedArr,
                custcode: amuser.CustomerCode,
                feedbacktype: parseInt(type),
                CustomerName: amuser.CustomerName, 
                custaddress: custAdd[0].AddressDetails,
                CustomerPhone: amuser.CustomerMobileNo,
                CustomerContactName: amuser.CustContactName,
                CustomerContactDesignation: amuser.DesignationName
            }
            let data = await ProductService.insertFeedBack(payload)
            if (data.Status) {
                setLinkProd([])
                AMToasterService('S', 'Successfuly added')
                resetFeeds()
            }
        } else {
            let feedArr = [{
                FeedbackDetCatg: '',
                FeedbackDetItemCode: '',
                FeedbackDetData: reaFeed
            }]
            let payload = {
                feedbacks: feedArr,
                custcode: amuser.CustomerCode,
                feedbacktype: parseInt(type),
                CustomerName: amuser.CustomerName, 
                custaddress: custAdd[0].AddressDetails,
                CustomerPhone: amuser.CustomerMobileNo,
                CustomerContactName: amuser.CustContactName,
                CustomerContactDesignation: amuser.DesignationName
            }
            let data = await ProductService.insertFeedBack(payload)
            if (data.Status) {
                setLinkProd([])
                AMToasterService('S', 'Successfuly added')
                resetFeeds()
            }
        }

    }

    const resetFeeds = () =>{
        setInputValue("")
        setType("")
        setProType("")
        setResFeed("")
    }

    const removeFeedItem = (fdCode) => {
        setLinkProd(prevVal => prevVal.filter((item) => item.FeedbackDetItemCode !== fdCode))
    }

    const getCustomerAddress = async () => {
        let payload = {
            custcode: amuser.CustomerCode
        }
        let data = await QuickOrderService.getCustomerAddress(payload)
        setCustAdd(data.Data[0])
    }

    return (
        <div>
            <Banner
                text={""}
            />
            <UserHeader />
            <BreadCmp link={useLocation().pathname} />
            <div className='container-fluid fade-image-class mb-5'>
                <div className='row mb-4'>
                    <div className="col-md-4">
                        <div style={{ width: '100%' }} className="mt-3">
                            <select onChange={(e) => setType(parseInt(e.target.value))} className="form-select mb-3" aria-label="Large select example">
                                <option value={""} selected>Select Feedback type</option>
                                {feedType.map(item => (
                                    <option key={item.value} value={item.value}>{item.label}</option>
                                ))}
                            </select>
                        </div>
                        {type === 3 &&
                            <div className="border border-1 p-2 d-flex flex-column align-items-center rounded p-auto mb-2 fade-image-class" >
                                <select onChange={(e) => setProType(e.target.value)} className="form-select mb-3" aria-label="Large select example">
                                    <option value={""} selected>Select Category type</option>
                                    {catType.map(item => (
                                        <option key={item.value} value={item.value}>{item.label}</option>
                                    ))}
                                </select>
                                <div className="position-relative w-100">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={inputValue}
                                        onChange={handleChange}
                                        placeholder="Type to search..."
                                    />
                                    {showSuggestions && inputValue && (
                                        <ul className="list-group position-absolute w-100" style={{ zIndex: 1000,cursor:'pointer' }}>
                                            {filteredSuggestions.map((suggestion, index) => (
                                                <li
                                                    key={index}
                                                    className="list-group-item list-group-item-action"
                                                    onClick={() => handleClick(suggestion)}
                                                >
                                                    {suggestion}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                    <textarea
                                        as="textarea"
                                        rows={3}
                                        value={reaFeed}
                                        onChange={(e) => setResFeed(e.target.value)}
                                        className="mb-3 mt-3 form-control"
                                        placeholder="enter your feedback?"
                                    ></textarea>
                                <div className="d-flex justify-content-end mt-3">
                                    <Button onClick={addFeedback} color="green" appearance="primary">
                                        Add
                                    </Button>
                                </div>
                            </div>
                        }
                        {type !== 3 && <Input
                            as="textarea"
                            rows={3}
                            value={reaFeed}
                            onChange={(e) => setResFeed(e)}
                            className="mb-3"
                            placeholder="enter your feedback?"
                        ></Input>}
                        <div className="d-flex justify-content-end">
                        <Button className="mb-3" onClick={handleSubmit} color="blue" appearance="primary">Submit feedback</Button>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <table className="table feedBack mt-3" style={{width: "97.6%"}}>
                            <thead>
                                <tr>
                                    <th>Product Name</th>
                                    <th>Product Code</th>
                                    <th>Category</th>
                                    <th>Feedback</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {linkProd.length !== 0 &&
                                    linkProd.map((item) => (
                                        <tr key={item.FeedbackDetItemCode}>
                                            <td>{item.FeedbackDetItemName}</td>
                                            <td>{item.FeedbackDetItemCode}</td>
                                            <td>{item.FeedbackDetCatg}</td>
                                            <td>{item.FeedbackDetData}</td>
                                            <td>
                                                <Button appearance="default" color="red" onClick={() => removeFeedItem(item.FeedbackDetItemCode)}>
                                                    <i className="fa-solid fa-trash text-danger"></i>
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {type != 3 ? (
                <>
                    <div style={{ marginBottom: '146px' }}></div>
                    <Footer />
                </>
            ) : (
                <Footer />
            )}
        </div>
    )

}