import React from "react";
import ReactApexChart from "react-apexcharts";

export const AMBarChart = ({options}) =>{
  
    return(
        <div>
            <ReactApexChart
            options={options.options}  series={options.series} type="bar" height={300}
            ></ReactApexChart>
        </div>
    )
}